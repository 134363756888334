import React, { Component } from 'react';
import { Row, Col } from 'antd';

require('./cardv.css')

class CardV extends Component {
    render() {
        let cardVStyle = {
            width:this.props.width,
            backgroundColor:'#FFFFFF',
            maxWidth:'700px',
            borderStyle:'none none none none', 
            borderWidth:'0 0 0 0',
            borderColor:'#FC813F',
            paddingTop:'14px',
            paddingLeft:'20px',
            paddingRight:'20px',
            margin:'auto',
            textAlign:'center',
            color:'rgba(0, 0, 0, 0.45)'
        }

        let bodyStyle = {
            width:'100%',
            maxWidth:'700px',
            height:this.props.bodyHeight,
            margin:'auto'
        }

        return (
            <Row style={{...cardVStyle}}>
                <Col span={24} style={{width:"100%",maxWidth:'700px'}}>
                    <div style={{fontFamily: 'DFPKingGothicGB',color:'rgba(0,0,0,0.85)',fontWeight:'500',fontSize: '16px',display:'inline',textAlign:'center', verticalAlign:'middle'}}>
                        {this.props.title}
                    </div>
                </Col>
                <Col span={24} style={{...bodyStyle,display:'flex',justifyContent:'space-around'}}>
                    <div style={{fontFamily: 'DFPKingGothicGB',color:'rgba(0,0,0,0.45)',paddingTop:'8%',lineHeight: '25px',fontWeight:'light',fontSize: '14px',height:'100px',textAlign:'left',width:'100%',wordBreak:'break-all',whiteSpace:'normal'}}>
                        {this.props.desc}
                    </div>
                </Col> 
            </Row>
        );
    }
}

export default CardV;