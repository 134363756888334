import React, { Component } from 'react'
import axios from 'axios'
import { fabric } from 'fabric'
import $ from 'jquery'
import {
  Button,
  Upload,
  message,
  Input,
  notification,
  Collapse,
  Spin,
  Icon
} from 'antd'

import scanCanvas from '../../utils/scanCanvas.js'

import { getData } from '../../utils/config'
import icon_up from './assets/Group 2.png'
import icon_down from './assets/Group 4.png'
import head from './assets/head.png'
import scan from './assets/scan.png'
import id1 from './assets/renlianyanzheng/人脸验证01.jpg'
import id2 from './assets/renlianyanzheng/人脸验证02.jpg'
import id3 from './assets/renlianyanzheng/人脸验证03.jpg'
import id4 from './assets/renlianyanzheng/人脸验证04.jpg'
import id5 from './assets/renlianyanzheng/人脸验证05.jpg'
import id6 from './assets/renlianyanzheng/人脸验证06.jpg'

const Search = Input.Search
const Panel = Collapse.Panel
const BASE_URL = 'http://ai.intra.xiaojukeji.com'

const imgData = [
  {
    name: id1,
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_g4lcniDMZFygpTUJIxsH',
  },
  {
    name: id2,
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_25kg7fQ8bIrV32n4ey2E',
  },
  {
    name: id3,
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_wafdz9F4WyXKr42ZKAwD',
  },
  {
    name: id4,
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_S8jawV3WsTsdDc59feRM',
  },
  {
    name: id5,
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_A7mtKDArLlUv5Z5VZdTu',
  },
  {
    name: id6,
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_qjeeEFDagfXkMUJT7ra6',
  }
]


export default class FaceVerify extends Component {

  state = {
    index: 1,
    score: '9%',
    desc: '极低可能为同一人',
    loading: false,
    responseJson: `{
      score: 1.00000011920929
      verify_result: true
    }`,
    imgUrls: [ imgData[0].url, imgData[1].url ],
    types: [1, 1],
    isLeft: true,
  }

  // 初始化图片墙的样式
  initButtonStyle = () => {
    $('.ant-input').css('box-shadow', 'none')
    $(".ant-input-search-button").css("background-color", "#19387B")
    $(".ant-input-search-button").css("box-shadow", 'none');
    $(".ant-input-search-button").css("text-shadow", 'none');
    $('.small-img:first').css('border', "1px solid #19387B")
    $('.ant-collapse').css('border-radius', 0)
    const btn = document.querySelector('.ant-input-search-button')
    btn.addEventListener('mouseenter', function () {
      btn.style.background = '#132E69'
    })
    btn.addEventListener('mouseleave', function () {
      btn.style.background = '#19387B'
    })
  }

  initCanvas = () => {
    const canvas = new fabric.Canvas('canvas')
    this.canvas = canvas
  }

  setImgUrls = (url) => {
    let {isLeft, imgUrls} = this.state
    if (isLeft) {
      imgUrls = [url, imgUrls[1]]
    } else {
      imgUrls = [imgUrls[0], url]
    }
    return imgUrls
  }

  // 在 canvas 上把图画出来
  /**
   * type: 通过哪种方式画图 1：本地 2：上传 3: 在线检测
   * @memberof IDcard
   */
  drawImage = (imgUrl, type, position) => {
    if (!imgUrl) {
      imgUrl = id1
    }
    const self = this
    const { isLeft } = this.state
    const objs = this.canvas.getObjects()
    console.log(objs)
    // objs.forEach(item => this.canvas.remove(item))

    
    fabric.Image.fromURL(imgUrl, function (oImg) {
      const center = self.canvas.getCenter()
      const scaleX = 350 / oImg.width
      const scaleY = 350 / oImg.height
      const scaleValue = scaleX > scaleY ? scaleY : scaleX
      console.log(scaleX, scaleY, oImg, center)
      let left = position ? center.left - 195 : center.left + 195
      let top = center.top + 9
      oImg.set({
        scaleX: 0.4375,
        scaleY: 0.4375,
        originX: 'center',
        originY: 'center',
        left,
        top,
      })
      if (type === 2 || type === 3) {
        oImg.set({
          scaleX,
          scaleY
        })
      }
      self.canvas.add(oImg)
      scanCanvas(scan, self.canvas)
    })
  }

  handleClick = (e, index) => {
    let { isLeft, imgUrls } = this.state
    
    /* if (this.state.index === index ) {
      return
    } */
    this.setState({ loading: true, index })
    
    const el = e.target
    const url = imgData[index-1].name
    $('.small-img').css('border-color', 'rgba(0,0,0,0)')
    $(el).css('border-color', "#19387B")
    this.drawImage(url, 1, isLeft)
    // this.postRequest(imgData[index - 1].url)
    
    if (isLeft) {
      imgUrls = [imgData[index-1].url, imgUrls[1]]
    } else {
      imgUrls = [imgUrls[0], imgData[index-1].url]
    }
    this.setState({imgUrls}, () => {
      this.postRequest()
    })
  }

  selectLeftOrRight = (isLeft) => {
    // 点击右图
    if (!isLeft) {
      this.setState({isLeft})
    // 点击左图
    } else {
      this.setState({isLeft})
    }
  }

  handleUpload = ({ file, fileList, event }) => {
    const isLeft = this.state.isLeft
    if (file.status === 'error') {
      message.error('上传失败')
    } else if (file.status === 'done') {
      console.log(file.response.data.url)
      const imgUrl = file.response.data.url
      this.drawImage(imgUrl, 2, isLeft)
      let imgUrls = this.setImgUrls(imgUrl)
      this.setState({imgUrls}, () => this.postRequest())
      message.success('图片上传成功')
    }

    $('.ant-upload-list').hide()
  }

  handleDetect = (value, e) => {
    if (!value) {
      return
    }
    let { isLeft } = this.state
    this.drawImage(value, 3, isLeft)
    let imgUrls = this.setImgUrls(value)
    this.setState({imgUrls}, () => this.postRequest())
  }

  postRequest = () => {
    let { imgUrls, types } = this.state
    
    axios.post(BASE_URL + '/face/verify', {
      type1: types[0],
      type2: types[1],
      url1: imgUrls[0],
      url2: imgUrls[1]
    })
      .then((res) => {
        if (res.status === 200) {
          const result = getData(res).data
          let { verify_result, score } = result
          let desc = ''
          if (verify_result) {
            desc = score > 0.6 ? '有很高可能为同一人' : '有较高可能为同一人'
          } else {
            desc = score > 0.3 ? '较低可能为同一人' : '极低可能为同一人'
          }
          if (score < 0) score = 0
          score = Math.round(score * 100) + '%'
          this.setState({
            score,
            desc,
            loading: false,
            responseJson: JSON.stringify(result, null, 4)
          })
        } else {
          message.error('服务繁忙，请稍后尝试')
        }
      })
  }

  componentDidMount() {
    this.initButtonStyle()
    this.initCanvas()
    this.drawImage(imgData[0].name, 1, true)
    this.drawImage(imgData[1].name)
    this.postRequest()
  }

  render() {

    const { score, desc, loading, responseJson, isLeft } = this.state
    // const responseJson = JSON.stringify(this.state.responseJson)
    return (
      <div>
        <div className='func-show'>
          <h3>功能演示</h3>
          <div className='show-container'>
            <div className='container-left'>
              <div className='big-img'>
                <div className='icon'>原始图片</div>
                <canvas id='canvas' width='800' height='448'></canvas>
              </div>
              <div className='mask'>
                <div className='mask-left' onClick={() => this.selectLeftOrRight(true)} style={{border: isLeft ? '2px solid #19387B' : ''}}></div>
                <div className='mask-right' onClick={() => this.selectLeftOrRight(false)} style={{border: isLeft ? '' : '2px solid #19387B'}}></div>
              </div>
              <div className='thumbnails'>
                {
                  imgData.map((item, index) => (
                    <div className='small-img' 
                      style={{width: 94, height: 94, backgroundImage: `url(${item.name})`}} 
                      key={index}
                      onClick={(e) => this.handleClick(e, index + 1)} key={index} 
                    >
                    </div>
                  ))
                }
              </div>
              <div className='upload'>
                <Search
                  onFocus={() => $('.ant-input').css('border', '1px solid rgba(25,56,123,0.6)')}
                  onBlur={() => $('.ant-input').css('border', '1px solid #d9d9d9')}
                  placeholder="请输入网络图片URL"
                  style={{ width: 552 }}
                  enterButton="检测"
                  size="large"
                  onSearch={this.handleDetect}
                />
                <span style={{ marginTop: 12 }}>或</span>
                <Upload
                  accept='image/*'
                  action={BASE_URL + '/ocr/upload'}
                  onChange={this.handleUpload}
                  name='upfile'
                >
                  <Button className='btn'
                    onMouseEnter={(e) => e.target.style.background = '#132E69'}
                    onMouseLeave={(e) => e.target.style.background = '#19387B'}
                    style={{ width: 110, height: 40, backgroundColor: '#19387B', color: '#fff', boxShadow: 'none', border: 'none', textShadow: 'none' }}
                  >本地上传</Button>
                </Upload>
                <p className='msg'>支持包含真实人脸的PNG、JPG图片，图片大小不超过2M</p>
              </div>
            </div>
            <div className='container-right'>
              <Collapse
                accordion={true}
                expandIconPosition='right'
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <Icon type="down" rotate={isActive ? 180 : 0} />}
              >
                <Panel
                  style={{ background: '#fff' }}
                  header={<span className='result-title'>识别结果</span>} key="1"
                >
                  <div style={{ width: 384, height: 512 }} className='recognition-result'>
                    {
                      loading
                        ? <Spin style={{ marginLeft: '40%' }} />
                        : <div>
                          <p className='score'>相似度：{score}</p>
                          <p className='desc'>{desc}</p>
                        </div>
                    }
                  </div>
                </Panel>
                <Panel
                  header={<span className='res-json'>Response Json</span>} key="2"
                  style={{ background: '#fff', borderRadius: 0 }}
                >
                  <div style={{ width: 350, height: 512 }} >
                    <pre style={{ width: 350, height: 540 }}>{responseJson}</pre>
                  </div>
                </Panel>

              </Collapse>
            </div>
          </div>
        </div>

        <style jsx>{`
          .func-show {
            margin: 0 auto;
            width: 1200px;
            padding: 94px 0 168px 0;
          }

          .func-show h3 {
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 53px;
          }
          
          .show-container {
            display: flex;
            width: 1200px;
            height: 688px;
            background: #fff;
          }

          .container-left {
            position: relative;
            width: 800px;
          }

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 800px;
            height: 448px;
          }

          .mask-left {
            position: absolute;
            top: 58px;
            left: 30px;
            width: 350px;
            height: 350px;
          }

          .mask-right {
            position: absolute;
            top: 58px;
            right: 30px;
            width: 350px;
            height: 350px;
          }

          .big-img {
            width: 800px;
            height: 448px;
            background: #ddd;
          }

          .big-img .icon {
            width: 116px;
            height: 40px;
            background-image: url(${head});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            text-align: center;
            color: #fff;
            line-height: 40px;
            margin: 0;
            position: absolute;
            z-index: 5;
          }

          .thumbnails {
            width: 800px;
            height: 130px;
            background: #666;
            display: flex;
            align-items: center;
            padding: 0 30px;
            justify-content: space-between;
          }

          .small-img {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border: 1px solid rgba(0,0,0,0);
            cursor: pointer;
          }

          .small-img>img {
            width: 100%;
            height: 100%;
            border: 2px solid rgba(0,0,0,0);
            cursor: pointer;
          }

          .upload {
            width: 800px;
            height: 110px;
            padding: 28px 30px 12px 30px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-left: 1px solid #E8E8E8;
            border-bottom: 1px solid #E8E8E8;
          }

          .msg {
            display: block;
            width: 346px;
            height: 24px;
            font-size: 12px;
            color: rgba(0,0,0,0.25);
            line-height: 24px;
          }

          .ant-input-search-button {
            background: #19387B !important;
          }

          .container-right {
            width: 400px;
            height: 688px;
          }

          .result-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #333333;
            justify-content: flex-end;
            cursor: pointer;
            height: 46px;
          }

          .recognition-result {
            padding: 200px 40px 0 100px;
          }

          .res-json {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #333333;
            justify-content: flex-end;
            cursor: pointer;
            height: 46px;
          }

          .icon {
            width: 14px;
            height: 14px;
            margin: 0 8px 0 18px;
          }

          .score {
            width: 245px;
            height: 24px;
            font-size: 28px !important;
            margin-bottom: 10px;
          }
          
          .desc {
            width: 294px;
          }
        `}</style>
      </div>
    )
  }
}
