import React, { Component } from 'react'

import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import Features from '../../component/ProService/Features'
import RecommondSence from '../../component/ProService/RecommondSence'
import shenwashuju from '../../img/ICON/深挖数据.png'
import hailiangshuju from '../../img/ICON/海量数据支持.png'
import zhishitupu from './ziranyuyan/知识图谱/知识图谱.jpg'
import faq from './ziranyuyan/知识图谱/推荐场景/泛安全.jpg'
import wlw from './ziranyuyan/知识图谱/推荐场景/物联网.jpg'
import zinengyinjian from './ziranyuyan/知识图谱/推荐场景/智能硬件.jpg'
import jinrongfuwu from './ziranyuyan/知识图谱/推荐场景/金融服务.jpg'
import zizhuhua from '../../data/ICON一期/35.自主化技术.png'
import r_zhishi from '../../data/语言处理技术/func-img/知识图谱.jpg'

export default class Knowledge extends Component {

  componentWillMount() {
    document.getElementById('root').scrollIntoView(true)
  }

  constructor(props) {
    super(props)
    this.state = {
      advList: [
        {
          title: '深挖数据',
          desc: '通过对数据的深度挖掘，可检测特定人群、深挖隐藏关系，进行关联查询、复杂关系可视化等实时分析',
          icon: shenwashuju
        },
        {
          title: '海量数据支持',
          desc: '高效管理复杂关联关系，海量数据快速响应，有助于处理效率大幅提升，并能深度挖掘数据潜在价值',
          icon: hailiangshuju
        },
        {
          title: '自主化技术',
          desc: '支持数据存储，计算，分析的全流程，可实现服务私有化、定制化，满足客户对安全和隐私的需求',
          icon: zizhuhua
        }
      ],
      recommondSence: [
				{
					name: '物联网',
          content: '在物联网数据接入、管理、分析等方面，可为用户提供引擎级产品到行业知识落地的全套解决方案',
          icon: 'iconwulianwang',
          bg: wlw,
				},
				{
					name: '泛安全',
          content: '实时分析亿万级海量关系数据，并转化为关系图谱数据，形成线上线下泛安全专属知识图谱',
          icon: 'iconfananquan',
          bg: faq
				},
				{
					name: '金融服务',
          content: '基于监管科技可服务于金融行业，有助于提升金融机构合规及风控能力，实现产业智能',
          icon: 'iconjinrongfuwu',
          bg: jinrongfuwu
				},

      ],
      funcList: [
        {
          desc: '基于计算分析和技术积累，支持高量级关系的计算及知识发现',
          image: r_zhishi
        }
      ]
    }
  }

  render() {
    return (
      <div>
        <Header headerImg={zhishitupu} title={'知识图谱'} desc={'基于知识表示及知识推理等手段描述真实世界，有助于数据表达规范化结构化'} />

        <Features funcList={this.state.funcList}/>

        <ProductAdv advList={this.state.advList}/>

        <RecommondSence recommondSence={this.state.recommondSence} />
        <div className='quickEntry_wrap'>
          <div className='quickEntry'>
            <div className='entry'>
              <span>更多推荐:</span>
              <p>语言处理技术  |  滴滴翻译</p>
            </div>
          </div>
        </div>
        <style jsx>{`
            .recommendScene_wrap {
                width: 100%;
                height: 780px;
                background: #202530;
            }
            .recommendScene {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 94px 0 100px 0;
            }
            .recommendScene>h3 {
                width: 800px;
                height: 53px;
                text-align: center;
                color: #fff;
                font-size: 38px;
                margin: 0 auto;
                margin-bottom: 53px;
            }
            .recommendScene .sceneList {
                display: flex;
                justify-content: space-between;
                width: 1200px;
            }

            .recommendScene .sceneList .sceneItem {
                width: 380px;
                height: 480px;
                padding: 60px 0 0 40px;
                background: #474747;
            }
            .recommendScene .sceneList .sceneItem>span{
                display: block;
                width: 221px;
                height: 30px;
                color: #fff;
                font-size: 22px;
            }
            .recommendScene .sceneList .sceneItem >.mark {
                width: 40px;
                height: 1px;
                color: #F37E43;
                border: 3px solid #F37E43;
                margin: 27px 0 20px 0;
            }
            .recommendScene .sceneList .sceneItem>p {
                width: 300px;
                height: 100px;
                font-size: 14px;
                line-height: 20px;
                color:#ededed;
            }

            .quickEntry_wrap {
              width: 100%;
              height: 300px;
              background: #f5f5f5
            }
            .quickEntry {
              width: 1200px;
              height: 100%;
              margin: 0 auto;
              padding: 100px 0;
            }
            .quickEntry .entry {
              width: 100%;
              height: 100px;
              background: #fff;
              padding: 36px 0 0 40px;
            }
            .quickEntry .entry>span {
              float: left;
              display: block;
              width: 99px;
              height: 28px;
              font-size: 20px;
              color: #747578;
              font-weight: bold;
              text-align: center;
            }
            .quickEntry .entry>p {
              float: left;
              color: #747578;
              font-size: 20px;
            }

            .proFunc_wrap {
                width: 100%;
                height: 2230px;
                background: #f5f5f5;
            }
            .proFunc {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 100px 0 100px 0;
            }
            .proFunc h2 {
                width: 152px;
                height: 45px;
                text-align: center;
                margin: 0 auto;
                font-size: 38px;
                color: #333;
                margin-bottom: 60px
            }
            .proFunc .proList {
                width: 1200px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .proFunc .proList .proItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 380px;
                height: 440px;
                background: #fff;
                margin-bottom: 30px;
            }
            .proFunc .proList .proItem>span {
                display: block;
                width: 140px;
                height: 30px;
                font-size: 20px;
                color: #333;
                text-align: center;
                margin: 20px 0;
            }
            .proFunc .proList .proItem>p {
                width: 310px;
                height: 42px;
                font-size: 14px;
                color: #747578;
                line-height: 22px;
                margin-bottom: 30px;
            }
            .proFunc .proList .proItem .select {
                width: 345px;
                height: 22px;
                display: flex;
                justify-content: space-around;
            }
            .proFunc .proList .proItem .select>a {
                color: #FF7F41 !important;
            }
        `}</style>

      </div>


    )
  }
}
