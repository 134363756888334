import React from 'react'
import Loadable from 'react-loadable'
import Home from '../pages/home'

const loading = () => (
  <div></div>
)

const Voice = Loadable({ loader: () => import('../pages/voice/voice'), loading })

const VoiceWake = Loadable({ loader: () => import('../pages/voice/voicewake'), loading })

const VoiceCompose = Loadable({ loader: () => import('../pages/voice/voicecompose'), loading })

const Understand = Loadable({ loader: () => import('../pages/voice/Understand'), loading })

const Face = Loadable({ loader: () => import('../pages/face/face'), loading })

const FaceAnaly = Loadable({ loader: () => import('../pages/face/face_analy'), loading })

const FaceVerify = Loadable({ loader: () => import('../pages/face/face_verify'), loading })

const FaceRecog = Loadable({ loader: () => import('../pages/face/face_recog'), loading })

const FaceOrient = Loadable({ loader: () => import('../pages/face/face_orient'), loading })

const FaceAnti = Loadable({ loader: () => import('../pages/face/face_anti'), loading })

const FaceDetect = Loadable({ loader: () => import('../pages/face/face_detect'), loading })

const OCR = Loadable({ loader: () => import('../pages/ocr/ocr'), loading })

const OCRID = Loadable({ loader: () => import('../pages/ocr/ocr_id'), loading })

const OCRDL = Loadable({ loader: () => import('../pages/ocr/ocr_dl'), loading })

const OCRVehicle = Loadable({ loader: () => import('../pages/ocr/ocr_vehicle'), loading })

const OCRGeneral = Loadable({ loader: () => import('../pages/ocr/ocr_general'), loading })

const OCRPLATE = Loadable({ loader: () => import('../pages/ocr/ocr_plate'), loading })

const OCRBankCard = Loadable({ loader: () => import('../pages/ocr/ocr_bankcard'), loading })

const OCRBarCode = Loadable({ loader: () => import('../pages/ocr/ocr_barcode'), loading })

const OCRVin = Loadable({ loader: () => import('../pages/ocr/ocr_vin'), loading })

const OCRBusiness = Loadable({ loader: () => import('../pages/ocr/ocr_business'), loading })

const OCRQRcode = Loadable({ loader: () => import('../pages/ocr/ocr_qrcode'), loading })

const OCRCustomize = Loadable({ loader: () => import('../pages/ocr/ocr_customize'), loading })

const NLP = Loadable({ loader: () => import('../pages/NLP/NLP'), loading })

const CarSolution = Loadable({ loader: () => import('../pages/solution/CarSolution'), loading })

const SafeSolution = Loadable({ loader: () => import('../pages/solution/SafeSolution'), loading })

const ServiceSolution = Loadable({ loader: () => import('../pages/solution/ServiceSolution'), loading })

const IntellPrediction = Loadable({ loader: () => import('../pages/data-intelligent/intelligent-prediction'), loading })

const IntellMatching = Loadable({ loader: () => import('../pages/data-intelligent/intelligent-matching'), loading })

const IntellReconmmendation = Loadable({ loader: () => import('../pages/data-intelligent/intelligent-reconmmendation'), loading })

const DataAnalysis = Loadable({ loader: () => import('../pages/data-intelligent/data-analysis'), loading })

const AbnormalMontioring = Loadable({ loader: () => import('../pages/data-intelligent/abnormal-monitoring'), loading })

const SequentialPredict = Loadable({ loader: () => import('../pages/time-data/sequential-predict'), loading })

const CooperDetail2 = Loadable({ loader: () => import('../pages/tech-cooperation/cooper-detail2'), loading })

const CooperDetail3 = Loadable({ loader: () => import('../pages/tech-cooperation/cooper-detail3'), loading })

const CooperDetail4 = Loadable({ loader: () => import('../pages/tech-cooperation/cooper-detail4'), loading })

const CooperDetail5 = Loadable({ loader: () => import('../pages/tech-cooperation/cooper-detail5'), loading })

const CooperDetail6 = Loadable({ loader: () => import('../pages/tech-cooperation/cooper-detail6'), loading })

const Detection = Loadable({ loader: () => import('../pages/face/Detection'), loading})

const FindPartners = Loadable({ loader: () => import('../pages/partners/find-partners/find-partners'), loading})

const nenglikaifang = Loadable({ loader: () => import('../pages/tech-cooperation/nenglikaifang'), loading })

const tuijianzu = Loadable({ loader: () => import('../pages/tech-cooperation/tuijinzu'), loading })

const kdd = Loadable({ loader: () => import('../pages/tech-cooperation/kdd'), loading })
const Zidonghuasuo = Loadable({ loader: () => import('../pages/tech-cooperation/zidonghuasuo'), loading })

const xiaoyuanhezuo = Loadable({ loader: () => import('../pages/tech-cooperation/xiaoyuanhezuo'), loading })

const kaifangpingtai = Loadable({ loader: () => import('../pages/tech-cooperation/kaifangpingtai'), loading })

const PartnerPlan = Loadable({ loader: () => import('../pages/partners/partner-plan/partner-plan'), loading})

const IntelPosition = Loadable({ loader: () => import('../pages/time-data/intelPosition'), loading})

const InterRoad = Loadable({ loader: () => import('../pages/time-data/intelRoad'), loading})

const TravelTime = Loadable({ loader: () => import('../pages/time-data/travelTime'), loading})

const RoutePlanning = Loadable({ loader: () => import('../pages/time-data/routePlanning'), loading})

const ApplicationNlp = Loadable({ loader: () => import('../pages/NLP/application-nlp'), loading})

const IntelligentCar = Loadable({ loader: () => import('../pages/solve-plan/intelligent-car'), loading})
const SmartDriving = Loadable({ loader: () => import('../pages/solve-plan/smart-driving'), loading})
const SmartInteraction = Loadable({ loader: () => import('../pages/solve-plan/smartInteraction'), loading})
const IntelligentOperating = Loadable({ loader: () => import('../pages/solve-plan/intelligent-operating'), loading})
const SmallService = Loadable({ loader: () => import('../pages/solve-plan/smart-service'), loading})
const SmallScheduling = Loadable({ loader: () => import('../pages/solve-plan/smart_scheduling'), loading})
const SmartDispatch = Loadable({ loader: () => import('../pages/solve-plan/smart_dispatch'), loading})
const IntelligentCity = Loadable({ loader: () => import('../pages/solve-plan/intelligent_city'), loading})
const SmartSecurity = Loadable({ loader: () => import('../pages/solve-plan/smart_security'), loading})
const SmartTraffic = Loadable({ loader: () => import('../pages/solve-plan/smart_traffic'), loading})
const Shijuejishu = Loadable({ loader: () => import('../pages/tech-cooperation/shijuejishu'), loading })
const Fangwendidi = Loadable({ loader: () => import('../pages/tech-cooperation/fangwendidi'), loading })
const Rencai = Loadable({ loader: () => import('../pages/tech-cooperation/rencai'), loading })
const Transportation = Loadable({ loader: () => import('../pages/tech-cooperation/transportation'), loading })
const Jiejuefangan = Loadable({ loader: () => import('../pages/tech-cooperation/jiejuefangan'), loading })
const Hezuohuoban = Loadable({ loader: () => import('../pages/tech-cooperation/hezuohuoban'), loading })
const Tongjisiwei = Loadable({ loader: () => import('../pages/tech-cooperation/tongjisiwei'), loading })
const Yingyong = Loadable({ loader: () => import('../pages/tech-cooperation/yingyong'), loading })
const Tiaozhanyujiyu = Loadable({ loader: () => import('../pages/tech-cooperation/tiaozhanyujiyu'), loading })
const Dafangyicai = Loadable({ loader: () => import('../pages/tech-cooperation/dafangyicai'), loading })
const IntelligentTravel = Loadable({ loader: () => import('../pages/solve-plan/intelligent-travel'), loading })
const Chenggongjuban = Loadable({ loader: () => import('../pages/tech-cooperation/chenggongjuban'), loading })
const Laboratory = Loadable({ loader: () => import('../pages/laboratory/laboratory'), loading })
const PageList = Loadable({ loader: () => import('../pages/laboratory/pageList/pageList'), loading })
const DataCenter = Loadable({ loader: () => import('../pages/data-center/data-center'), loading })

const routes = [
  {
    path: '/',
    component: Home
  },
  // 语音技术
  {
    path: '/voice',
    component: Voice
  },
  {
    path: '/voicecompose',
    component: VoiceCompose
  },
  {
    path: '/voicewake',
    component: VoiceWake
  },
  {
    path: '/understand',
    component: Understand
  },
  // 图像技术
  {
    path: '/face',
    component: Face
  },
  {
    path: '/face/:id',
    component: Detection
  },
  {
    path: '/faceanalyze',
    component: FaceAnaly
  },
  {
    path: '/faceverify',
    component: FaceVerify
  },
  {
    path: '/facerecog',
    component: FaceRecog
  },
  {
    path: '/faceorient',
    component: FaceOrient
  },
  {
    path: '/faceanti_spoofing',
    component: FaceAnti
  },
  {
    path: '/facedetect',
    component: FaceDetect
  },
  {
    path: '/ocr',
    component: OCR
  },
  {
    path: '/ocrid',
    component: OCRID
  },
  {
    path: '/ocrdl',
    component: OCRDL
  },
  {
    path: '/ocrvehicle',
    component: OCRVehicle
  },
  {
    path: '/ocrgeneral',
    component: OCRGeneral
  },
  {
    path: '/ocrplate',
    component: OCRPLATE
  },
  {
    path: '/ocrbkcd',
    component: OCRBankCard
  },
  {
    path: '/ocrbcd',
    component: OCRBarCode
  },
  {
    path: '/ocrvin',
    component: OCRVin
  },
  {
    path: '/ocrbusiness',
    component: OCRBusiness
  },
  {
    path: '/ocrqrcode',
    component: OCRQRcode
  },
  {
    path: '/ocrcustomize',
    component: OCRCustomize
  },
  // 自然语言处理
  {
    path: '/NLP',
    component: NLP
  },
  {
    path: '/applicationnlp',
    component: ApplicationNlp
  },
  // 整体解决方案
  {
    path: '/solution/car',
    component: CarSolution
  },
  {
    path: '/solution/safe',
    component: SafeSolution
  },
  {
    path: '/solution/service',
    component: ServiceSolution
  },
//  智能预测
  {
    path: '/intellPrediction',
    component: IntellPrediction
  },
  //  智能匹配
  {
    path: '/intellMatching',
    component: IntellMatching
  },
  //  智能推荐
  {
    path: '/intellRecommendation',
    component: IntellReconmmendation
  },
  //  数据分析
  {
    path: '/dataAnalysis',
    component: DataAnalysis
  },
//  异常监控
  {
    path: '/abnormalMonitoring',
    component: AbnormalMontioring
  },
  //  时序检测
  {
    path: '/sequentialPredict',
    component: SequentialPredict
  },
  // 时空智能
  {
    path: '/intelPosition',
    component: IntelPosition
  },
  // 智能路段
  {
    path: '/interRoad',
    component: InterRoad
  },
  {
    path: '/travelTime',
    component: TravelTime
  },
  {
    path: '/routePlanning',
    component: RoutePlanning
  },
  //  科技合作item-2
  {
    path: '/cooperation/cooper-detail2',
    component: CooperDetail2
  },
  {
    path: '/cooperation/cooper-detail3',
    component: CooperDetail3
  },
  {
    path: '/cooperation/cooper-detail4',
    component: CooperDetail4
  },
  {
    path: '/cooperation/cooper-detail5',
    component: CooperDetail5
  },
  {
    path: '/cooperation/cooper-detail6',
    component: CooperDetail6
  },
  {
    path: '/findpartners',
    component: FindPartners
  },
  {
    path: '/cooperation/nenglikaifang',
    component: nenglikaifang
  },
  {
    path: '/cooperation/tuijinzu',
    component: tuijianzu
  },
  {
    path: '/cooperation/kdd',
    component: kdd
  },
  {
    path: '/cooperation/xiaoyuanhezuo',
    component: xiaoyuanhezuo
  },
  {
    path: '/cooperation/kaifangpingtai',
    component: kaifangpingtai
  },
  {
    path: '/partnerplan',
    component: PartnerPlan
  },
  {
    path: '/intelligentcar', // 智能汽车
    component: IntelligentCar
  },
  {
    path: '/smartdriving', // 智能驾驶
    component: SmartDriving
  },
  {
    path: '/smartinteraction',
    component: SmartInteraction
  },
  {
    path: '/intelligentoperating',
    component: IntelligentOperating
  },
  {
    path: '/smart_service',
    component: SmallService
  },
  {
    path: '/smart_scheduling',
    component: SmallScheduling
  },
  {
    path: '/smart_dispatch',
    component: SmartDispatch
  },
  {
    path: '/intelligent_city',
    component: IntelligentCity
  },
  {
    path: '/smart_security',
    component: SmartSecurity
  },
  {
    path: '/smart_traffic',
    component: SmartTraffic
  },
  {
    path: '/cooperation/zidonghuasuo',
    component: Zidonghuasuo
  },
  {
    path: '/cooperation/shijuejishu',
    component: Shijuejishu
  },
  {
    path: '/cooperation/fangwendidi',
    component: Fangwendidi
  },
  {
    path: '/cooperation/rencai',
    component: Rencai
  },
  {
    path: '/cooperation/transportation',
    component: Transportation
  },
  {
    path: '/cooperation/jiejuefangan',
    component: Jiejuefangan
  },
  {
    path: '/cooperation/hezuohuoban',
    component: Hezuohuoban
  },
  {
    path: '/cooperation/tongjisiwei',
    component: Tongjisiwei
  },
  {
    path: '/cooperation/yingyong',
    component: Yingyong
  },
  {
    path: '/cooperation/tiaozhanyujiyu',
    component: Tiaozhanyujiyu
  },
  {
    path: '/cooperation/dafangyicai',
    component: Dafangyicai
  },
  {
    path: '/cooperation/chenggongjuban',
    component: Chenggongjuban
  },
  {
    path: '/intelligent_travel',
    component: IntelligentTravel
  },
  {
    path: '/labs',
    component: Laboratory
  },
  {
    path: '/page_list',
    component: PageList
  },
  {
    path: '/resources',
    component: DataCenter
  },
]


export default routes
