import React, { Component } from 'react'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import Footer from '../../component/footer/footer'
import Features from '../../component/ProService/Features'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import scrollTop from '../../utils/scrollTop'

import jisulinxian from '../../img/ICON/技术领先.png'
import gexinghua from '../../img/ICON/个性化定制.png'
import fuwujingqiao from '../../img/ICON/拓展性强.png'
import xiaoguoziran from '../../img/ICON/效果自然.png'
import hecheng from './image/header/语音合成.jpg'
import zaixianhecheng from './image/useful/在线语音合成.jpg'
import lixian from './image/useful/离线语音合成.jpg'
import bg_chezai from './image/recommond/智能车载.jpg'
import bg_kefu from './image/recommond/智能客服.jpg'
import bg_shebei from './image/recommond/智能设备.jpg'


const voiceDesc = '采用业界领先的语音合成技术，根据文本生成自然流畅的语音，并经过公司验证，支持音量、语速等调节，支持多种音色，可应用于智能车载、智能客服、智慧设备等场景'

export default class VoiceCompose extends Component {

	constructor(props) {
		super(props)
		this.state = {
			advlist: [
				{
					title: '技术领先',
					desc: '领先的语音合成技术，稳定的语音性能，简易的系统配置，业内领先的在线云端及离线合成引擎方案智能切换',
					icon: jisulinxian
				},
				{
					title: '个性化定制',
					desc: '提供普通话、英语、粤语、中英文混读等多语言及多音色的语音合成方案，支持语速、音量、音调等各种设置',
					icon: gexinghua
				},
				{
					title: '服务精巧',
					desc: '服务响应速度快，服务资源体积小，快速定制化，多平台支持，接入灵活，维护简单，支持SSML动态控制',
					icon: fuwujingqiao
				},
				{
					title: '效果自然',
					desc: '具有接近真人发声的合成效果，声音自然清晰并且具有极强的表现力，支持个性化音色，贴切各种使用场景',
					icon: xiaoguoziran
				}
			],
			funcList: [
				{
					name: '在线语音合成',
					desc: '基于业界领先的混合深度学习语音合成技术，合成音质可以达到接近真人发音的水平，具有合成语音音质高、清晰且自然、极具表现力等特点',
					image: zaixianhecheng
				},
				{
					name: '离线语音合成',
					desc: '采用深度学习技术，具有体积小、功耗低、易调节、效果平稳等特点。可集成到手机、智能设备等，使用时不需要联网就能生成高质量的语音',
					image: lixian
				},
			],
			recommondSence: [
				{
					name: '智能车载',
					content: '在智能车载场景中，优美动听的音频播报，让旅途更加轻松愉快，驾驶员视线不用离开行驶方向，让驾驶更安全',
					icon: 'iconzhinengqiche',
					bg: bg_chezai
				},
				{
					name: '智能客服',
					content: '通过先进的语音合成技术生成高质量、接近自然人声的播报音频，让智能客服更接近真实客服，提升客户的满意度',
					icon: 'iconzhinengkefu',
					bg: bg_kefu
				},
				{
					name: '智能设备',
					content: '为智能家居、音箱、机器人等赋予讲话的能力，结合语音识别、 语义解析，使得设备能听会说，让人机交互更加自然流畅',
					icon: 'iconzhinengzhongduan',
					bg: bg_shebei
				},
			],
			more: [
				{
					name: '语音识别',
					path: '/voice'
				},
				{
					name: '声纹识别',
					path: '/voiceprint'
				}
			]
		};
	}

	componentWillMount() {
		scrollTop()
	}

	render() {
		return (
			<div>
				<Header headerImg={hecheng} title={'语音合成'} desc={voiceDesc} />

				<Features funcList={this.state.funcList} />

				<ProductAdv advList={this.state.advlist} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<MoreRecommend more={this.state.more} />

				<style jsx>{`
					.recommend_wrap {
						width: 100%;
						height: 880px;
						background: #F5F5F5;
					}
					.recommend {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
						text-align: center;
					}
					.recommend h3 {
						font-size: 38px;
						color: #333;
					}
					.rec_list {
						display: flex;
						justify-content: space-around;
						margin-top: 65px;
						width: 100%;
						height: 383px;
						padding-left: 0;
					}
					.rec_list .rec_item {
						width: 380px;
						height:580px;
					}

					.rec_list .rec_item>img {
						width: 380px;
						height: 340px;
						background: #93F086;
					}

					.rec_list .rec_item>div{
						width: 380px;
						height: 240px;
						display: block;
						background: #fff;
						padding: 42px 0 0 28px;
					}
					.rec_list .rec_item>div>h4{
						display: block;
						width: 325px;
						height: 45px;
						color: #333;
						font-size: 20px;
						line-height: 30px;
						text-align: left;
					}
					.rec_list .rec_item>div>p {
						width: 325px;
						height: 120px;
						font-size: 14px;
						color: #747578;
						line-height: 22px;
						text-align: left;
					}


					.quickEntry_wrap {
						width: 100%;
						height: 300px;
						background: #f5f5f5;
					}
					.quickEntry {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
					}
					.quickEntry .entry {
						width: 100%;
						height: 100px;
						background: #fff;
						padding: 36px 0 0 40px;
					}
					.quickEntry .entry>span {
						float: left;
						display: block;
						width: 99px;
						height: 28px;
						font-size: 20px;
						color: #747578;
						font-weight: bold;
						text-align: center;
					}
					.quickEntry .entry>p {
						float: left;
						color: #747578;
						font-size: 20px;
					}
					.recommendScene_wrap {
						width: 100%;
						height: 780px;
						background: #202530;
					}
					.recommendScene {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 94px 0 100px 0;
					}
					.recommendScene>h3 {
						width: 800px;
						height: 53px;
						text-align: center;
						color: #fff;
						font-size: 38px;
						margin: 0 auto;
						margin-bottom: 53px;
					}
					.recommendScene .sceneList {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 1200px;
					}

					.recommendScene .sceneList .sceneItem {
						width: 380px;
						height: 480px;
						padding: 60px 0 0 40px;
						background: #474747;
						margin-bottom: 30px;
					}
					.recommendScene .sceneList .sceneItem>span{
						display: block;
						width: 221px;
						height: 30px;
						color: #fff;
						font-size: 22px;
					}
					.recommendScene .sceneList .sceneItem >.mark {
						width: 40px;
						height: 1px;
						color: #F37E43;
						border: 3px solid #F37E43;
						margin: 27px 0 20px 0;
					}
					.recommendScene .sceneList .sceneItem>p {
						width: 300px;
						height: 100px;
						font-size: 14px;
						line-height: 20px;
						color:#ededed;
					}

					.productAdv_wrap {
						width: 100%;
						height: 547px;
						background: #fff;
					}
					.productAdv {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 95px 0 100px 0
					}
					.productAdv .advList {
						margin-top: 52px;
						display: flex;
						justify-content: space-between;
					}
					.productAdv h3 {
						font-size: 38px;
						color: #333;
						text-align: center;
					}

					.advList .advItem {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.productAdv .advList .advItem .adv_logo {
						position: relative;
						width: 100px;
						height: 100px;
					}

					.advList .advItem .adv_logo>img {
						width: 100px;
						height: 100px;
					}
					.advList .advItem>span {
						display: block;
						width: 149px;
						height: 30px;
						font-size: 20px;
						color: #333;
						letter-spacing: 0.34px;
						text-align: center;
						line-height: 30px;
						margin: 25px 0;
					}
					.advList .advItem>p{
						width: 270px;
						height: 42px;
						color: #747578;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
					}
        		`}</style>
			</div>
		)
	}
}
