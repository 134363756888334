import React, { Component } from 'react';
import { Row, Col } from 'antd';
import $ from 'jquery';

require('./vthumbnails.css')

const colLen = 3;
const colWidth = 140;
const colHeight = 140;

class VThumbnail extends Component {
    constructor(){
        super();
        this.thumbNailClass = "";
    }

    getMarginTop = (idx,items) => {
        if(items == undefined){
            return 0;
        }

        if(items.length >= 3){
            if(idx <=  2){
                if(this.props.nailheight -  Math.ceil(items.length/3)*colHeight >= 0){
                    return (this.props.nailheight -  Math.ceil(items.length/3)*colHeight)/2;
                } else {
                    return 10;
                }                
            } else {
                return 10;
            }
        } else if(items.length == 1){
            return (this.props.nailheight - colHeight)/2;            
        } else if(items.length == 2){
            return (this.props.nailheight - colHeight)/2;            
        }  
    }
    
    getMarginLeft = (idx,items) => {
        if(items.length >= 3){
            if(idx % colLen ==  0){
                return (this.props.nailWidth - 3*colWidth - 20)/2;
            } else {
                return 10;
            }
        } else if(items.length == 1){
            return (this.props.nailWidth - colWidth)/2;            
        } else if(items.length == 2){
            return (this.props.nailWidth - 2*colWidth - 10)/3;            
        }                
    }
    
    render() {
        let imgStyle = {
            width:'180px',
            height:colHeight,            
            borderWidth:'1px',
            borderStyle:'solid',
            borderColor:'#d9d9d9',
            backgroundColor:'#F77B4C',
            border:'1px solid #d9d9d9',
            //boxShadow:'darkgrey 1px 1px 1px 1px',
            marginTop:'10px',
            textAlign:'left',
            paddingLeft:'15px',
            color:'white',
            fontWeight:'bold',
            paddingTop:'20px'
        };
        
        if(this.props.thumbNailClass != undefined){
            this.thumbNailClass = this.props.thumbNailClass;
        } else {
            this.thumbNailClass = "thumbnail";
        }

        var colSpan = Math.floor( 24 / colLen) -1;


        let titleStyle  = {
            fontSize:14
        };

        let itemStyle  = {
            fontSize:18
        };

        if(this.props.items == undefined){
            return <div style={{marginLeft:'20px',width:'180px',height:'300px',backgroundColor:'white',borderColor:'black',border:'3px'}}>test</div>
        }

        const  cols = this.props.items.map((item,idx) =>        
            <Col span={colSpan} style={{...imgStyle,width:colWidth,marginLeft:this.getMarginLeft(idx,this.props.items),marginTop:this.getMarginTop(idx,this.props.items)}}>
                <div style={{...titleStyle}}  >
                    人脸{item.idx}
                </div>
                <div style={{...itemStyle}}  >
                    性别:{item.gender}
                </div>
                <div style={{...itemStyle}}  >
                    年龄:{item.age}
                </div>
                <div style={{...itemStyle}}  >
                    情绪:{item.emotion}
                </div>
            </Col>
        );

        return(
            <Row style={{overflow:'auto',height:'370px'}}>
                {cols}
            </Row>
        )
    }
}

export default VThumbnail;