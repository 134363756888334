import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import Main from './main';
import * as serviceWorker from './serviceWorker';
// import './font/SVGFont/iconfont.css'

// 这个index才是js的入口文件，因为render在这里
ReactDOM.render(<BrowserRouter><Main /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

function setRem() {
  const width = document.documentElement.clientWidth || document.body.clientWidth
  const htmlDom = document.getElementsByTagName('html')[0]
  htmlDom.style.fontSize = width / 120 + 'px'
}

//初始化
setRem()

window.onresize = function() {
  setRem()
}
