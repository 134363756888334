import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Card } from 'antd';
import { Button } from 'antd';
import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom'
import Analy from '../../component/try/face/analy';
import CardH from '../../component/cardh/cardh';
import CardV from '../../component/cardv/cardv';
import $ from 'jquery';

import daijia from '../../img/daijia.png'
import taxi from '../../img/chuzuche.png'
import kuaiche from '../../img/kuaiche.png'
import premier from '../../img/licheng.png'

import jingdu from '../../img/jingdu.png'
import wendingxing from '../../img/wendingxing.png'
import gexinghua from '../../img/gexinghua.png'

import zhuceluru from '../../img/zhuceluru.png'
import rezhengdenglu from '../../img/renzhengdenglu.png'
import renlianshuxing from '../../img/renlianshuxing.jpg'
import jiashizheng from '../../img/jiashizheng.jpg'
import { bannerHeader, bannerCap, bannerDesc, bannerBtn, bannerTitle, cardTitle, prodFunc, prodAdva, recScen, coopCase, coverYoushi } from '../../const.js'

const { Meta } = Card;

const bottomBanner = {
    fontFamily: 'DFPKingGothicGB',
    fontSize: '15px',
    color: '#272933',
    textAlign: 'center'
}

class FaceAnaly extends Component {
    constructor() {
        super();
        this.state = {
            result: '',
            resultJson: ''
        };
    }

    componentDidMount() {
        $(".ant-card-actions").css('background-color', '#FFFFFF');

        let anchorName = window.location.hash;
        if (anchorName) {
            anchorName = anchorName.replace("#", "");
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        }
    }

    onTry = () => {
        console.log(window.location.href, window.location.pathname);
        var urlInfos = window.location.href.split('#');
        if (urlInfos.length >= 1) {
            window.location.href = urlInfos[0] + "#try";
        }
    }

    render() {
        return (
            <div >
                <Row >
                    <Col span={24}>
                        <div id='top' style={{ ...bannerHeader, backgroundImage: `url(${renlianshuxing})` }}>
                            <h1 style={{ ...bannerCap }}>人脸属性分析</h1>
                            <p style={{ ...bannerDesc }}>对图像中的人脸进行性别、年龄、情绪等属性的分析</p>
                            <h2 style={{ ...bannerBtn }}>
                                <Button onClick={this.onTry} style={{ backgroundColor: 'transparent', color: 'white', height: '55%', width: '10%', border: '1px solid white' }} >立即使用</Button>
                                <Button style={{ backgroundColor: 'transparent', color: 'white', marginLeft: '2%', height: '55%', width: '10%', border: '1px solid white' }} >技术文档</Button>
                            </h2>
                        </div>
                    </Col>
                    <Col span={24} style={{ position: 'relative' }}>
                        <br />
                        <br />
                        <div id="try" style={{ ...bannerTitle, background: '#FAFAFA' }}>功能演示</div>
                        <Analy />
                    </Col>
                    <Col span={24}>
                        <div style={{ ...prodAdva }}>
                            <h2 style={{ ...bannerTitle }}>产品优势</h2>
                            <Row gutter={16} >
                                <Col offset={1} span={6}>
                                    <Card
                                        style={{ width: '100%', border: '0px' }}
                                        cover={<img alt="准确可靠" src={jingdu} style={{ ...coverYoushi, marginBottom:'12px'}} />} >
                                        <Meta title="准确可靠"
                                            description="每日百万级别线上数据验证，识别精度达99.9%以上"
                                            style={{ ...cardTitle }} />
                                    </Card>
                                </Col>
                                <Col offset={2} span={6}>
                                    <Card
                                        style={{ width: '100%', border: '0px' }}
                                        cover={<img alt="可用性高" src={wendingxing} style={{ ...coverYoushi, marginBottom:'12px'}} />} >
                                        <Meta title="可用性高"
                                            description="实时识别、支持高并发计算，提供高可用性保证"
                                            style={{ ...cardTitle }} />
                                    </Card>
                                </Col>
                                <Col offset={2} span={6}>
                                    <Card
                                        style={{ width: '100%', border: '0px' }}
                                        cover={<img alt="支持个性化定制" src={gexinghua} style={{ ...coverYoushi, marginBottom:'12px'}} />} >
                                        <Meta title="支持个性化定制"
                                            description="可配合客户实际需求，提供定制化、多模块灵活组合的人脸属性分析服务"
                                            style={{ ...cardTitle }} />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ ...recScen }}>
                            <h2 style={{ ...bannerTitle }}>推荐场景</h2>
                            <Row gutter={16} >
                                <Col offset={1} span={11}>
                                    <CardH
                                        width='100%'
                                        imgWidth='92px'
                                        bodyHeight='110px'
                                        lineHeight='25px'
                                        imgUrl={zhuceluru}
                                        title="用户画像"
                                        style={{ border: '0px' }}
                                        desc="根据图像中人脸的性别、年龄、情感属性，刻画用户特征，进一步为个性化运营工作提供依据"
                                    />
                                </Col>
                                <Col span={11}>
                                    <CardH
                                        width='85%'
                                        imgWidth='92px'
                                        bodyHeight='110px'
                                        lineHeight='25px'
                                        imgUrl={rezhengdenglu}
                                        title="场景分析"
                                        style={{ border: '0px' }}
                                        desc="多维度分析人脸特征，识别出关键场景，在业务管控、智能安防等领域发挥重要作用"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ background: '#FAFAFA', padding: '0px', border: '3px', width: "100%", margin: 'auto' }}>
                            <br />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ background: '#FAFAFA', padding: '30px', border: '3px', width: "100%", margin: 'auto', minWidth: '700px' }}>
                            <h2 style={{ ...bannerTitle }}>服务类型</h2>
                            <Row gutter={16} >
                                <Col offset={1} span={6}>
                                    <CardV
                                        width='95%'
                                        bodyHeight='120px'
                                        style={{ border: '0px' }}
                                        title="云端服务"
                                        desc="提供云端服务API，使用方法简明快捷，有效降低开发成本">
                                    </CardV>
                                </Col>
                                <Col offset={2} span={6}>
                                    <CardV
                                        width='95%'
                                        bodyHeight='120px'
                                        style={{ border: '0px' }}
                                        title="离线识别SDK"
                                        desc="针对移动设备上的识别需求，可提供SDK方案，离线实现功能">
                                    </CardV>
                                </Col>
                                <Col offset={2} span={6}>
                                    <CardV
                                        width='95%'
                                        bodyHeight='120px'
                                        style={{ border: '0px' }}
                                        title="私有化部署"
                                        desc="部署至客户本地服务器，保障数据私密性，提高安全性">
                                    </CardV>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ background: '#FAFAFA', padding: '0px', border: '3px', width: "100%", margin: 'auto' }}>
                            <br />
                            <br />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ ...coopCase }}>
                            <div style={{ ...bannerTitle }}>合作案例</div>
                            <Row gutter={1}>
                                <Col offset={5} span={6}>
                                    <img style={{ width: '55%', border: 'none' }} alt="滴滴快车" src={kuaiche} />
                                </Col>
                                <Col offset={5} span={6}>
                                    <img style={{ width: '55%', border: 'none' }} alt="滴滴代驾" src={daijia} />
                                </Col>
                                <Col offset={5} span={6}>
                                    <img style={{ width: '55%', border: 'none' }} alt="滴滴出租车" src={taxi} />
                                </Col>
                                <Col offset={5} span={6}>
                                    <img style={{ width: '55%', border: 'none' }} alt="滴滴礼橙专车" src={premier} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ background: '#FAFAFA', padding: '0px', border: '3px', width: "100%", margin: 'auto' }}>
                            <br />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{ background: '#FFFAFA', border: '3px', width: "85%", margin: 'auto', fontFamily: 'DFPKingGothicGB' }}>
                            <Col offset={3} span={2}>
                                <div style={{ ...bottomBanner }}>更多推荐:</div>
                            </Col>
                            <Col offset={1} span={2}>
                                <div >
                                    <Link to='/facedetect#top' style={{ ...bottomBanner }}>人脸检测</Link>
                                </div>
                            </Col>
                            <Col offset={1} span={2}>
                                <div >
                                    <Link to='/faceverify#top' style={{ ...bottomBanner }}>人脸验证</Link
                                    ></div>
                            </Col>
                            <Col offset={1} span={24}>
                                <br />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default FaceAnaly;
