import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import navList from '../data/mockNav'

import yuyinjishu from '../img/ICON/语音技术.png'
import tuxinjishu from '../img/ICON/图像技术'
import ziranyuyan from '../img/ICON/自然语言处理.png'
import shujuzhineng from '../img/ICON/智能数据.png'
import shikongshuju from '../img/ICON/深挖数据.png'
import jishuyoushi from '../img/ICON/技术领先.png'
import dashuju from '../img/ICON/海量数据支持.png'
import shangyehua from '../img/ICON/智慧金融.png'
import shengtailian from '../img/ICON/拓展性强.png'
import i_jichang from '../img/ICON/智慧机场.png'
import i_chuxing from '../img/ICON/智慧出行.png'
import zhihuichengshi from '../img/ICON/智慧城市.png'
import zhinengwuliu from '../img/ICON/智慧物流.png'
import shouyetoutu from '../data/shouye/头图.jpg'
import zhinengqiche from '../data/shouye/智能汽车.jpg'
import zhihuichuxing from '../data/shouye/智慧出行.jpg'
import zhihuijichang from '../data/shouye/智慧机场.jpg'
import dengnijiaru from '../data/shouye/等你加入.jpg'
import zhinengchengshi from '../data/shouye/智能城市.jpg'
import zhihuiyuanqu from '../data/shouye/智慧园区.jpg'
import zhihuiwuliu from '../data/shouye/智慧物流.jpg'
import zhihuijinrong from '../data/shouye/智慧金融.jpg'
import zhinengiot from '../data/shouye/智能IOT.jpg'
import i_qiche from '../data/shouye/智能汽车.png'
import i_yunying from '../data/shouye/智能运营.png'
import i_chengshi from '../data/shouye/智慧城市.png'
import i_kefu from '../data/shouye/智能客服.png'
import logo_bg from '../data/shouye/生态伙伴logo.svg'
import pingtaigaishu from '../data/shouye/平台整体概述.png'

const yidong = require('./partners/partner-plan/images/yidong.jpg')
const liantong = require('./partners/partner-plan/images/liantong.jpg')
const shoudujichang = require('./partners/partner-plan/images/shoudujichang.jpg')
const yunanjichang = require('./partners/partner-plan/images/yunnanjichang.jpg')
const yuebotongxin = require('./partners/partner-plan/images/yuebotongxin.jpg')
const woqukeji = require('./partners/partner-plan/images/woqukeji.jpg')
const ronglian = require('./partners/partner-plan/images/ronglian.jpg')
const huayun = require('./partners/partner-plan/images/huayun.jpg')
const ligongdaxue = require('./partners/partner-plan/images/ligongdaxue.jpg')
const yingweida = require('./partners/partner-plan/images/yingweida.jpg')
const rihai = require('./partners/partner-plan/images/rihai.jpg')
const aobizhongguang = require('./partners/partner-plan/images/aobizhongguang.jpg')
const feitiantongxin = require('./partners/partner-plan/images/feitiantongxin.jpg')
const juli = require('./partners/partner-plan/images/juli.jpg')
const openailab = require('./partners/partner-plan/images/openailab.jpg')
const guoyankeji = require('./partners/partner-plan/images/guoyankeji.jpg')
const xilinx = require('./partners/partner-plan/images/xilinx.jpg')
const faleiao = require('./partners/partner-plan/images/faleiao.jpg')
const dipingxian = require('./partners/partner-plan/images/dipingxian.jpg')


const sceneImg = [
  {
    bg: zhihuichuxing,
    icon: 'iconzhihuichuhang1',
    name: '智慧出行',
    desc: '基于滴滴在自身业务中积累的视觉智能、语音智能、语言智能、数据智能和时空智能等AI能力，赋能出行行业，助力智慧出行'
  },
  {bg: zhinengqiche, icon: 'iconzhihuiqiche', name: '智能汽车', desc: '基于滴滴行业领先的AI技术，打造DMS、ADAS等汽车安全智能产品，助力汽车智能升级'},
  {bg: zhihuijichang, icon: 'iconzhinengjichang', name: '智慧机场', desc: '基于滴滴强大的预测和调度能力，为机场提供出行预测方案，缓解机场拥堵状况'},
  {
    bg: zhinengchengshi,
    icon: 'iconzhinengchengshi',
    name: '智慧城市',
    desc: '基于滴滴在交通出行、汽车安全领域的技术积累，延伸到智能安防、智慧园区、智慧交通等智慧城市重要细分领域，助力安全、绿色、高效城市建设'
  },
  {
    bg: zhihuiyuanqu,
    icon: 'iconzhihuiyuanqu',
    name: '智慧园区',
    desc: '利用AI、物联网、大数据、云计算等技术，打造智慧安全解决方案，实现“园区重点区域24小时不间断、实时智能监测” ，打造“事前预警、事中联动、事后辅证”整套流程，提升园区安全性'
  },
  {
    bg: zhihuiwuliu,
    icon: 'iconzhihuiwuliu',
    name: '智慧物流',
    desc: '基于滴滴在运营调度、人车匹配领域的业务积累，通过智能匹配、智能预测、智能视觉等技术，赋能物流预测、物流分发、快递包裹分拣等物流重要环节'
  },
  {
    bg: zhihuijinrong,
    icon: 'iconzhihuijinrong',
    name: '智慧金融',
    desc: '基于滴滴在交通出行业务中沉淀的大数据分析技术，结合金融公司业务历史数据，精确评估信用风险，预测投资回报，快速进行产品定价'
  },
  {bg: zhinengiot, icon: 'iconzhihuiIOT', name: '智能IoT', desc: '基于滴滴领先的AI能力，赋能家居、汽车、玩具等场景，带来智能高效的交互体验'},
]

const aiCategory = [
  {name: '视觉智能', icon: tuxinjishu},
  {name: '语音智能', icon: yuyinjishu},
  {name: '语言智能', icon: ziranyuyan},
  {name: '数据智能', icon: shujuzhineng},
  {name: '时空智能', icon: shikongshuju},
]

const enterProduct = [
  [
    {name: '人脸识别', path: '/face'},
    {name: '人体识别', path: '/body'},
    {name: '文字识别', path: '/character'},
    {name: '车辆分析', path: '/car'},
    {name: '内容审核', path: '/review'}
  ],
  [
    {name: '语音识别', path: '/voice'},
    {name: '语音合成', path: '/voicecompose'},
    {name: '语音增强', path: '/voicesignal'},
    {name: '语音唤醒', path: '/voicewake'},
    {name: '声纹识别', path: '/voiceprint'}
  ],
  [
    {name: '语言基础技术', path: '/NLP'},
    {name: '语言应用技术', path: '/applicationnlp'},
    {name: '知识图谱', path: '/knowledge'},
    {name: '滴滴翻译', path: '/translation'},
    {name: '机器人平台', path: '/robot'}
  ],
  [
    {name: '智能预测', path: '/intellPrediction'},
    {name: '智能推荐', path: '/intellRecommendation'},
    {name: '智能匹配', path: '/intellMatching'},
    {name: '异常检测', path: '/abnormalMonitoring'},
  ],
  [
    {name: '智能定位', path: '/intelPosition'},
    {name: '路线规划', path: '/routePlanning'},
    {name: '行程时间预估', path: '/travelTime'},
    {name: '智能路况', path: '/interRoad'},
  ]
]

const solutionCases = [
  {
    cateName: '智慧出行',
    desc: '整合滴滴AI能力，打造智能出行平台，实现包括智能客服、智能运营，出行安全在内的多维一体化解决方案，赋能平台智能化运营；同时聚焦车辆与交通，开放赋能智能驾驶安全与智能交通感知',
    path: '/intelligent_travel',
  },
  {
    cateName: '智能汽车',
    path: '/intelligentcar',
    desc: '基于海量出行行业数据及领先的视觉智能、语音智能等AI技术，提供包含智能驾驶、智能交互的整体解决方案，通过整合上下游供应商资源，形成关注驾驶安全的端云一体化解决方案，赋能营运车辆场景',
    subList: [
      {
        name: '智能驾驶',
        path: '/smartdriving'
      },
      {
        name: '智能交互',
        path: '/smartinteraction'
      }
    ]
  },
  {
    cateName: '智慧城市',
    path: '/intelligent_city',
    desc: '在安防交通领域，面向平安城市、交通安全、智慧园区及智慧场站提供完善的一体化解决方案，为城市提供智慧的双眼，实现场景感知、数据分析、智能决策等城市治理综合解决方案',
    subList: [
      {
        name: '智能安防',
        path: '/smart_security'
      },
      {
        name: '智能交通',
        path: '/smart_traffic'
      },
    ]
  },
  {
    cateName: '智能运营',
    path: '/intelligentoperating',
    desc: '基于机器学习、智能预测、智能匹配、异常诊断等能力，结合多年实际业务的验证与迭代，推出智能运营解决方案，包括智能排班、智能调度，助力企业降本提效',
    subList: [
      {
        name: '智能排班',
        path: '/smart_scheduling'
      },
      {
        name: '智能调度',
        path: '/smart_dispatch'
      }
    ]
  },
  {
    cateName: '智能客服',
    path: '/smart_service',
    desc: '基于语音识别、自然语言处理、知识图谱、语音合成等AI能力，推出滴滴智能客服解决方案，实现AI能力与客服业务的真正赋能结合，降低人工成本，提高服务质量',
  }

]


const imgLogo = [yidong, liantong, shoudujichang, yunanjichang, yuebotongxin, woqukeji, ronglian, huayun, ligongdaxue, yingweida, rihai, aobizhongguang,
  feitiantongxin, juli, openailab, guoyankeji, xilinx, faleiao, dipingxian]

class Home extends Component {

  state = {
    productId: 0,
    enterProduct: enterProduct,
    sceneWidthX: 0,
    sceneIndex: 0,
    intelImgWidthX: 0,
    resVideoWidthX: 0,
    resourceIndex: 1,
    solutionCaseIndex: 1,
    aiCateWidthX: 0,
    solutionCases: solutionCases[0]
  }

  jumpToNextPage = () => {
    document.transform = 'translateY(100px)'
  }

  switchProduct = (index) => {
    let {aiCateWidthX} = this.state
    aiCateWidthX = index * 15.23
    this.setState({productId: index, aiCateWidthX})
  }

  preScene = () => {
    let {sceneWidthX, sceneIndex} = this.state
    if (sceneIndex === 0) {
      return
    }
    sceneIndex -= 1
    sceneWidthX += 25
    this.setState({sceneWidthX, sceneIndex})
  }

  nextScene = () => {
    let {sceneWidthX, sceneIndex} = this.state
    if (sceneIndex === 5) {
      return
    }
    sceneIndex += 1
    sceneWidthX -= 25
    this.setState({sceneWidthX, sceneIndex})
  }

  preIntelImg = () => {
    let {intelImgWidthX, solutionCaseIndex} = this.state
    if (solutionCaseIndex === 1) {
      return
    }
    solutionCaseIndex -= 1
    intelImgWidthX += 42.5
    this.setState({
      intelImgWidthX,
      solutionCaseIndex,
      solutionCases: solutionCases[solutionCaseIndex - 1]
    })
  }

  nextIntelImg = () => {
    let {intelImgWidthX, solutionCaseIndex} = this.state
    if (solutionCaseIndex === 5) {
      return
    }
    solutionCaseIndex += 1
    intelImgWidthX -= 42.5
    this.setState({
      intelImgWidthX,
      solutionCaseIndex,
      solutionCases: solutionCases[solutionCaseIndex - 1]
    })
  }

  preResVideo = () => {
    let {resVideoWidthX, resourceIndex} = this.state
    if (resourceIndex === 1) {
      return
    }
    resVideoWidthX += 65
    resourceIndex -= 1
    this.setState({resVideoWidthX, resourceIndex})
  }

  nextResVideo = () => {
    let {resVideoWidthX, resourceIndex} = this.state
    if (resourceIndex === 4) {
      return
    }
    resVideoWidthX -= 65
    resourceIndex += 1
    this.setState({resVideoWidthX, resourceIndex})
  }

  render() {
    const {
      productId, enterProduct, sceneWidthX, intelImgWidthX, sceneIndex,
      resVideoWidthX, resourceIndex, solutionCaseIndex, aiCateWidthX, solutionCases
    } = this.state

    return (
      <div>
        <div className='header-wrap'>
          <div className='header-dot'>
            <span className='eng-name'>DiDi AI LABS</span>
            <h1 className='title'>滴滴AI开放平台</h1>
            <p className='dot-desc'>赋能行业，共建全产业链AI生态</p>
          </div>
          {/*<a className='jump-to-second iconfont iconzhankaidown' href='#resource-wrap'></a>*/}
         {/* <div className='header-dot'>
            <span className='eng-name'>DiDi AI LABS</span>
            <h1 className='title'>滴滴AI开放平台即将发布</h1>
            <p className='dot-desc'>2019.10.18，敬请期待！</p>
          </div>*/}
        </div>
        {/*资料库*/}
        {/*<div className='resource-wrap' id="resource-wrap">
					<div className='resource-title'>
						<h3>资料库</h3>
						<span>RESOURCE</span>
					</div>
					<div className='swiper-container'>
						<div className='swiper-wrap' style={{transform: `translateX(${resVideoWidthX}rem)`}}>
							<div className='swiper-slide'></div>
							<div className='swiper-slide'></div>
							<div className='swiper-slide'></div>
							<div className='swiper-slide'></div>
						</div>
					</div>
					<div className='resource-num' style={{left: `${resourceIndex > 1 ? '29.53rem' : '16.88rem'}`}}>{`0${resourceIndex}`}</div>
					<div className='swiper-arrow'>
						<div className='iconfont iconshouyeicon-copy'
							style={{color: `${resourceIndex === 1 ? '#9d9d9d' : '#333'}`, borderColor: `${resourceIndex === 1 ? 'transparent' : '#333'}`}}
							onClick={this.preResVideo}
						></div>
						<div className='iconfont iconshouyeicon'
							style={{color: `${resourceIndex === 4 ? '#9d9d9d' : '#333'}`, borderColor: `${resourceIndex === 4 ? 'transparent' : '#333'}`}}
							onClick={this.nextResVideo}
						></div>
					</div>
				</div>*/}

        {/*这下面是要在对外平台只展示头部图片，其他的全部隐藏*/}
        <div className='diamond-wrap'>
          <div className='diamond-title'>
            <h3>开放全球领先的AI服务</h3>
            <span>AI SERVICES & CAPABILITIES</span>
          </div>
          <div className='ai-category-list'>
            {
              aiCategory.map((item, index) => (
                <div className='category-item'
                     key={index}
                     onMouseEnter={() => this.switchProduct(index)}
                >
                  <div className='category-item-icon' style={{backgroundImage: `url(${item.icon})`}}></div>
                  <span
                    style={{marginTop: 10, fontSize: '1.38rem', fontWeight: '500', color: '#333'}}>{item.name}</span>
                </div>
              ))
            }
          </div>
          <div className='single-tech-wrap'>
            <div className='single-tech'>
              {
                enterProduct[productId].map((item, index) => (
                  <Link className='product-active' style={{fontSize: '1.38rem'}}
                        to={item.path}
                        key={index}
                  >{item.name}</Link>
                ))
              }
            </div>
          </div>
        </div>

        <div className='intel-list'>
          <div className='intel-list-title'>
            <h3>解决方案</h3>
            <span>PRODUCT SOLUTIONS</span>
          </div>
          <div className='car-box'>
            <h3 className='car-name'
                onClick={() => this.props.history.push(solutionCases.path)}
            >{solutionCases.cateName}</h3>
            <div className="car-desc">{solutionCases.desc}</div>
            <div className='car-list'>
              {
                solutionCases.subList && solutionCases.subList.map((item, index) => (
                  <div key={index}
                     className='car-item'
                     onClick={() => this.props.history.push(item.path)}
                  >{item.name} </div>
                ))
              }
            </div>
            <div className='intel-arrow' style={{display: 'flex'}}>
              <div className='iconfont iconshouyeicon-copy'
                   onClick={this.preIntelImg}
                   style={{
                     color: `${solutionCaseIndex === 1 ? '#9d9d9d' : '#333'}`,
                     borderColor: `${solutionCaseIndex === 1 ? 'transparent' : '#333'}`
                   }}
              ></div>
              <div className='iconfont iconshouyeicon'
                   onClick={this.nextIntelImg}
                   style={{
                     color: `${solutionCaseIndex === 5 ? '#9d9d9d' : '#333'}`,
                     borderColor: `${solutionCaseIndex === 5 ? 'transparent' : '#333'}`
                   }}
              ></div>
            </div>
          </div>
          <div className='intel-contariner'>
            <div className='intel-wrap' style={{transform: `translateX(${intelImgWidthX}rem)`}}>
              <div className='car-img intel-img' style={{backgroundImage: `url(${zhihuichuxing})`}}></div>
              <div className='car-img intel-img' style={{backgroundImage: `url(${i_qiche})`}}></div>
              <div className='car-img intel-img' style={{backgroundImage: `url(${i_chengshi})`}}></div>
              <div className='car-img intel-img' style={{backgroundImage: `url(${i_yunying})`}}></div>
              <div className='car-img intel-img' style={{backgroundImage: `url(${i_kefu})`}}></div>
            </div>
          </div>
          <div className='intel-page-dot'>
            <div className='page-dot' style={{background: `${solutionCaseIndex === 1 ? '#fff' : '#666'}`}}></div>
            <div className='page-dot' style={{background: `${solutionCaseIndex === 2 ? '#fff' : '#666'}`}}></div>
            <div className='page-dot' style={{background: `${solutionCaseIndex === 3 ? '#fff' : '#666'}`}}></div>
            <div className='page-dot' style={{background: `${solutionCaseIndex === 4 ? '#fff' : '#666'}`}}></div>
            <div className='page-dot' style={{background: `${solutionCaseIndex === 5 ? '#fff' : '#666'}`}}></div>
          </div>
        </div>

        <div className='core-adv-wrap'>
          <h2>核心优势</h2>
          <span>CORE ADVANTAGES</span>
          <div className='adv-list'>
            <div className='adv-item'>
              <span className='iconfont iconjishulingxian'></span>
              <span className='adv-item-name'>技术领先</span>
              <p className='adv-item-desc'>滴滴拥有十分完备的技术架构，多项技术已达国际领先水平</p>
            </div>
            <div className='adv-item'>
              <span className='iconfont icondashujuyoushi'></span>
              <span className='adv-item-name'>大数据优势</span>
              <p className='adv-item-desc'>沉淀海量出行数据，以AI能力服务亿万用户</p>
            </div>
            <div className='adv-item'>
              <span className='iconfont iconshangyehuachangjingyoushizhihuijinrong'></span>
              <span className='adv-item-name'>商业化场景优势</span>
              <p className='adv-item-desc'>全球卓越的移动出行平台， AI能力全面赋能实际业务场景</p>
            </div>
            <div className='adv-item'>
              <span className='iconfont iconshengtailianyoushi'></span>
              <span className='adv-item-name'>生态链优势</span>
              <p className='adv-item-desc'>与产业链企业开展紧密合作， 共同推动未来技术发展</p>
            </div>
          </div>
        </div>

        <div className='recommend-scene-wrap'>
          <div className='recommend-scene-title'>
            <h2>赋能行业，无缝贴合真实业务场景</h2>
            <span>INDUSTRY EMPOWERMENT</span>
          </div>
          <div className='scene-swpier-container'>
            <div className='scene-swiper-wrap'
                 style={{transform: `translateX(${sceneWidthX}rem)`}}
            >
              {
                sceneImg.map((item, index) => (
                  <div className='scene-swiper-slide'
                       key={index}
                       style={{backgroundImage: `url(${item.bg})`}}
                  >
                    <div className='scene-mask'></div>
                    <div className={`icon iconfont scene-icon ${item.icon}`}></div>
                    <span className='scene-name'>{item.name}</span>
                    <p className='scene-desc'>{item.desc}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className='arrow_left icon iconfont iconshouyeicon-copy'
               style={{
                 color: `${sceneIndex === 0 ? 'rgb(139, 130, 130)' : '#fff'}`,
                 borderColor: `${sceneIndex === 0 ? 'transparent' : '#fff'}`
               }}
               onClick={this.preScene}
          ></div>
          <div className='arrow_right icon iconfont iconshouyeicon'
               style={{
                 color: `${sceneIndex === 5 ? 'rgb(139, 130, 130)' : '#fff'}`,
                 borderColor: `${sceneIndex === 5 ? 'transparent' : '#fff'}`
               }}
               onClick={this.nextScene}
          ></div>
        </div>

        <div className='cooper-height'>
          <div className='cooper-list-title'>
            <h3>携手行业，共建AI生态</h3>
            <span>ECOLOGICAL COOPERATION</span>
          </div>
          <div className="logo-bg" style={{backgroundImage: `url(${logo_bg})`}}>
            <div className="logo-desc">（排名不分先后）</div>
          </div>
          {/*<div className="logo-content">
							{
								imgLogo.map((item, index) => (
									<img src={item} alt="logo" className="logo-content-img" key={index}/>
								))
							}
						</div>*/}
          <a href='/findpartners' className="home-detail">更多详情</a>
        </div>

        <div className='wait-join-wrap'>
          <div className='wait-join-title'>
            <h3>平台整体能力概述</h3>
            <span>PLATFORM CAPABILITIES</span>
          </div>
          <div className='wait-join'>
            <h4>开放全线产品与服务，等你加入</h4>
            <p className='wait-join-desc'>我们致力于构建全球领先的人工智能技术与解决方案开放平台</p>
            <p className='wait-join-desc'>提供高效易用的API、SDK等开发组件，助力业界快速实现产品升级</p>
            <div className='wait-join-split'></div>
            <div className='wait-join-num'>
              <div className='join-num'>
                <span className='num'>56</span>
                <span>项</span>
                <p className='join-feature-item'>技术能力</p>
              </div>
              <div className='join-num'>
                <span className='num'>8</span>
                <span>项</span>
                <p className='join-feature-item'>解决方案</p>
              </div>
              <div className='join-num'>
                <span className='num'>24</span>
                <span>小时</span>
                <p className='join-feature-item'>快速集成</p>
              </div>
            </div>
          </div>
          <div className='wait-join-img' style={{backgroundImage: `url(${pingtaigaishu})`}}></div>
        </div>

        <style jsx>{`
					.header-wrap {
						position: relative;
						margin-top: -50px;
						width: 120rem;
						height: 67.5rem;
						background-image: url(${shouyetoutu});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.jump-to-second{
						width: 40px;
						height: 40px;
						position: absolute;
						color: #fff;
						font-size: 40px;
						top: 85%;
						animation: 1s ease-in down infinite;
						animation-fill-mode: forwards;
						cursor: pointer;
					}
					@keyframes down{
						from {
							opacity: 0.3
						}
						to {
							opacity: 1;
							transform: translateY(10px);
						}
					}

					.header-dot {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 42.5rem;
						height: 42.5rem;
						border: 4px solid #FFFFFF;
						border-radius: 50%;
					}

					.eng-name {
						font-family: Helvetica;
						font-size: 32px;
						color: #FFFFFF;
						letter-spacing: 2px;
						margin-top: 11.88rem;
					}

					.title {
						font-family: DFPKingGothicGB;
						font-size: 46px;
						color: #FFFFFF;
						letter-spacing: 0;
						margin: 2rem 0 2.75rem 0;
					}

					.dot-desc {
						font-family: PingFangSC-Light;
						font-size: 18px;
						color: #FFFFFF;
						letter-spacing: 2px;
						line-height: 28px;
						text-align: center;
					}

					.dot-wechat {
						width: 9.94rem;
						height: 2.31rem;
						border: 1px solid #C1C1C1;
						border-radius: 2px;
						border-radius: 2px;
						margin-top: 3.69rem;
						display: flex;
						align-items: center;
					}

					.wechat {
						margin-left: 20px;
						font-family: PingFangSC-Light;
						font-size: 14px;
						color: #FFFFFF;
						letter-spacing: 0;
					}

					.resource-wrap {
						position: relative;
						width: 120rem;
						height: 67.5rem;
						background: #f5f5f5;
						overflow: hidden;
					}

					.resource-title {
						margin: 9.19rem 0 2.5rem 12.63rem;
					}
					.resource-title>h3 {
						font-size: 2.38rem;
						color: #333;
						margin-bottom: 0;
					}
					.resource-title>span {
						font-size: 1.63rem;
						color: #ababab;
					}

					.swiper-container {
						width: 120rem;
						height: 31.25rem;
						overflow: hidden;
					}
					.swiper-wrap {
						display: flex;
						margin-left: 32.5rem;
						transition: 0.5s linear;
					}

					.swiper-slide {
						flex-shrink: 0;
						width: 55rem;
						height: 31.25rem;
						margin-right: 10rem;
						background: #666666;
					}
					.resource-num{
						text-align: center;
						line-height: 5.94rem;
						font-family: Helvetica;
						font-size: 28px;
						color: #FFFFFF;
						position: absolute;
						width: 5.94rem;
						height: 5.94rem;
						background: #002d6b;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
					}
					.swiper-arrow{
						display: flex;
						position: absolute;
						bottom: 8.25rem;
						left: 12.63rem;
					}
					.swiper-arrow .iconfont{
						cursor: pointer;
						width: 3.5rem;
						height: 3.5rem;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 90px;
						margin-right: 1.5rem;
						border-radius: 50%;
					}
					.swiper-arrow .iconfont:hover{
						border: 3px solid #333;
					}

					.diamond-wrap {
						width: 120rem;
						background: #fff;
						overflow: hidden;
					}

					.diamond-title {
						margin: 9.19rem 0 6.16rem 12.63rem;
					}
					.diamond-title>h3 {
						font-size: 2.38rem;
						color: #333;
						margin-bottom: 0;
					}
					.diamond-title>span{
						font-size: 1.63rem;
						color: #A8A8A8;
					}
					.ai-category-list {
						width: 66.75rem;
						margin: 0 auto;
						display: flex;
						justify-content: space-between;
					}
					.category-item {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.category-item-icon {
						width: 5.8rem;
						height: 5.8rem;
						background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
					}
					.single-tech-wrap {
						margin-top: 2.56rem;
						width: 120rem;
						height: 15.63rem;
						background: #10182B;
					}
					.single-tech::before {
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						top: -10px;
						left: 2rem;
						border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #10182B;
						transition: 0.2s;
						transform: translateX(${aiCateWidthX}rem);
					}
					.single-tech {
						position: relative;
						height: 100%;
						width: 66.75rem;
						margin: 0 auto;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.intel-list {
						position: relative;
						width: 120rem;
						height: 57.75rem;
						background: #fff;
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
					.intel-list-title{
						position: absolute;
						top: 9.06rem;
						left: 12.56rem;
					}
					.cooper-list-title{
					    box-sizing: content-box !important;
              padding-top: 9.06rem;
              padding-left: 12.56rem;
					}
					.intel-list-title>h3, .cooper-list-title>h3{
						font-size: 2.38rem;
						color: #333;
						margin-bottom: 0;
					}
					.intel-list-title>span, .cooper-list-title>span{
						font-size: 1.63rem;
						color: #ababab;
					}

					.intel-list .car-box {
						position: relative;
						margin: 5.13rem 0 0 18.81rem;
						width: 120px;
						height: 270px;
					}

					.car-name {
						font-family: DFPKingGothicGB;
						font-size: 1.5rem;
						color: #666666;
						letter-spacing: 0;
						line-height: 40px;
						margin-bottom: 0.44rem;
						cursor: pointer;
					}
					.car-desc{
						font-size: 1.125rem;
						color: #666666;
						letter-spacing: 0;
						line-height: 24px;
						margin-bottom: 1.44rem;
						width: 38.06rem;
					}

					.car-item {
						position: relative;
						font-size: 1.125rem;
						color: #666666;
						letter-spacing: 2px;
						text-indent: 0.88rem;
						cursor: pointer;
					}
					.car-item::before{
						position: absolute;
						content: '';
						display: block;
						width: 0.31rem;
						height: 0.31rem;
						border-radius: 50%;
						background: #333;
						top: 50%;
						transform: translateY(-50%);
					}
					.intel-arrow {
						position: absolute;
						bottom: -93px;
						margin-top: 3.56rem;
						margin-left: -1rem;
					}
					.intel-arrow .iconfont {
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 3rem;
						height: 3rem;
						font-size: 90px;
						margin-right: 30px;
					}
					.intel-arrow .iconfont:hover{
						border: 3px solid #333;
						border-radius: 50%;
					}
					.intel-contariner {
						width: 42.5rem;
						height: 42.5rem;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 15.13rem;
					}
					.intel-wrap {
						display: flex;
						transition: 0.5s ;
					}
					.intel-img {
						flex-shrink: 0;
						width: 42.5rem;
						height: 42.5rem;
						border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
					}
					.intel-page-dot{
						position: absolute;
						display: flex;
						flex-direction: column;
						right: 5.38rem;
						top: 50%;
						transform: translateY(-50%);
					}
					.page-dot{
						width: 0.75rem;
						height: 0.75rem;
						border-radius: 50%;
						margin-bottom: 1.88rem;
						border: 1px solid #666;
					}

					.core-adv-wrap {
						width: 120rem;
						background: #f8f8f8;
						padding: 9.94rem 0 8rem;
					}

					.core-adv-wrap>h2{
						font-family: DFPKingGothicGB;
						font-size: 2.38rem;
						color: #333333;
						margin-top: 0;
						margin-bottom: 0;
						margin-left: 12.56rem;
					}

					.core-adv-wrap>span{
						font-size: 1.63rem;
						color: #A8A8A8;
						margin-left: 12.56rem;
					}

					.adv-list {
						display: flex;
						justify-content: space-between;
						margin: 0 auto;
						width: 1200px;
						margin-top: 7.55rem;
					}

					.adv-item {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 16.88rem;
					}
					.adv-item>.iconfont {
						font-size: 80px;
					}

					.adv-item-name {
						font-family: DFPKingGothicGB;
						font-size: 22px;
						color: #333333;
						letter-spacing: 0.34px;
						margin: 2.41rem 0 1.5rem 0;
					}

					.adv-item-desc {
						text-align: center;
					}

					.recommend-scene-wrap {
						position: relative;
						width: 120rem;
						height: 66rem;
						background: #202530;
						overflow: hidden;
					}

					.recommend-scene-title　{
						margin: 9.69rem 0 4.75rem 12.56rem;
					}

					.recommend-scene-title>h2 {
						font-family: DFPKingGothicGB;
						font-size: 2.38rem;
						color: #FFFFFF;
						margin-bottoddm: 0;
					}

					.recommend-scene-title>span{
						font-size: 1.63rem;
						color: #A8A8A8;
					}
					.scene-swpier-container {
						position: relative;
						margin: 0 auto;
						width: 75rem;
						height: 30rem;
						overflow: hidden;
					}
					.scene-swiper-wrap {
						position: absolute;
						display: flex;
						width: 175rem;
						height: 100%;
						transition: 0.2s linear;
					}
					.scene-swiper-slide {
						flex-shrink: 0;
						position: relative;
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 25rem;
						height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
					}
					.scene-swiper-slide:hover .scene-mask {
						background: rgba(25,56,123,0.9) !important;
					}
					.scene-swiper-slide:hover .scene-icon, .scene-swiper-slide:hover .scene-name{
						transform: translateY(-108px);
					}
					.scene-mask {
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background: rgba(25,56,123,0.55) !important;
						transition: 0.2s;
					}
          div{
            box-sizing: border-box;
          }
					.scene-swiper-slide .icon {
						position: relative;
						z-index: 5;
						width: 80px;
						height: 80px;
						font-size: 70px;
						color: #fff;
						text-align: center;
						background-repeat: no-repeat;
						background-size: 100%;
						background-position: center center;
						border-radius: 50%;
						margin-top: 10.5rem;
						transition: 0.3s;
					}
					.scene-swiper-slide>span {
						position: relative;
						z-index: 5;
						color: #fff;
						font-size: 1.37rem;
						margin-top: 33px;
						transition: 0.3s;
					}
					.scene-swiper-slide>p{
						margin-top: 24px;
						visibility: hidden;
						width: 18.33rem;
						height: 100px;
						font-size: 14px;
						line-height: 20px;
						color:#ededed;
						opacity: 0;
						transition: 0.5s;
					}
					.scene-swiper-slide:hover .scene-desc{
						visibility: visible !important;
						transform: translateY(-108px);
						opacity: 1 !important;
					}
				 .arrow_right, .arrow_left {
					 	width: 3.5rem;
						height: 3.5rem;
					 	display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
						font-size: 90px;
						position: absolute;
						top: 50%;
						cursor: pointer;
					}
					.arrow_right:hover, .arrow_left:hover{
						border-radius: 50%;
						border: 3px solid #fff;
					}
					.arrow_left{
						left: 14rem;
					}
					.arrow_right{
						right: 14rem;
					}

					.wait-join-wrap {
						width: 120rem;
						height: 47.185rem;
						background: #F8F8F8;
						display: flex;
						position: relative;
					}
					.wait-join-title{
						position: absolute;
						top: 10rem;
						left: 12.5rem;
					}
					.wait-join-title>h3{
						font-size: 2.38rem;
						color: #333;
						margin-bottom: 0;
					}
					.wait-join-title>span{
						font-size: 1.63rem;
						color: #ababab;
					}

					.wait-join {
						width: 50%;
						height: 100%;
						padding: 21rem 0 0 12.5rem;
					}
					.wait-join>h4{
						font-family: DFPKingGothicGB;
						font-size: 1.88rem;
						color: #666666;
						letter-spacing: 0;
						margin-bottom: 1.06rem;
					}
					.wait-join-desc{
						font-size: 1rem;
						color: #696969;
						margin-bottom: 20px;
					}
					.wait-join-split{
						margin: 4rem 0 2.25rem 0;
						width: 38.75rem;
						height: 1px;
						opacity: 0.2;
						border: 1px solid #333;
					}

					.wait-join-num {
						display: flex;
						justify-content: space-between;
						width: 30rem;
					}

					.num {
						font-family: Helvetica;
						font-size: 4.13rem;
						color: #666666;
					}

					.join-feature {
						display: flex;
						justify-content: space-between;
						width: 40rem;
					}

					.join-feature-item {
						font-family: DFPKingGothicGB;
						font-size: 1.38rem;
						color: #333333;
						letter-spacing: 2px;
					}

					.wait-join-img {
						width: 50%;
						height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
					}
					p{
						margin-bottom: 0;
					}
					.logo-bg{
					  position: relative;
            background-repeat: no-repeat;
            width: 1050px;
            height: 453px;
            margin: 2rem auto 8rem;
					}
					.logo-desc{
						position: absolute;
						right: 0;
						bottom: -20px;
					}
					.logo-content{
						width: 1020px;
						display: flex;
						justify-content: flex-start;
						flex-wrap: wrap;
						margin:220px auto 80px;
						}
						.logo-content-img{
							width: 200px;
							height: 80px;
							// margin: 0 5px 4px 0;
						}
						.logo-content-img:nth-child(5n) {
							margin-right: 0;
						}
						.cooper-height{
							position: relative;
              width: 120rem;
              background: #fff;
              padding-bottom: 8.5rem;
						}
						.home-detail{
							display: block;
							width: 175px;
							height: 45px;
							line-height: 45px;
							border: 1px solid #19387B;
							border-radius: 2px;
							font-size: 16px;
							color: #19387B;
							text-align: center;
							cursor: pointer;
							margin: 0 auto;
						}
						.home-detail:hover{
							color: #fff;
							background-color: #19387B ;
						}
				`}</style>
      </div>
    )
  }
}


export default withRouter(Home)
