import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import Footer from '../../component/footer/footer'
import './face.css'

import neirongshenhe from '../../data/内容审核JPG/header/涉黄图像检测.jpg'
import zidingyi from '../../data/内容审核JPG/自定义内容检测.jpg'
import shehuang from '../../data/内容审核JPG/涉黄图像检测.jpg'
import shezhen from '../../data/内容审核JPG/涉政图像检测.jpg'
import zhucexinxi from '../../data/内容审核JPG/图像视角/推荐场景x6/注册信息审核.jpg'
import zhibopingtai from '../../data/内容审核JPG/图像视角/推荐场景x6/直播平台审核.jpg'
import shipinsheneh from '../../data/内容审核JPG/图像视角/推荐场景x6/视频审核.jpg'
import ugc from '../../data/内容审核JPG/图像视角/推荐场景x6/UGC内容.jpg'
import zhunquekekao from '../../img/ICON/保密可靠.png'
import keyongqingao from '../../img/ICON/适应性强.png'
import duozhongzhichi from '../../img/ICON/通用性强.png'

const ProductFunc = ({features}) => (
	<div>
		<div className='proFunc_wrap'>
			<div className='proFunc'>
				<h2>产品功能</h2>
				<div className='proList'>
					{
						features.map((item, index) => (
							<div className='proItem' key={index}>
								<div className='back-img-wrap' style={{ width: 380, height: 260 }}>
									<div className='back-img' style={{ width: 380, height: 260, backgroundImage: `url(${item.img})` }}></div>
								</div>
								<span style={{ fontWeight: 100 }}>{item.name}</span>
								<p>{item.desc}</p>
								<div className='select'>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to='/support'>合作咨询</Link>
									<div style={{width: 1, height: 39, background: '#e8e8e8', position: 'absolute'}}></div>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to={item.path || ''}>了解详情</Link>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>

		<style jsx>{`
			.proFunc_wrap {
					width: 100%;
					background: #f5f5f5;
			}
			.proFunc {
					width: 1200px;
					height: 100%;
					margin: 0 auto;
					padding: 100px 0 100px 0;
			}
			.proFunc h2 {
					width: 152px;
					height: 45px;
					text-align: center;
					margin: 0 auto;
					font-size: 38px;
					color: #333;
					margin-bottom: 60px
			}
			.proFunc .proList {
					width: 1200px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
			}
			.proFunc .proList .proItem {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 380px;
					background: #fff;
					margin-bottom: 30px;
			}

			.back-img-wrap {
				overflow: hidden;
			}

			.back-img {
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center center;
				transition: 0.5s linear;
			}

			.back-img:hover {
				transform: scale(1.1)
			}

			.proFunc .proList .proItem>span {
					display: block;
					width: 140px;
					height: 30px;
					font-weight: bold;
					font-size: 20px;
					color: #333;
					text-align: center;
					margin: 20px 0;
			}
			.proFunc .proList .proItem>p {
					width: 310px;
					height: 42px;
					font-size: 14px;
					color: #747578;
					line-height: 22px;
					margin-bottom: 30px;
			}
			.proFunc .proList .proItem .select {
					width: 380px;
					height: 65px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					border-top: 1px solid #E8E8E8;
					position: relative;
			}

		`}</style>
	</div>
)

export default class Review extends Component {

	state = {
		features: [
			{
				name: '涉黄图像检测',
				desc: '以人工智能鉴黄技术识别图像中的内容，可高效筛选并标记出色情、性感图像',
				img: shehuang,
				path: '/review/yellow'
			},
			{
				name: '涉政图像检测',
				desc: '对涉政敏感信息进行识别，有效减少人力审核成本，保障网络安全',
				img: shezhen,
				path: '/review/political'
			},
			{
				name: '自定义内容检测',
				desc: '可根据客户在不同场景下的需求，提供自定义内容检测服务，有效提升服务效率',
				img: zidingyi,
				path: '/review/custom'
			},
		],

		productAdv: [
			{
				title: '海量数据',
				desc: '基于滴滴大数据库训练模型，并拥有海量出行领域数据积累，样本数据丰富',
				icon: zhunquekekao
			},
			{
				title: '技术领先',
				desc: '专业人工智能技术团队基于深度学习模型， 运用聚类、回归等数据挖掘类算法，进行数据分析',
				icon: keyongqingao
			},
			{
				title: '结果准确',
				desc: '经公司大量业务落地验证，审核结果准确率高，提升企业业务质量',
				icon: duozhongzhichi
			}
		],
		recommondSence: [
			{
				name: '注册信息审核',
				content: '对网站、社交、游戏、H5活动页面等账号申请信息填写的审核，有效规避借用账号注册进行违法信息传播的风险',
				bg: zhucexinxi,
				icon: 'iconzizhishenhe'
			},
			{
				name: '视频内容审核',
				content: '提供视频审核接口，可依据业务实际需要，对图像、字幕、声音等要素进行多功能、多维度的自动化内容审核，综合判断后返回审核结果',
				bg: shipinsheneh,
				icon: 'iconshipinzhiboshenhe'
			},
			{
				name: '直播平台审核',
				content: '在直播场景中可采用人工智能内容审核技术，及时高效发现涉黄、涉政等违规内容并提出告警，保证平台内容健康',
				bg: zhibopingtai,
				icon: 'iconzhibopingtaishenhe'
			},
      {
				name: '平台内容反垃圾',
				content: '对图片网站、UGC网站、电商及社交平台等进行全面内容审核，可依据实际需求筛查违规广告及其他敏感信息，规避潜在的业务风险',
				bg: ugc,
				icon: 'iconshejiaodianshangpingtaijianguan'
      }
		],
		more: [
			{
				name: '人体识别',
				path: '/body'
			},
			{
				name: '文字识别',
				path: '/character'
			}
		]
	}

	render() {
		return (
			<div>
				<Header headerImg={neirongshenhe} title={'内容审核'} desc={'基于领先的深度学习能力，可精准识别文本、图片以及视频内容中的敏感信息，提高人力审核效率，维护健康绿色的网络环境'} />

				<ProductFunc features={this.state.features} />

				<ProductAdv advList={this.state.productAdv} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<MoreRecommend more={this.state.more} />

			</div>
		)
	}
}
