import React, { Component } from 'react'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import Footer from '../../component/footer/footer'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import scrollTop from '../../utils/scrollTop'

import jisulinxian from '../../img/ICON/技术领先.png'
import duoyingjian from '../../img/ICON/多硬件支持.png'
import duopingtai from '../../img/ICON/多平台支持.png'
import gexinghua from '../../img/ICON/个性化定制.png'
import zengqiang from './image/header/语音增强.jpg'
import bosu_bg from './image/useful/波束形成.jpg'
import shengyuandinwei from './image/useful/声源定位.jpg'
import huishengxiaochu from './image/useful/回声消除.jpg'
import mangyuanfenli from './image/useful/盲源分离.jpg'
import zaoshenyizhi from './image/useful/噪声抑制.jpg'
import zidongzengyi from './image/useful/自动增益.jpg'
import bg_chezai from './image/recommond/智能车载.jpg'
import bg_jiqiren from './image/recommond/智能机器人.jpg'
import bg_jiaju from './image/recommond/智能家居400.jpg'

const voiceDesc = '用于抑制干扰信号，提升语音信号质量。采用业内先进的算法，在多个业务场景落地验证，性能可靠稳定，为离在线语音识别、语音唤醒、语音交互系统提供前端高质量信号保证'

export default class Voice extends Component {

  constructor(props) {
    super(props)
    this.state = {
      advList: [
        {
          title: '技术领先',
					desc: '基于国际先进的语音信号处理技术，并在多个大体量产品中落地验证，并不断迭代优化',
					icon: jisulinxian
        },
        {
          title: '多硬件方案支持',
					desc: '提供单麦克方案、线形麦克阵列方案、环形麦克阵列方案等支持',
					icon: duoyingjian
        },
        {
          title: '多平台支持',
					desc: '支持linux、android、ios、windows等，可提供对应SDK或各种版本库',
					icon: duopingtai
        },
        {
          title: '定制化设计',
					desc: '可以按照客户需求，有针对性地优化算法',
					icon: gexinghua
        }
			],
			recommondSence: [
				{
					name: '智能车载',
					content: '在各种车载噪音、干扰情况下，增强目标语音，抑制干扰，从而提升唤醒率和识别率，使得司机无论在安静场景还是噪声场景均能达到良好的体验，让行车更加安全便捷',
					icon: 'iconzhinengqiche',
					bg: bg_chezai
				},
				{
					name: '智能家居',
					content: '提升复杂智能家居场景中目标声音的质量，使得识别率和唤醒率大幅提升，达到更好的智能家居控制体验',
					icon: 'iconzhinengjiaju',
					bg: bg_jiaju
				},
				{
					name: '智能机器人',
					content: '提升智能机器人接收语音的质量，实现即使在噪音环境的使用场景中，也可准确实现对机器人的控制与操作',
					icon: 'iconzhinengjiqiren',
					bg: bg_jiqiren
				},
			],
			more: [
				{
					name: '语言理解',
					path: '/understand'
				},
				{
					name: '语音唤醒',
					path: '/voicewake'
				}
			]
    }
  }

	componentWillMount() {
		scrollTop()
	}

	render() {

		return (
			<div>
				<Header headerImg={zengqiang} title={'语音增强'} desc={voiceDesc} />

				{/* 推荐场景 */}
				<div className='recommend_wrap'>
					<div className='recommend'>
						<h3>功能介绍</h3>
						<div className='rec_list'>
							<div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${bosu_bg})`}}></div>
								<div>
									<h4> 波束形成（BF）</h4>
									<p>主要用于保留目标角度声音，去除非目标角度声音，可使用在车载、家居等具有人声干扰、设备干扰等的使用场景</p>
								</div>
							</div>
							<div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${shengyuandinwei})`}}></div>
								<div>
									<h4>声源定位（DOA）</h4>
									<p>主要用于获取目标声音的空间方位信息，可使用在车载、家居等具有人声干扰、设备干扰等的使用场景</p>
								</div>
							</div>
							<div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${huishengxiaochu})`}}></div>
								<div>
									<h4>声学回声消除（AEC）</h4>
									<p>主要用于保留目标声音，去除回声，可使用在车载、家居等自身具有扬声器设备的使用场景</p>
								</div>
							</div>
              <div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${mangyuanfenli})`}}></div>
								<div>
									<h4>盲源分离（BSS）</h4>
									<p>主要用于将混合信号中的源信号分离出来，得到目标语音，可使用在车载、家居等具有人声干扰、设备干扰、环境噪音等的使用场景</p>
								</div>
							</div>
              <div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${zaoshenyizhi})`}}></div>
								<div>
									<h4>噪声抑制（NS）</h4>
									<p>主要用于保留目标声音，去除环境噪音，可使用在车载、家居等具有环境噪音的使用场景</p>
								</div>
							</div>
              <div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${zidongzengyi})`}}></div>
								<div>
									<h4>自动增益控制（AGC）</h4>
									<p>主要用于将目标声音幅度等级自动调整至期望等级，可使用在车载、家居等具有远场拾音的使用场景</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ProductAdv advList={this.state.advList} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<MoreRecommend more={this.state.more} />

				<style jsx>{`
					.recommend_wrap {
						width: 100%;
						background: #F5F5F5;
					}
					.recommend {
						width: 1200px;
            height: 100%;
						margin: 0 auto;
						padding: 100px 0;
						text-align: center;
					}
					.recommend h3 {
						font-size: 38px;
						color: #333;
					}
					.rec_list {
						display: flex;
            flex-wrap: wrap;
						justify-content: space-between;
						margin-top: 65px;
						width: 100%;
						padding-left: 0;
					}
					.rec_list .rec_item {
						width: 380px;
						height:580px;
            margin-bottom: 20px;
					}

					.rec_list .rec_item>.img {
						width: 380px;
						height: 340px;
					}

					.rec_list .rec_item>div{
						width: 380px;
						height: 240px;
						display: block;
						background: #fff;
						padding: 42px 0 0 28px;
					}
					.rec_list .rec_item>div>h4{
						display: block;
						width: 325px;
						height: 45px;
						color: #333;
						font-size: 20px;
						line-height: 30px;
						text-align: left;
					}
					.rec_list .rec_item>div>p {
						width: 325px;
						height: 120px;
						font-size: 14px;
						color: #747578;
						line-height: 22px;
						text-align: left;
					}


					.quickEntry_wrap {
						width: 100%;
						height: 300px;
						background: #f5f5f5;
					}
					.quickEntry {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
					}
					.quickEntry .entry {
						width: 100%;
						height: 100px;
						background: #fff;
						padding: 36px 0 0 40px;
					}
					.quickEntry .entry>span {
						float: left;
						display: block;
						width: 99px;
						height: 28px;
						font-size: 20px;
						color: #747578;
						font-weight: bold;
						text-align: center;
					}
					.quickEntry .entry>p {
						float: left;
						color: #747578;
						font-size: 20px;
					}
					.recommendScene_wrap {
						width: 100%;
						height: 780px;
						background: #202530;
					}
					.recommendScene {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 94px 0 100px 0;
					}
					.recommendScene>h3 {
						width: 800px;
						height: 53px;
						text-align: center;
						color: #fff;
						font-size: 38px;
						margin: 0 auto;
						margin-bottom: 53px;
					}
					.recommendScene .sceneList {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 1200px;
					}

					.recommendScene .sceneList .sceneItem {
						width: 380px;
						height: 480px;
						padding: 60px 0 0 40px;
						background: #474747;
						margin-bottom: 30px;
					}
					.recommendScene .sceneList .sceneItem>span{
						display: block;
						width: 221px;
						height: 30px;
						color: #fff;
						font-size: 22px;
					}
					.recommendScene .sceneList .sceneItem >.mark {
						width: 40px;
						height: 1px;
						color: #F37E43;
						border: 3px solid #F37E43;
						margin: 27px 0 20px 0;
					}
					.recommendScene .sceneList .sceneItem>p {
						width: 300px;
						height: 100px;
						font-size: 14px;
						line-height: 20px;
						color:#ededed;
					}

        `}</style>
			</div>
		)
	}
}
