import React, {Component} from 'react'
import {withRouter, Link} from 'react-router-dom'

class Header extends Component {


  showSelect = () => {
    const plate = this.props.plate
    if (plate === 1) {
      return (
        <div className='about'>
          <a onClick>合作咨询</a>
        </div>
      )
    } else {
      return (
        <div className='about'>
          <a>立即使用</a>
          <a>技术文档</a>
        </div>
      )
    }
  }

  render() {

    const {headerImg, title, desc, plate, partnerPlan} = this.props

    return (
      <div>
        <div className='headerImg'>
          <div className='info'>
            <h2>{title}</h2>
            <p>{desc}</p>
            {
              partnerPlan ? (
                <div className="plan-Wrap">
                  <a onClick={() => this.props.history.push('/findpartners')}
                     className='find-btn find-partner'>查找合作伙伴</a>
                  <a href='mailto:openai@didilgobal.com' className="find-btn join-partner">加入合作计划</a>
                </div>
              ) : (
                <div className='about' style={{justifyContent: 'center'}}>
                  <a onClick={() => this.props.history.push('/support')}>合作咨询</a>
                </div>
              )
            }
          </div>
          <div className='circle'>
          </div>
        </div>

        <style jsx>{`
          .headerImg {
            position: relative;
            width: 100%;
            height: 31vw;
            background-image: url(${headerImg});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          .circle {
            position: absolute;
            width: 26vw;
            height: 26vw;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(0,0,0,0.2);
            border-radius: 50%;
          }

          .headerImg .info {
            position: relative;
            z-index: 2;
            width: 41%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          h2 {
            color: #fff;
            font-size: 42px;
            height: 59px;
          }

          p{
            width: 650px;
            color: #fff;
            font-size: 16px;
            line-height: 28px;
            margin: 10px 0 50px 0;
            text-align: center;
          }
          .about {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 15vw;
          }

          .about a {
            display: block;
            color: #fff;
            width: 128px;
            height: 44px;
            border: 1px solid #fff;
            text-align: center;
            line-height: 42px;
            border-radius: 2px;
            font-size: 16px;

          }

          .about a:hover {
            background: rgba(255,255,255,0.16);
          }

          .plan-Wrap{
             display: flex;
             justify-content: center;
          }

          .find-btn{
            display: inline-block;
            color: #fff;
            width: 7.7vw;
            height: 2.5vw;
            border: 1px solid #fff;
            text-align: center;
            line-height: 2.5vw;
            border-radius: 2px;
            font-size: 1vw;
            }
            .find-partner{
                margin-right: 50px;
            }
            .find-partner:hover{
            background: rgba(255,255,255,0.16);
            }
            .join-partner{
                  color: #333;
                  background-color: #fff;
              }
            .join-partner:hover{
               color: #fff;
                background-color: #19387B;
                border: 1px solid #19387B;
              }
        `}</style>
      </div>
    )
  }
}

export default withRouter(Header)
