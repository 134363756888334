import React, { Component } from 'react';
import { Row, Col } from 'antd';
import $ from 'jquery';

require('./thumbnails.css')

class Thumbnail extends Component {
    constructor(){
        super();
        this.thumbNailClass = "";
    }

    onClick =(item,e) => {
        $('.' + this.thumbNailClass).each(function(e){
        //$(".thumbnail").each(function(e){
            $(this).css("border-color","#E2E2E2");
        });

        $(e.target).css("border-color","#F77B4C");

        // console.log(item,item.original);
        this.props.onThumbnailClick(item.original);
    }

    createCol = (items) => {
        var len = items.length;
        var colSpan = Math.floor( 24 / len);

        let imgStyle  = {
            width:'150px',
            height:'180px'
        };

        const  cols = items.map((item) =>
            <Col span={colSpan}>
                <img style={{...imgStyle}} src={item.thumbnail} />
            </Col>
        );

        return cols;
    }

    render() {
        var len = this.props.images.length;
        var colSpan = Math.floor( 24 / len);
        let imgStyle = {
            width:'98%',
            height:'120px',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            backgroundSize:'contain',
            margin:'2px',
            padding:'px',
            borderWidth:'1px',
            borderStyle:'solid',
            borderColor:'#E2E2E2',
            cursor:'pointer'
        };

        if(this.props.thumbNailClass != undefined){
            this.thumbNailClass = this.props.thumbNailClass;
        } else {
            this.thumbNailClass = "thumbnail";
        }

        const  cols = this.props.images.map((item) =>
            <Col key={item.thumbnail} span={colSpan} style={{margin:'2px'}}>
                <div style={{...imgStyle,backgroundImage:'url(' + item.thumbnail + ')',borderColor:item.borderColor}} onClick={this.onClick.bind(this,item)} className={this.thumbNailClass} >
                </div>
            </Col>
        );

        return(
            <Row style={{display:'flex',justifyContent:'space-around'}}>
                {cols}
            </Row>
        )
    }
}

export default Thumbnail;
