
const solutionCases = [
  {
    id: 1,
    name: '专项解决方案',
    categorys: [
      {
        cateItem: '智能交互',
        path: ''
      },
      {
        cateItem: '智能客服',
        path: ''
      },
      {
        cateItem: '智能排班',
        path: ''
      },
      {
        cateItem: '智能调度',
        path: ''
      },
      {
        cateItem: '智能核验',
        path: ''
      },
      {
        cateItem: '智能驾驶',
        path: ''
      },
      {
        cateItem: '智能质检',
        path: ''
      },
      {
        cateItem: '智能安全',
        path: ''
      },
      /* {
        cateItem: '文字录入',
        path: ''
      },
      {
        cateItem: '内容审核',
        path: ''
      },
      {
        cateItem: 'DMS驾驶监控',
        path: ''
      },
      {
        cateItem: 'ADAS驾驶辅助',
        path: ''
      },
      {
        cateItem: '图像质检',
        path: ''
      },
      {
        cateItem: '三维重建模拟',
        path: ''
      },
      {
        cateItem: '多模驾驶安全',
        path: ''
      },
      {
        cateItem: 'AI视频监控',
        path: ''
      },
      {
        cateItem: '多模驾驶安全',
        path: ''
      },
      {
        cateItem: '客服机器人',
        path: ''
      },
      {
        cateItem: '在线客服辅助',
        path: ''
      },
      {
        cateItem: '用户反馈平台',
        path: ''
      },
      {
        cateItem: '对话机器人',
        path: ''
      },
      {
        cateItem: '用户画像挖掘',
        path: ''
      },
      {
        cateItem: '智能翻译',
        path: ''
      },
      {
        cateItem: '智能预测',
        path: ''
      },
      {
        cateItem: '智能排班',
        path: ''
      },
      {
        cateItem: '智能推荐',
        path: ''
      },
      {
        cateItem: '数据分析',
        path: ''
      },
      {
        cateItem: '路线规划',
        path: '/solution/lineplanning'
      },
      {
        cateItem: '供需预测',
        path: ''
      }, */
    ]
  },
  {
    id: 2,
    name: '行业解决方案',
    categorys: [
      /* {
        cateItem: '智能企业服务',
        path: '/solution/service'
      },
      {
        cateItem: '智能安全安防',
        path: '/solution/safe'
      },
      {
        cateItem: '智能汽车车载',
        path: '/solution/car'
      } */
      {
        cateItem: '智慧出行',
        path: ''
      }
    ]
  },
  /* {
    id: 3,
    name: '赋能行业',
    categorys: [
      {
        cateItem: '智慧出行',
        path: ''
      },
      {
        cateItem: '智能汽车',
        path: ''
      },
      {
        cateItem: '智慧机场',
        path: ''
      },
      {
        cateItem: '智慧城市',
        path: ''
      },
      {
        cateItem: '智慧园区',
        path: ''
      },
      {
        cateItem: '智慧物流',
        path: ''
      },
      {
        cateItem: '智慧金融',
        path: ''
      },
      {
        cateItem: '智能IOT',
        path: ''
      },
    ]
  } */
]

export default solutionCases
