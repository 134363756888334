import React, { Component } from 'react'

export default class Features extends Component {

  state = {
    minWidth: false
  }

  componentWillMount() {
    const { funcList } = this.props
    if (funcList.length === 2 || funcList.length === 4) {
      this.setState({minWidth: false})
    } else {
      this.setState({minWidth: true})
    }
  }

  render() {
    const { funcList } = this.props
    const { minWidth } = this.state

    return (
      <div>
        <div className='recommend_wrap'>
          <div className='recommend'>
            <h3>功能介绍</h3>
            {
              funcList.length === 1 ? (
                <div>
                  <p className="func-introduced">
                    {
                      funcList[0].desc
                    }
                  </p>
                  <div className="func-img" style={{backgroundImage: `url(${funcList[0].image})`}}></div>
                </div>
                ) : (
                <div className='rec_list'>
                  {
                    funcList.map((item, index) => (
                      <div className='rec_item' key={index} >
                        <div className="img" style={{backgroundImage: `url(${item.image})`}}></div>
                        <div>
                          <h4>{item.name}</h4>
                          <p>{item.desc}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
        </div>

        <style jsx>{`
          .func-introduced{
            margin: 0 auto;
            font-size: 14px;
            color: #747578;
            line-height: 22px;
            width: 842px;
            margin: 53px auto 36px;
          }
          .func-img{
            margin: 0 auto;
            width: 1200px;
            height: 680px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          .recommend_wrap {
						width: 100%;
						background: #F5F5F5;
					}
					.recommend {
						width: 1200px;
						margin: 0 auto;
						padding: 94px 0 70px 0;
						text-align: center;
					}
					.recommend h3 {
						font-size: 32px;
						color: #333;
            margin-bottom: 0;
					}
					.rec_list {
						display: flex;
						justify-content: flex-start;
						flex-wrap: wrap;
						margin-top: 54px;
						width: 1200px;
						padding-left: 0;
					}
					.rec_list .rec_item {
            margin-right: 30px;
						width: ${minWidth ? 380 : 585}px;
						height:580px;
						margin-bottom: 30px;
					}

          .rec_list .rec_item:nth-child(${minWidth ? '3n' : '2n'}) {
						margin-right: 0;
					}

					.rec_list .rec_item>.img {
						width:100%;
						height: 340px;
					}

					.rec_list .rec_item>div{
						width: 100%;
						height: 240px;
						background: #fff;
					  padding: 42px 0 0 ${minWidth ? 28 : 43}px;
					}
					.rec_list .rec_item>div>h4{
						display: block;
						width: 325px;
						height: 45px;
						color: #333;
						font-size: 20px;
						line-height: 30px;
						text-align: left;
					}
					.rec_list .rec_item>div>p {
            width: ${minWidth ? 325 : 500}px;
						height: 120px;
						font-size: 14px;
						color: #747578;
						line-height: 22px;
						text-align: left;
					}
        `}</style>
      </div>
    )
  }
}
