import React, { Component } from 'react'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'

import scrollTop from '../../utils/scrollTop'

import jishulinxian from '../../img/ICON/技术领先.png'
import zhinengdidu from './img/智能地图/智能地图.jpg'
import waimai from './img/智能地图/推荐场景x4/外卖.jpg'
import pingche from './img/智能地图/推荐场景x4/拼车合乘.jpg'
import wuliu from './img/智能地图/推荐场景x4/物流.jpg'
import wangyueche from './img/智能地图/推荐场景x4/网约车.jpg'
import i_wangyueche from '../../data/推荐场景icon/安全检测.png'
import i_pingche from '../../data/推荐场景icon/安防检测.png'
import i_waimai from '../../data/推荐场景icon/智慧交通.png'
import i_wuliu from '../../data/推荐场景icon/智慧商业.png'
import zhinengshuju from '../../data/ICON一期/30.智能数据.png'
import jizhixingneng from '../../data/ICON一期/18.快速响应.png'


const voiceDesc = '基于滴滴独有的海量出行数据，采用业界领先的机器学习解决方案，提供路线规划、到达时间预估等核心地图服务。在公司支持多项重要业务，准确性高、性能稳定'

export default class Map extends Component {

	constructor(props) {
		super(props)
		this.state = {
			advlist: [
				{
					title: 'AI优先',
					desc: '全部服务基于人工智能解决方案，采用国际前沿技术，相比于传统地图算法，精准度有革命性的提升',
					icon: jishulinxian
				},
				{
					title: '高质量数据',
					desc: '滴滴庞大的出行场景每日积累超过70TB原始轨迹，业务模式保证数据的高可靠性。高质量数据进一步激发人工智能方案的潜力',
					icon: zhinengshuju
				},
				{
					title: '极致性能',
					desc: '创新的高性能架构，微秒级服务响应时间，经受公司业务验证和流量考验，支持大调用量、高并发的应用场景',
					icon: jizhixingneng
				},
			],
			recommondSence: [
				{
					name: '网约车',
					content: '利用定位、路线规划和行程时间预估，降低司机成本，减少乘客等待时间，提升派单效率',
					icon: 'iconwangyueche',
					bg: wangyueche
				},
				{
					name: '拼车合乘',
					content: '利用路线规划和批量算路，寻找最佳合乘方案，提高拼成率，降低绕路距离，降低衔接等待时间',
					icon: 'iconpinchehecheng',
					bg: pingche
				},
				{
					name: '外卖',
					content: '利用高性能批量算路，规划外卖员到店铺、用户的接送顺序和路径，系统性降低成本',
					icon: 'iconwaimai',
					bg: waimai
				},
				{
					name: '物流',
					content: '利用路线规划，寻找高速费和运输时间的平衡，避开安全隐患路段，获得最佳运输效率',
					icon: 'iconwuliu',
					bg: wuliu
				},
			]
		};
	}

	componentWillMount() {
		scrollTop()
	}

	render() {
		return (
			<div>
				<Header headerImg={zhinengdidu} title={'智能地图'} desc={voiceDesc} />

				{/* 推荐场景 */}
				<div className='recommend_wrap'>
					<div className='recommend'>
						<h3>功能介绍</h3>
						<div className='rec_list'>
							<div className='rec_item'>
								<img />
								<div>
									<h4>定位</h4>
									<p>将地理坐标（如GPS）转换为实际道路，基于深度学习技术，个性化推荐最合理的车辆停靠点，提升接送效率</p>
								</div>
							</div>
							<div className='rec_item'>
								<img />
								<div>
									<h4>路线规划</h4>
									<p>为用户规划最合理的行程路线，脱胎于滴滴现有业务，大幅提升交通运输效率和安全水平。支持超高性能的多对多批量算路</p>
								</div>
							</div>
							<div className='rec_item'>
								<img />
								<div>
									<h4>行程时间预估</h4>
									<p>依托滴滴大数据和领先的机器学习技术，为行程提供精准的时间预估，具有平均误差低、极端异常值少的特点</p>
								</div>
							</div>
							<div className='rec_item'>
								<img />
								<div>
									<h4>路况</h4>
									<p>提供精准、实时、高覆盖率的路况查询服务。及时掌握拥堵信息，制定高性价比的出行计划</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ProductAdv advList={this.state.advlist} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<div className='quickEntry_wrap'>
					<div className='quickEntry'>
						<div className='entry'>
							<span>更多推荐:</span>
							<p>时序预测  |  智能匹配</p>
						</div>
					</div>
				</div>

				<style jsx>{`
					.recommend_wrap {
						width: 100%;
						height: 880px;
						background: #F5F5F5;
					}
					.recommend {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
						text-align: center;
					}
					.recommend h3 {
						font-size: 38px;
						color: #333;
					}
					.rec_list {
						display: flex;
						justify-content: space-between;
						margin-top: 65px;
						width: 100%;
						height: 383px;
						padding-left: 0;
					}
					.rec_list .rec_item {
						width: 296px;
						height:580px;
					}

					.rec_list .rec_item>img {
						width: 100%;
						height: 340px;
						background: #93F086;
					}

					.rec_list .rec_item>div{
						width: 100%;
						height: 240px;
						display: block;
						background: #fff;
						padding: 30px 15px 0;
					}
					.rec_list .rec_item>div>h4{
						display: block;
						width: 100%;
						height: 45px;
						color: #333;
						font-size: 20px;
						line-height: 30px;
						text-align: left;
					}
					.rec_list .rec_item>div>p {
						width: 100%;
						height: 120px;
						font-size: 14px;
						color: #747578;
						line-height: 22px;
						text-align: left;
					}


					.quickEntry_wrap {
						width: 100%;
						height: 300px;
						background: #f5f5f5;
					}
					.quickEntry {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
					}
					.quickEntry .entry {
						width: 100%;
						height: 100px;
						background: #fff;
						padding: 36px 0 0 40px;
					}
					.quickEntry .entry>span {
						float: left;
						display: block;
						width: 99px;
						height: 28px;
						font-size: 20px;
						color: #747578;
						font-weight: bold;
						text-align: center;
					}
					.quickEntry .entry>p {
						float: left;
						color: #747578;
						font-size: 20px;
					}
					.recommendScene_wrap {
						width: 100%;
						height: 780px;
						background: #202530;
					}
					.recommendScene {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 94px 0 100px 0;
					}
					.recommendScene>h3 {
						width: 800px;
						height: 53px;
						text-align: center;
						color: #fff;
						font-size: 38px;
						margin: 0 auto;
						margin-bottom: 53px;
					}
					.recommendScene .sceneList {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 1200px;
					}

					.recommendScene .sceneList .sceneItem {
						width: 300px;
						height: 480px;
						padding: 60px 0 0 40px;
						background: #474747;
						margin-bottom: 30px;
					}
					.recommendScene .sceneList .sceneItem>span{
						display: block;
						width: 221px;
						height: 30px;
						color: #fff;
						font-size: 22px;
					}
					.recommendScene .sceneList .sceneItem >.mark {
						width: 40px;
						height: 1px;
						color: #F37E43;
						border: 3px solid #F37E43;
						margin: 27px 0 20px 0;
					}
					.recommendScene .sceneList .sceneItem>p {
						width: 220px;
						height: 100px;
						font-size: 14px;
						line-height: 20px;
						color:#ededed;
					}

					.productAdv_wrap {
						width: 100%;
						height: 547px;
						background: #fff;
					}
					.productAdv {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 95px 0 100px 0
					}
					.productAdv .advList {
						margin-top: 52px;
						display: flex;
						justify-content: space-between;
					}
					.productAdv h3 {
						font-size: 38px;
						color: #333;
						text-align: center;
					}

					.advList .advItem {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.productAdv .advList .advItem .adv_logo {
						position: relative;
						width: 100px;
						height: 100px;
					}

					.advList .advItem .adv_logo>img {
						width: 100px;
						height: 100px;
					}
					.advList .advItem>span {
						display: block;
						width: 149px;
						height: 30px;
						font-size: 20px;
						color: #333;
						letter-spacing: 0.34px;
						text-align: center;
						line-height: 30px;
						margin: 25px 0;
					}
					.advList .advItem>p{
						width: 270px;
						height: 42px;
						color: #747578;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
					}
        		`}</style>
			</div>
		)
	}
}
