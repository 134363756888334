import React, { Component, PureComponent } from 'react'
import scrollTop from '../../utils/scrollTop'

import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import CoopCase from '../../component/CoopCase'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import huotijiance from '../../data/func_gif/人脸活体检测.gif'

import IDcard from '../img-service/IDcard.jsx'
import Travel from '../img-service/travel.jsx'
import Bank from '../img-service/bank.jsx'
import Driver from '../img-service/driver.jsx'
import Plate from '../img-service/plate.jsx'
import FaceDetection from '../img-service/Face-Detection.jsx'
import FaceVerify from '../img-service/face-verify.jsx'
import FaceAttribute from '../img-service/face-attribute.jsx'

import { renlianshibie } from '../../data/productService'
import renlian from '../../img/renlianshibie.jpg'


export default class Detection extends PureComponent {

  state = {
    funcImg: '',
    funcGif: '',
    title: '',
    desc: '',
    headerImg: '',
    funcIntro: '',    // 功能介绍
    productAdv: [],
    recommondSence: [],
    serviceType: [],
    more: [],
    path: []
  }

  /* selectWebDemo = () => {
    const id = this.props.match.params.id
    switch (id) {
      case 'IDcard':
        return <IDcard />
        break
      case 'travel':
        return <Travel />
        break
      case 'bank':
        return <Bank />
        break
      case 'driver':
        return <Driver />
        break
      case 'plate':
        return <Plate />
        break
      case 'detection':
        return <FaceDetection />
        break
      case 'verify':
        return <FaceVerify />
        break
      case 'attribute':
        return <FaceAttribute />
        break
      default:
        return null
    }
  } */
  /**
   * 根据 params.id ，加载不懂页面，获得不同的数据
   */
  getContent(newPath) {
    let path = this.props.match.params.id
    if (newPath && newPath !== path) {
      path = newPath
    }
    const item = renlianshibie.find(item => item.path === path)
    const { title, desc, productAdv, recommondSence, serviceType, funcIntro, more, funcGif, funcImg} = item
    this.funcGif = item.funcGif || ''
    const headerImg = item.headerImg || renlian
    this.setState({
      title,
      desc,
      funcIntro,
      productAdv,
      recommondSence,
      serviceType,
      more,
      headerImg,
      funcImg,
    }, () => {
      console.log(this.funcGif, path, newPath)
      const funcGif = this.funcGif || ''
      this.scrollToGif(funcGif)
    })
  }

  scrollToGif = (funcGif) => {
    this.flag = false

    window.addEventListener('scroll', (e) => {
      if (!this.flag) {
        this.timer = setTimeout(() => {
          var top = document.documentElement.scrollTop || document.body.scrollTop
          if (top > 260) {
            this.setState({funcImg: this.funcGif})
          }
          this.flag = false
        }, 1000/60)
      }
      this.flag = true
    })
  }

  componentWillMount() {
    this.getContent()
    scrollTop()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  componentWillReceiveProps(nextProps) {
    const newPath = nextProps.match.params.id

    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.getContent(newPath)
      scrollTop()
    }
  }

  render() {
    const { title, desc, serviceType, productAdv, recommondSence, funcIntro, more, headerImg, funcGif, funcImg } = this.state

    return (
      <div>
        <Header title={title} headerImg={headerImg || renlian} desc={desc} />

        <div className='func-show-wrap'>

          <div className='func-intro'>
            <h3>功能介绍</h3>
            {
              funcIntro ?
                <p>{funcIntro}</p> :
                null
            }
            <div className='intro-list'>

              {
                title === '车流统计'
                ? <video controls width="1200" height="620" autoplay="true" style={{outline: 'none'}}>
                    <source src="http://img-ys011.didistatic.com/static/aiopen/do1_MrshzxcBdAXQ3SVYvqZr"
                      type="video/mp4"
                    />
                  </video>
                : <img style={{width: '100%', height: '100%'}} alt='' src={funcImg} onContextMenu={$event => $event.preventDefault()}/>
              }
            </div>
          </div>

        </div>

        <ProductAdv advList={productAdv} />

        <RecommondSence recommondSence={recommondSence} />

        <div className='service-type-wrap'>
          <div className='service-type'>
            <h4>服务类型</h4>
            <div className='service-list'>
              {
                serviceType.map((item, index) => (
                  <div className='service-item' key={index}>
                    <span>{item.name}</span>
                    <p>{item.content}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <MoreRecommend more={more} />

        <style jsx>{`
          .func-show-wrap {
            width: 100%;
            background: #f5f5f5;
          }

          .func-intro {
            margin: 0 auto;
            width: 1200px;
            padding: 94px 0 100px 0;
          }

          .func-intro>h3{
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 53px;
          }

          .func-intro>p {
            width: 700px;
            color: #747578;
            font-size: 14px;
            line-height: 20px;
            margin: 0 auto;
            text-align: center;
          }

          .intro-list {
            width: 100%;
            height: 620px;
            margin-top: 36px;
            display: flex;
            justify-content: space-between;
          }

          .intro-item {
            width: 585px;
            height: 340px;
            background: #93F086;
          }

          .func-show {
            margin: 0 auto;
            width: 1200px;
            height: 100%;
            padding: 85px 0 109px 0;
          }

          .func-show>h2 {
            font-size: 38px;
            color: #333;
            text-align: center;
            margin-bottom: 54px;
          }

          .recognition-container {
            width: 1200px;
            height: 803px;
            background: #93F086;
          }

          .service-type-wrap {
            width: 100%;
            background: #f5f5f5;
          }

          .service-type {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 94px 0 100px 0;
          }

          .service-type>h4 {
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 53px;
          }

          .service-list {
            display: flex;
            justify-content: space-between;
          }

          .service-item {
            text-align: center;
            width: 380px;
            height: 300px;
            background: #fff;
            padding-top: 70px;
          }

          .service-item>span {
            font-family: PingFangSC-Medium;
            font-size: 22px;
            color: #333;
          }

          .service-item>p {
            margin: 0 auto;
            margin-top: 33px;
            width: 288px;
            font-size: 16px;
            color: #747578;
          }

        `}</style>
      </div>
    )
  }
}

