import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import Footer from '../../component/footer/footer'
import './face.css'

import cheliangfenxi from '../../data/车辆分析/车辆检测/车辆检测.jpg'
import cheliu from '../../data/车辆分析/cheliu.jpg'
import chexing from '../../data/车辆分析/chexing.jpg'
import cheliangjiance from '../../data/车辆分析/cheliangjiance.jpg'
import bukong from '../../data/车辆分析/社交监管.jpg'
import jiaotongdiaodu from '../../data/车辆分析/交通调度.jpg'
import jiashifuzhu from '../../data/车辆分析/驾驶辅助.jpg'
import zhunquekekao from '../../img/ICON/保密可靠.png'
import keyongqingao from '../../img/ICON/适应性强.png'
import duozhongzhichi from '../../img/ICON/通用性强.png'

const ProductFunc = ({features}) => (
	<div>
		<div className='proFunc_wrap'>
			<div className='proFunc'>
				<h2>产品功能</h2>
				<div className='proList'>
					{
						features.map((item, index) => (
							<div className='proItem' key={index}>
								<div className='back-img-wrap' style={{ width: 380, height: 260 }}>
									<img alt='' className='back-img' src={item.img} style={{ width: 380, height: 260 }} />
								</div>
								<span style={{ fontWeight: 100 }}>{item.name}</span>
								<p>{item.desc}</p>
								<div className='select'>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to='/support'>合作咨询</Link>
									<div style={{width: 1, height: 39, background: '#e8e8e8', position: 'absolute'}}></div>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to={item.path}>了解详情</Link>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>

		<style jsx>{`
			.proFunc_wrap {
					width: 100%;
					background: #f5f5f5;
			}
			.proFunc {
					width: 1200px;
					height: 100%;
					margin: 0 auto;
					padding: 100px 0 100px 0;
			}
			.proFunc h2 {
					width: 152px;
					height: 45px;
					text-align: center;
					margin: 0 auto;
					font-size: 38px;
					color: #333;
					margin-bottom: 60px;
			}
			.proFunc .proList {
					width: 1200px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
			}
			.proFunc .proList .proItem {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 380px;
					background: #fff;
					margin-bottom: 30px;
			}

			.back-img-wrap {
				overflow: hidden;
			}

			.back-img {
				transition: 0.5s linear;
			}

			.back-img:hover {
				transform: scale(1.1);
			}

			.proFunc .proList .proItem>span {
					display: block;
					width: 140px;
					height: 30px;
					font-weight: bold;
					font-size: 20px;
					color: #333;
					text-align: center;
					margin: 20px 0;
			}
			.proFunc .proList .proItem>p {
					width: 310px;
					height: 42px;
					font-size: 14px;
					color: #747578;
					line-height: 22px;
					margin-bottom: 30px;
			}
			.proFunc .proList .proItem .select {
					width: 380px;
					height: 65px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					border-top: 1px solid #E8E8E8;
					position: relative;
			}

		`}</style>
	</div>
)

export default class Car extends Component {

	state = {
		features: [
			{
				name: '车型识别',
				desc: '依据车辆外观特征，识别出车系、品牌、车型、颜色等信息',
				path: '/car/model-reco',
				img: chexing
			},
			{
				name: '车辆检测',
				desc: '识别图像中所有车辆的位置并区分车辆类别，可返回检测坐标结果、车辆类型标签和车辆目标个数',
				path: '/car/car-detect',
				img: cheliangjiance
			},
			{
				name: '车流统计',
				desc: '对拍摄区域内的各类车辆目标进行检测和追踪，计算各类车辆的驶入、驶出数量，实现动态车流量统计',
				path: '/car/count',
				img: cheliu
			},
		],

		productAdv: [
			{
				title: '准确可靠',
				desc: '已落地到公司业务多个环节，经实际业务线上数据验证，识别准确度可达98%',
				icon: zhunquekekao
			},
			{
				title: '可用性高',
				desc: '有效排除不同拍摄条件下形成的多种干扰，场景适应能力强，响应速度快',
				icon: keyongqingao
			},
			{
				title: '个性化定制',
				desc: '可配合用户的个性化场景需求，从不同维度对车辆进行分析',
				icon: duozhongzhichi
			}
		],
		recommondSence: [
			{
				name: '交通调度',
				content: '借助道路、卡口、车库等位置的摄像终端设备采集视频，自动识别车型信息，实时分析车流量，辅助交通调度',
				bg: jiaotongdiaodu,
				icon: 'iconzhinengqiche'
			},
			{
				name: '布控侦查',
				content: '识别并结构化存储车辆属性信息，便于凭图片或属性标签筛查、搜索违法车辆，大幅提高侦查效率',
				bg: bukong,
				icon: 'iconchanpinzhijian'
			},
			{
				name: '驾驶辅助',
				content: '能够快速识别路面车辆信息，判断其他车辆与当前驾驶车辆的相对位置，辅助实现智能驾驶、安全驾驶',
				bg: jiashifuzhu,
				icon: 'iconfuzhujiashi'
			},
		],
		more: [
			{
				name: '人脸识别',
				path: '/face'
			},
			{
				name: '人体识别',
				path: '/'
			}
		]
	}

	render() {
		return (
			<div>
				<Header headerImg={cheliangfenxi} title={'车辆分析'} desc={'基于海量出行数据和行业领先的AI技术，提供包括车型识别、车辆属性识别、车流统计等技术能力，且支持定制化车辆分析需求'} />

				<ProductFunc features={this.state.features} />

				<ProductAdv advList={this.state.productAdv} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<MoreRecommend more={this.state.more} />
			</div>
		)
	}
}
