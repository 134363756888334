import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class MoreRecommend extends Component {

  render() {
    const { more } = this.props
    return (
      <div>
        <div className='quickEntry_wrap'>
          <div className='quickEntry'>
            <div className='entry'>
              <span>更多推荐:&nbsp;</span>
              {
                more.map((item, index) => (
                  <em key={index} onClick={() => this.props.history.push(item.path)}>{item.name}</em>
                ))
              }
            </div>
          </div>
        </div>

        <style jsx>{`
          .quickEntry_wrap {
            width: 100%;
            height: 300px;
            background: #f5f5f5
          }
          .quickEntry {
              width: 1200px;
              height: 100%;
              margin: 0 auto;
              padding: 100px 0;
          }
          .quickEntry .entry {
              display: flex;
              align-items: center; 
              width: 100%;
              height: 100px;
              background: #fff;
              padding-left: 40px;
          }
          .quickEntry .entry>span {
              float: left;
              display: block;
              width: 99px;
              height: 28px;
              font-size: 20px;
              color: #747578;
              text-align: center;
          }
          .quickEntry .entry>em {
              float: left;
              padding: 0 10px;
              color: #747578;
              font-size: 20px;
              line-height: 16px;
              border-right: 1px solid #747578;
              cursor: pointer;
          }
          .quickEntry .entry>em:hover {
            color: #19387B
          }
          .quickEntry .entry>em:last-child {
            border-right: none;
          }
        `}</style>
      </div>
    )
  }
}

export default withRouter(MoreRecommend)
