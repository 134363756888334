/*
* kjqy: 科技前沿，yjgc： 业界观察， ptdt: 平台动态， xsjl：学术交流
* 时间是从最新的开始排列
* */

import nenglikaifang from '../imgs/nenglikaifang.jpeg'
import tuijinzu from '../imgs/tuijinzu.jpg'
import kdd from '../imgs/kdd.jpeg'
import xiaoyuanhezuo from '../imgs/xiaoyuanhezuo.jpeg'
import kaifangpingtai from '../imgs/kaifangpingtai.jpg'
import weilai from '../imgs/banner/banner1.jpg'
import funeng from '../imgs/banner/banner2.jpeg'
import chashou from '../imgs/banner/banner3.jpg'
import jiaotong from '../imgs/banner/banner4.jpeg'
import diyi from '../imgs/banner/banner5.jpg'
import dazhong from '../imgs/banner/banner6.png'
import dafangyicai from '../imgs/banner/dafangyicai.jpg'
import tiaozhanyujiyu from '../imgs/banner/tiaozhanyujiyu.jpg'
import yingyong from '../imgs/banner/yingying.jpg'
import tongjisiwei from '../imgs/banner/tongjisiwei.jpg'
import hezuohuoban from '../imgs/banner/hezuohuoban.jpg'
import jiejuefangan from '../imgs/banner/jiejuefangan.jpg'
import transportation from '../imgs/banner/transportation.jpeg'
import rencai from '../imgs/banner/rencai.jpeg'
import fangwendidi from '../imgs/banner/fangwendidi.jpeg'
import shijuejishu from '../imgs/banner/shijujishu.jpeg'
import chonggongjuban from '../images/chenggongjuban/慧享未来.jpg'

const {moxingliangxiang, jichuyanjiu, yuyanmoxing,chuxingyingyong, kejirenwen, didiyingyong, didichuxing, kaifangpingtai2, dididangxuan,rumendaojingtong, ces, meet, santironghe, chenglihuiyi, ciisjiyu} = require('./imageRequie')


export const titleList = [
  {
    "path": "https://mp.weixin.qq.com/s/cmqzNfoxplCk3KiCuOeU_A",
    "title": "滴滴DMS携手中科创达TurboX Auto 4.0，惊艳亮相2020CES",
    "time": '2020-01-14',
    imgUrl: ces,
    flag: 'ptdt'
  },
  {
    "path": "https://mp.weixin.qq.com/s/s1Y83YBFZ5c5aGb-xb-ayg",
    "title": "滴滴AI负责人叶杰平：你的每一次出行，都已有AI落地的助力 | MEET 2020",
    "time": '2019-12-30',
    imgUrl: meet,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/e9Bl53EoLBHNCQEEazq3dQ",
    "title": "滴滴出行唐剑：未来的智慧交通，将是人、车、路三体融合",
    "time": '2019-12-11',
    imgUrl: santironghe,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/tMrt7sdXhnC9Wk7_EevTNg",
    "title": "滴滴承办中国物联网大会AIoT专题论坛暨中国电子学会物联网青年专技组成立会议",
    "time": '2019-11-13',
    imgUrl: chenglihuiyi,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/5DKYgTRDMdtf_5SqcN2kIQ",
    "title": "ICDM 2019 | Speech&NLP教程，195页PPT带你从入门到精通（附下载）",
    "time": '2019-11-12',
    imgUrl: rumendaojingtong,
    flag: 'xsjl'
  },
  {
    "path": "https://mp.weixin.qq.com/s/iNkyf68OkshdVWlNQoTn4w",
    "title": "CIIS 2019 | 滴滴AI Labs负责人叶杰平解读人工智能在出行领域的机遇和挑战",
    "time": '2019-11-08',
    imgUrl: ciisjiyu,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/BK9ox2sYXNdtCit0dIB2HQ",
    "title": "瓦格纳运筹学杰出实践奖首次授予中国公司，滴滴当选",
    "time": '2019-10-25',
    imgUrl: dididangxuan,
    flag: 'kjqy'
  },
  {
    "path": "https://mp.weixin.qq.com/s/xR4_NlswWaHJqJvenbSCgA",
    "title": "滴滴发布AI技术与解决方案开放平台",
    "time": '2019-10-18',
    imgUrl: kaifangpingtai2,
    flag: 'ptdt'
  },
  {
    "path": "https://mp.weixin.qq.com/s/JQXYrJlZR_unDtxI5-gWuA",
    "title": "IEEE计算机协会主席一行访问滴滴出行",
    "time": '2019-10-17',
    imgUrl: didichuxing,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/chenggongjuban",
    "title": "“智能出行·慧享未来”高峰论坛在沪成功举办",
    "time": '2019-09-02',
    imgUrl: chonggongjuban,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/yS8cVpu_mNJsJ1bFQ22hNQ",
    "title": "CCKS 2019 | 知识图谱在滴滴的应用",
    "time": '2019-09-02',
    imgUrl: didiyingyong,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/Vkd5BdlzSS7pTqR6iQZyPw",
    "title": "未来精英论坛3.0 | 探秘桔厂科技和人文",
    "time": '2019-08-27',
    imgUrl: kejirenwen,
    flag: 'xsjl'
  },
  {
    "path": "https://mp.weixin.qq.com/s/Z_ajkg3mMcTXZG2Gf8SJbg",
    "title": "IJCAI 2019 | 4小时244页《AI in Transportation》教程带你系统梳理人工智能在出行领域的应用",
    "time": '2019-08-14',
    imgUrl: chuxingyingyong,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/dafangyicai",
    "title": "滴滴重磅发布交通出行领域多项AI技术成果，KDD 2019大放异彩",
    "time": '2019-08-09',
    imgUrl: dafangyicai,
    flag: 'kjqy'
  },
  {
    "path": "/cooperation/tiaozhanyujiyu",
    "title": "产学界人士齐聚KDD-AIoT研讨会，共话万物智联未来挑战与机遇",
    "time": '2019-08-07',
    imgUrl: tiaozhanyujiyu,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/yingyong",
    "title": "KDD 2019教程 |《深度强化学习在交通领域的应用》,带你知晓DRL在交通领域最新进展",
    "time": '2019-08-06',
    imgUrl: yingyong,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/tongjisiwei",
    "title": "JSM 2019 | 数据驱动在滴滴，详解智能出行时代的统计思维",
    "time": '2019-08-05',
    imgUrl: tongjisiwei,
    flag: 'kjqy'
  },
  {
    "path": "https://mp.weixin.qq.com/s/854tUHwS1qr-VRDPMuF5ag",
    "title": "滴滴开源DELTA :AI开发者可轻松训练自然语言模型",
    "time": '2019-08-03',
    imgUrl: yuyanmoxing,
    flag: 'kjqy'
  },
  {
    "path": "/cooperation/hezuohuoban",
    "title": "滴滴成为2019世界人工智能大会首批战略合作伙伴",
    "time": '2019-08-02',
    imgUrl: hezuohuoban,
    flag: 'yjgc'
  },
  {
    "path": "/cooperation/jiejuefangan",
    "title": "MWC2019 | 滴滴AI Labs助力合作伙伴，赋能汽车智能驾驶安全解决方案",
    "time": '2019-08-02',
    imgUrl: jiejuefangan,
    flag: 'ptdt'
  },
  {
    "path": "/cooperation/transportation",
    "title": "How can AI Revolutionize Transportation?",
    "time": '2019-07-27',
    imgUrl: transportation,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/rencai",
    "title": "滴滴参与DeeCamp2019，携手创新工场培养“接地气”的AI应用型人才",
    "time": '2019-07-21',
    imgUrl: rencai,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/fangwendidi",
    "title": "重磅 | 图灵奖得主、计算机科学算法大师和教育专家John Hopcroft访问滴滴",
    "time": '2019-07-11',
    imgUrl: fangwendidi,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/shijuejishu",
    "title": "ICME 讲习班 《CV in Transportation》,177页PPT带你了解出行领域的计算机视觉技术",
    "time": '2019-07-11',
    imgUrl: shijuejishu,
    flag: 'xsjl'
  },
  /*{
    "path": "/cooperation/zidonghuasuo",
    "title": "交流 | 滴滴副总裁、AI Labs负责人叶杰平教授一行到访自动化所dsfsdfds",
    "time": '2019-07-05',
    imgUrl: zidonghuasuo
  },*/
  {
    "path": "/cooperation/nenglikaifang",
    "title": "滴滴AI服务与能力开放",
    "time": '2019-07-03',
    imgUrl: nenglikaifang,
    flag: 'ptdt'
  },
  {
    "path": "/cooperation/tuijinzu",
    "title": "滴滴携手北航、易华录、寒武纪、大连理工、清华、中科大等成立智能出行推进组",
    "time": '2019-06-20',
    imgUrl: tuijinzu,
    flag: 'yjgc'
  },
  {
    "path": "/cooperation/kdd",
    "title": "KDD 2019 | 从上千篇投稿脱颖而出，这三篇论文你不可错过！",
    "time": '2019-06-17',
    imgUrl: kdd,
    flag: 'kjqy'
  },
  {
    "path": "https://mp.weixin.qq.com/s/O10d9Jh8l59jSx7qaE1ROQ",
    "title": "滴滴与Yoshua Bengio领导的Mila达成深度合作，推进智能驾驶基础研究",
    "time": '2019-06-14',
    imgUrl: jichuyanjiu,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/xiaoyuanhezuo",
    "title": "香港科大-滴滴出行联合实验室“Smart Mobility”研讨会顺利召开，双方将开展智慧校园合作",
    "time": '2019-05-22',
    imgUrl: xiaoyuanhezuo,
    flag: 'xsjl'
  },
  {
    "path": "/cooperation/kaifangpingtai",
    "title": "滴滴启动\"群雁计划\" 打造滴滴智能出行开放平台",
    "time": '2019-05-13',
    imgUrl: funeng,
    flag: 'ptdt'
  },
  {
    "path": "/cooperation/detail",
    "title": "全球智博会@滴滴专场论坛：赋能新出行· AI 绘未来",
    "time": '2019-05-09',
    imgUrl: weilai,
    flag: 'yjgc'
  },
 /* {
    "path": "/cooperation/cooper-detail2",
    "title": "滴滴亮相2019全球智博会：智能出行时代的开放与赋能",
    "time": '2019-05-09',
    imgUrl: funeng
  },*/
  /*{
    "path": "/cooperation/cooper-detail3",
    "title": "滴！您有一封智博会论坛邀请函请注意查收",
    "time": '2019-05-05',
    imgUrl: chashou
  },*/
  {
    "path": "/cooperation/cooper-detail4",
    "title": "滴滴出行唐剑：大数据+AI，滴滴如何从人、车、路打造智慧交通？",
    "time": '2019-05-01',
    imgUrl: jiaotong,
    flag: 'yjgc'
  },
  {
    "path": "/cooperation/cooper-detail5",
    "title": "滴滴 AI Labs 包揽 WIDER FACE 评测五项世界第一",
    "time": '2019-05-01',
    imgUrl: diyi,
    flag: 'kjqy'
  },
  {
    "path": "/cooperation/cooper-detail6",
    "title": "滴滴CTO张博：让AI像电力一样服务大众",
    "time": '2019-03-22',
    imgUrl: dazhong,
    flag: 'yjgc'
  },
  {
    "path": "https://mp.weixin.qq.com/s/zmG5eAgl5T9Peouh1miRAw",
    "title": "AAAI 2019 | 滴滴详解交通出行中的人工智能 多项算法模型亮相",
    "time": '2019-02-02',
    imgUrl: moxingliangxiang,
    flag: 'kjqy'
  }
]
