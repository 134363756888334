import React, { Component } from 'react'
 
export default class Footer extends Component {
    
    render() {
        const bg  = this.props.bg || ''
        this.bg = bg ? bg : '#333'
        return (
            <div className='footer'>
                <div className='footerWrapper'>
                    <div className='f_top'>
                        <div className='service'>
                            <h3>客服热线</h3>
                            <ul>
                                <li>司机客服热线 +86 400 0000 666</li>
                                <li>乘客客服热线 +86 4000000999</li>
                                <li>代驾客服热线 +86 400 7666 998</li>
                                <li>其他业务热线 +86 400 0000 999-9</li>
                            </ul>
                        </div>
                        <div className='aboutUs'>
                            <h4>关于我们</h4>
                        </div>
                    </div>
                    <div className='f_bottom'>
                        <div style={{ float: 'left' }}>
                            <div className='left'>
                                <div>
                                    <ul>
                                        <li className='title'>
                                            <a href='javascript:'>关于滴滴</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>关于我们</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>文化与价值观</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>发展历程</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>管理团队</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>社会责任</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>DDWN</a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className='title'>
                                            <a href='javascript:;'>业务范畴</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>出行服务</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>国际业务</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>金融业务</a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className='title'>
                                            <a href='javascript:;'>滴滴科技</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>滴滴大脑</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>智慧交通</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>滴滴安全</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>科研网络</a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className='title'>
                                            <a href='javascript:;'>新闻中心</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>新闻</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>博客</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>资源库</a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className='title'>
                                            <a href='javascript:;'>联络我们</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>开放平台</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>人才招聘</a>
                                        </li>
                                        <li>
                                            <a href='javascript:;'>常见问题</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='right'>
                            <h5>客户端下载</h5>
                        </div>
                    </div>

                    <div className='desc'>
                        <p>2012-2016 滴滴出行科技有限公司 津ICP备15007503号-1</p>
                    </div>
                </div>

								<style jsx>{`
									.footer {
											width: 100%;
											height: 370px;
											background: ${this.bg};
											color: #ADADAD
									}

									.footerWrapper {
											width: 1200px;
											margin: 0 auto;
											height: 370px;
											padding: 64px 0;
									}

									a {
											color: #ADADAD; 
											font-size: 12px;
									}

									.f_top {
											width: 1200px;
											overflow: hidden;
											border-bottom: 3px solid #4E4E4E;
									}

									.service {
											width: 530px;
											float: left;
									}

									.service h3 {
											color: #ADADAD;
											font-size: 16px;
									}

									.service>ul {
											padding: 0;
											width: 530px;
											height: 46px;
									}

									.service>ul>li {
											float: left;
											font-size: 12px;
											color: #ADADAD;
											line-height: 22px;
											margin-right: 60px
									}

									.aboutUs {
											float: left;
											margin-left: 230px;
									}

									.aboutUs h4 {
											color: #ADADAD;
											font-size: 16px
									}

									.f_bottom {
											width: 1200px;
											margin-top: 30px;
											overflow: hidden;
									}
									.f_bottom .left {
											margin-left: -40px;
											width: 530px;
											display: flex;
											justify-content: start
									}

									.f_bottom .left .title a{
											font-size: 16px;
									}

									.f_bottom .right {
											float: left;
											margin-left: 265px
									}

									.f_bottom .right h5 {
											color: #ADADAD;
											font-size: 16px;
									}

									.desc {
											margin: 43px 0 100px;
											font-size: 12px;
									}
        			`}</style>
            </div>
        )
    }
}
