const formatCardNum = (str) => {
  if (!str) {
    return 
  }
  var  result = ''
  for (var i = 0; i < str.length; i++) {
    result += str[i]
    if ((i+1) % 4 === 0 && i !== 0) {
      result += " "
    }
  }
  return result
}

export default formatCardNum