import React, {Component} from 'react'
import icon_back from "../imgs/icon_back@2x.png";
import {withRouter} from 'react-router-dom'
import {Link} from 'react-router-dom'

import './cooperation-com.css'

import {titleList} from './cooperation-co'
class CooperationCom extends Component {
  // 跳转到咨询详情页
  newTarget = (item) => {
    const path = item.path
    if (path.includes('weixin')) {
      window.open(item.path)
    } else {
      this.props.history.push(item.path)
    }
  }

  render () {
    return (
      <div className='cooper-left'>
        <div className='back' onClick={() => this.props.history.replace('/cooperation')}>
          <img src={icon_back} alt='loading' />
          科技资讯
        </div>
        <div className='detail'>
          <span>近期新闻</span>
          <ul className='detail-list'>
            {
              titleList.slice(0, 6).map((item, index) => {
                return <li className='detail-item' key={index}>
                  <a target className='huanhang' onClick={() => this.newTarget(item)}>{item.title}</a>
                </li>
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}
export default withRouter(CooperationCom)
