import React, { Component } from 'react';
import $ from 'jquery'
import SupportFrom from '../component/support/suportform'

import {
  Form,
  Input,
  Select,
  Button
} from 'antd'

import hezuozixun from '../img/合作咨询.jpg'

const Item = Form.Item
const Option = Select.Option

const itemStyle = {
  marginBottom: '40px'
}

const formItemLayout = {
  labelCol: {
    offset: 8,
    span: 4
  },
  wrapperCol: {
    span: 12
  },
};

class Support extends Component {

    componentDidMount() {
      console.log('mount')
      $('.ant-input').css('box-shadow', 'none')
      $(".ant-input-search-button").css("background-color", "#19387B")
      $(".ant-input-search-button").css("box-shadow", 'none')
      $(".ant-input-search-button").css("text-shadow", 'none')
    }

  

    render() {

      const { getFieldDecorator } = this.props.form

      return (
        <div>
          <div className='headerImg' >
            <div className='info'>
              <h2>合作咨询</h2>
              <p>请详细描述您的需求，我们会安排专业人员尽快与您沟通对接</p>
            </div>
            <div className='circle'>
            </div>
          </div>

          <SupportFrom />
          
          <style jsx>{`
            .headerImg {
              position: relative;
              width: 100%;
              height: 31vw;
              background-image: url(${hezuozixun});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
            }
            .circle {
              position: absolute;
              width: 26vw;
              height: 26vw;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: rgba(0,0,0,0.2);
              border-radius: 50%;
            }

            .headerImg .info {
              position: relative;
              z-index: 2;
              width: 41%;
              height: 100%;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            
            h2 {
              color: #fff;
              font-size: 3vw;
            }

            p{
              color: #fff;
              font-size: 1vw;
              line-height: 1.4em;
              margin: 10px 0 49px 0;
              text-align: center;
            }
            .about {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 15vw;
            }
            
            .about a {
              display: block;
              width: 6.7vw;
              height: 2.5vw;
              border: 1px solid #999;
              text-align: center;
              line-height: 2.5vw;
              border-radius: 2px;
              font-size: 1vw;
            }

            .support-form-wrap {
              width: 100%;
              background: #F9F9F9;
            }

            .support-form {
              width: 1200px;
              margin: 0 auto;
              padding: 60px 0 100px 0;
              display: flex;
              justify-content: center;
            }
            

          `}</style>
        </div>
      )
    }
  }
  
  export default Form.create()(Support)