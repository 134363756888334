import React, { Component } from 'react'

export default class ProductAdv extends Component {

  state = {
    itemWidth: 0
  }

  productAdvWidth = () => {
    const {advList} = this.props
    if (advList.length === 3) {
      this.setState({
        itemWidth: 336
      })
    }
    if (advList.length === 4) {
      this.setState({
        itemWidth: 270
      })
    }
    if (advList.length === 2) {
      this.setState({
        itemWidth: 500
      })
    }
  }

  componentDidMount() {
    this.productAdvWidth()
  }

  render() {

    const advList = this.props.advList || []
    const {title} = this.props

    const itemWidth = this.state.itemWidth

    return (
      <div className='productAdv_wrap'>
        <div className='productAdv'>
          {
            title === 'solve' ? (
              <h3>方案优势</h3>
              ) : (
              <h3>产品优势</h3>
            )
          }
          <div className='advList'>
            {
              advList.map((item,index) => (
                <div className='advItem' key={index}>
                  <div className='adv_logo'>
                    <img src={item.icon} alt='loading' />
                    <div className='p-mask'></div>
                  </div>
                  <span>{item.title}</span>
                  <p>{item.desc}</p>
                </div>
              ))
            }
          </div>
        </div>

        <style jsx>{`
          .productAdv_wrap {
              width: 100%;
              height: 547px;
              background: #fff;
          }
          .productAdv {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 94px 0 100px 0
          }
          .productAdv .advList {
            margin-top: 53px;
            display: flex;
            justify-content: space-between;
          }
          .productAdv h3 {
            font-size: 32px;
            color: #333;
            text-align: center;
          }

          .advList .advItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: ${itemWidth}px;
          }
          .productAdv .advList .advItem .adv_logo {
            position: relative;
            width: 100px;
            height: 100px;
          }

          .p-mask {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 0;
            left: 0;
          }

          .advList .advItem .adv_logo>img {
            width: 98px;
            height: 98px;
          }
          .advList .advItem>span {
            display: block;
            font-size: 20px;
            color: #333;
            letter-spacing: 0.34px;
            text-align: center;
            line-height: 30px;
            margin: 20px 0 14px 0;
          }
          .advList .advItem>p{
            height: 42px;
            color: #747578;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            width: ${itemWidth}px;
          }
        `}</style>
      </div>
    )
  }
}
