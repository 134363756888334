import React, { Component } from 'react';
import { Row, Col } from 'antd';

require('./cardh.css')

class CardH extends Component {
    render() {
        let cardHStyle = {
            width:this.props.width,
            backgroundColor:'#FFFFFF',
            maxWidth:'400px',
            borderStyle:'none none none solid', 
            borderWidth:'0 0 0 5px',
            borderColor:'#FC813F',
            paddingTop:'14px',
            paddingRight:'5%',
            margin:'auto',
            color:'rgba(0, 0, 0, 0.45)'
        }

        let imgStyle = {
            marginLeft:'5%',
            width:this.props.imgWidth
        }

        let bodyStyle = {
            width:'100%',
            maxWidth:'400px',
            height:this.props.bodyHeight,
            margin:'auto',
            lineHeight:this.props.lineHeight
        }

        return (
            <Row style={{...cardHStyle}}>
                <Col span={24} style={{width:"100%",maxWidth:'400px'}}>
                    <img style={{...imgStyle}} src={this.props.imgUrl} />
                    <p style={{paddingLeft:'11%',fontFamily: 'DFPKingGothicGB',color:'rgba(0, 0, 0, 0.85)',fontWeight:'500',fontSize: '16px',display:'inline',textAlign:'center', verticalAlign:'middle'}}>
                        {this.props.title}
                    </p>
                </Col>
                <Col span={24} style={{...bodyStyle}}>
                    <div style={{paddingLeft:'5%',fontFamily: 'DFPKingGothicGB',paddingTop:'8%',color:'rgba(0, 0, 0, 0.45)',fontWeight:'light',fontSize: '14px'}}>
                        {this.props.desc}
                    </div>
                </Col> 
            </Row>
        );
    }
}

export default CardH;