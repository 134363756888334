import React, { Component } from 'react'

export default class NotMatch extends Component {
  render() {
    return (
      <div className='info-404-wrap'>
        <div className='info-404'>
          <h2>404</h2>
          <p>对不起，页面出错了</p>
        </div>

        <style jsx>{`
          .info-404-wrap {
            width: 100%;
            height: calc(100vh-64px);
            background: #fff;
          }
          .info-404 {
            position: absolute;
            left: 50%;
            top: 30%;
            width: 200px;
            height: 300px;
            transform: translate(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .info-404 h2 {
            width: 200px;
            text-align: center;
            margin: 0 auto;
            font-size: 24px;
            font-weight: bold;
          }
        `}</style>
      </div>
    )
  }
}
