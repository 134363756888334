import React, { Component } from 'react'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import Footer from '../../component/footer/footer'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import scrollTop from '../../utils/scrollTop'

import jisu from '../../img/ICON/技术领先.png'
import sudukuai from '../../img/ICON/高效开发.png'
import chaxunkuai from '../../img/ICON/查询快速.png'
import shenwenshibie from './image/header/声纹识别.jpg'
import lixianyanzheng from './image/useful/离线声纹验证.jpg'
import zaixianshibie from './image/useful/在线声纹识别.jpg'
import zaixianyanzheng from './image/useful/在线声纹验证.jpg'
import applogin from './image/recommond/App登录.jpg'
import gexinghua from './image/recommond/个性化服务.jpg'
import bg_kefu from './image/recommond/智能客服.jpg'
import bg_jiaju from './image/recommond/智能家居.jpg'
import bg_zhongduan from './image/recommond/智能终端.jpg'

const voiceDesc = '通过语音信号对说话人的身份进行鉴别的技术，滴滴声纹识别技术采用业界先进的算法和模型，依托海量语音数据，为用户提供精准、高效、稳定的声纹验证、声纹识别服务'

export default class VoicePrint extends Component {

  constructor(props) {
    super(props)
    this.state = {
      advList: [
        {
          title: '技术领先',
					desc: '依靠业内领先的声纹技术，滴滴在线声纹服务可实现高于98%的声纹验证准确率和召回率',
					icon: jisu
        },
        {
          title: '速度快',
					desc: '滴滴在线声纹验证服务的计算实时率小于0.1，处理10秒语音仅需1秒即可返回结果',
					icon: sudukuai
        },
        {
          title: '查询快',
					desc: '在线声纹识别服务支持秒级查询，注册人数10000人时查询耗时小于10秒',
					icon: chaxunkuai
        },
			],
			recommondSence: [
				{
					name: 'App登录',
					content: '支持如“声音锁”登录功能，需要预先录入注册音频，随后仅需说出特定词语即可完成登录验证',
					icon: 'iconAPPdenglu',
					bg: applogin
				},
				{
					name: '智能终端',
					content: '可为各类场站（如机场、火车站）的智能售票机、无人售货机、智能储物柜等终端机提供身份验证服务',
					icon: 'iconzhinengzhongduan',
					bg: bg_zhongduan
				},
				{
					name: '智能家居',
					content: '适用于智能音箱、智能门锁、智能安防系统等需要身份验证的智能家居设备',
					icon: 'iconzhinengjiaju',
					bg: bg_jiaju
				},
				{
					name: '个性化服务',
					content: '为基于用户身份的个性化服务提供支持',
					icon: 'iconfuwuguankong',
					bg: gexinghua
				},
			],
			more: [
				{
					name: '语音识别',
					path: '/voice'
				},
				{
					name: '自然语音理解',
					path: '/understand'
				}
			]
    }
  }

	componentWillMount() {
		scrollTop()
	}

	render() {
		return (
			<div>
				<Header headerImg={shenwenshibie} title={'声纹识别'} desc={voiceDesc} />

				{/* 推荐场景 */}
				<div className='recommend_wrap'>
					<div className='recommend'>
						<h3>功能介绍</h3>
						<div className='rec_list'>
							<div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${zaixianyanzheng})`}}></div>
								<div>
									<h4>在线声纹验证</h4>
									<p>验证用户的身份是否与注册信息一致，用户预先录入一定长度的语音作为注册信息，之后用户仅需说出指令词即可完成身份验证</p>
								</div>
							</div>
							<div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${zaixianshibie})`}}></div>
								<div>
									<h4>在线声纹识别</h4>
									<p>类似身份查询，在线声纹系统从存有多条注册信息的数据库中查询与当前用户最匹配的结果</p>
								</div>
							</div>
							<div className='rec_item'>
								<div className="img" style={{backgroundImage: `url(${lixianyanzheng})`}}></div>
								<div>
									<h4>离线声纹验证</h4>
									<p>结合滴滴唤醒技术，为手机等嵌入式设备提供离线式声纹验证服务，无需联网，具有低资源占用、高准确率的特点</p>
								</div>
							</div>
						</div>
					</div>
				</div>

        <ProductAdv advList={this.state.advList} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<MoreRecommend more={this.state.more} />

				<style jsx>{`
					.recommend_wrap {
						width: 100%;
						height: 880px;
						background: #F5F5F5;
					}
					.recommend {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
						text-align: center;
					}
					.recommend h3 {
						font-size: 38px;
						color: #333;
					}
					.rec_list {
						display: flex;
						justify-content: space-between;
						margin-top: 65px;
						width: 100%;
						height: 383px;
						padding-left: 0;
					}
					.rec_list .rec_item {
						width: 380px;
						height:580px;
					}

					.rec_list .rec_item>.img {
						width: 380px;
						height: 340px;
						background: contain;
					}

					.rec_list .rec_item>div{
						width: 380px;
						height: 240px;
						display: block;
						background: #fff;
						padding: 42px 0 0 28px;
					}
					.rec_list .rec_item>div>h4{
						display: block;
						width: 325px;
						height: 45px;
						color: #333;
						font-size: 20px;
						line-height: 30px;
						text-align: left;
					}
					.rec_list .rec_item>div>p {
						width: 325px;
						height: 120px;
						font-size: 14px;
						color: #747578;
						line-height: 22px;
						text-align: left;
					}


					.quickEntry_wrap {
						width: 100%;
						height: 300px;
						background: #f5f5f5;
					}
					.quickEntry {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 100px 0;
					}
					.quickEntry .entry {
						width: 100%;
						height: 100px;
						background: #fff;
						padding: 36px 0 0 40px;
					}
					.quickEntry .entry>span {
						float: left;
						display: block;
						width: 99px;
						height: 28px;
						font-size: 20px;
						color: #747578;
						font-weight: bold;
						text-align: center;
					}
					.quickEntry .entry>p {
						float: left;
						color: #747578;
						font-size: 20px;
					}
					.recommendScene_wrap {
						width: 100%;
						height: 1290px;
						background: #202530;
					}
					.recommendScene {
						width: 1200px;
						height: 100%;
						margin: 0 auto;
						padding: 94px 0 100px 0;
					}
					.recommendScene>h3 {
						width: 800px;
						height: 53px;
						text-align: center;
						color: #fff;
						font-size: 38px;
						margin: 0 auto;
						margin-bottom: 53px;
					}
					.recommendScene .sceneList {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 1200px;
					}

					.recommendScene .sceneList .sceneItem {
						width: 380px;
						height: 480px;
						padding: 60px 0 0 40px;
						background: #474747;
						margin-bottom: 30px;
					}
					.recommendScene .sceneList .sceneItem>span{
						display: block;
						width: 221px;
						height: 30px;
						color: #fff;
						font-size: 22px;
					}
					.recommendScene .sceneList .sceneItem >.mark {
						width: 40px;
						height: 1px;
						color: #F37E43;
						border: 3px solid #F37E43;
						margin: 27px 0 20px 0;
					}
					.recommendScene .sceneList .sceneItem>p {
						width: 300px;
						height: 100px;
						font-size: 14px;
						line-height: 20px;
						color:#ededed;
					}

					.advList .advItem .adv_logo>.img {
						width: 100px;
						height: 100px;
					}
					.advList .advItem>span {
						display: block;
						width: 149px;
						height: 30px;
						font-size: 20px;
						color: #333;
						letter-spacing: 0.34px;
						text-align: center;
						line-height: 30px;
						margin: 25px 0;
					}
					.advList .advItem>p{
						width: 270px;
						height: 42px;
						color: #747578;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
					}
        		`}</style>
			</div>
		)
	}
}
