module.exports = {
  moxingliangxiang: require('./images/2019.02.02.jpg'),
  jichuyanjiu: require('./images/2019.06.14.jpg'),
  yuyanmoxing: require('./images/2019.08.03.jpg'),
  chuxingyingyong: require('./images/2019.08.14.jpg'),
  kejirenwen: require('./images/2019.08.27.jpg'),
  didiyingyong: require('./images/2019.08.31.jpg'),
  didichuxing: require('./images/2019.10.17.jpg'),
  kaifangpingtai2: require('./images/2019.10.18.jpg'),
  dididangxuan: require('./images/2019.10.25.jpg'),
  rumendaojingtong: require('./images/2019.11.12.jpg'),
  ces: require('./images/0114.jpg'),
  meet: require('./images/1230.jpg'),
  santironghe: require('./images/1211.jpg'),
  chenglihuiyi: require('./images/1113.jpg'),
  ciisjiyu: require('./images/1108.jpg'),
}

