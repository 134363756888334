import React, { Component } from 'react'
import scrollTop from '../../utils/scrollTop'

import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'
import {nlpdata} from '../../data/productService'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import axios from 'axios'

import {BASE_URL} from '../../utils/config'

const Wenben = () => (
  <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
    <div className='wenben-item'>
      <span className='iconfont iconquanfangweifenxiwenbenneirongjiyuyi'></span>
      <div>
        <h3>全方位分析文本内容及语义</h3>
        <p>利用滴滴深度学习模型结合不同题材文章语义特征，对内容全方位充分理解，智能提取文本核心主要内容</p>
      </div>
    </div>
    <div className='wenben-item'>
      <span className='iconfont iconbiaotizhinengshengcheng'></span>
      <div>
        <h3>标题智能生成</h3>
        <p>可对文章进行语义分析，篇章结构分析，段落分布分析，智能判断得出语句重要性程度，总结核心内容，形成文章标题</p>
      </div>
    </div>
    <div className='wenben-item'>
      <span className='iconfont iconwenbenzhaiyaotiqu'></span>
      <div>
        <h3>文章概要提取</h3>
        <p>可对文章内容进行分析，提取文章重点内容向用户推送，有助于提升点击率并使用户阅读效率大大提升</p>
      </div>
    </div>
    <div className='wenben-item'>
      <span className='iconfont iconfunengwenchuangjizhinengxiezuo'></span>
      <div>
        <h3>赋能文创及智能写作</h3>
        <p>根据实时需求控制需求长度，自动抽取核心信息，形成简要结果，赋能文创写作、内容分发、内容推送等多种场景</p>
      </div>
    </div>
    <style jsx>{`
      .wenben-item {
        display: flex;
        margin-bottom: 60px;
      }

      .iconfont {
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 28px;
        line-height: 31px;
        margin-right: 15px;
      }

      .wenben-item h3 {
        font-size: 20px;
        color: #333333;
      }

      p {
        width: 477px;
        font-size: 14px;
        color: #808080;
        line-height: 21px;
      }
    `}</style>
  </div>
)
// axios.defaults.headers.Caller = 'nlp'
export default class SubNLP extends Component {

  state = {
    header: '',
    title: '',
    desc: '',
    funcIntro: '',    // 功能介绍
    productAdv: [],
    recommondSence: [],
    more: [],
  }

  /**
   * 根据 params.id ，加载不懂页面，获得不同的数据
   */
  getContent(newPath) {
    let path = this.props.match.params.id
    if (newPath && newPath !== path) {
      path = newPath
    }
    const item = nlpdata.find(item => item.path === path)
    const { title, desc, header, productAdv, recommondSence, more, funcIntro, funcImg } = item
    this.setState({
      header,
      title,
      desc,
      funcIntro,
      productAdv,
      recommondSence,
      more,
      funcImg
    })
  }

  componentWillMount() {
    this.getContent()
    scrollTop()
  }

  componentDidMount() {

    axios.post(BASE_URL + '/api/translate',
     {
      text: '我爱中国',
      source: 'zh',
      target: 'en'
    }).then((res) => console.log(res.data))
  }

  componentWillReceiveProps(nextProps) {
    const newPath = nextProps.match.params.id
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.getContent(newPath)
      scrollTop()
    }
  }

  handleContextMenu = ($event) => {
    $event.preventDefault()
  }

  render() {
    const { desc, productAdv, recommondSence, more, funcIntro, header} = this.state
    const title = this.state.title || ''
    const funcImg = this.state.funcImg || ''
    return (
      <div>
        <Header title={title} headerImg={header} desc={desc} />

        <div className='func-show-wrap'>
          <div className='func-intro'>
            <h3>功能介绍</h3>
            {
              funcIntro ?
                <p>{funcIntro}</p> :
                null
            }
            <div className='intro-list'>
              {title === '文本摘要' ?
                  <Wenben />
               :  <img style={{width: '100%', height: '100%'}} alt='' src={funcImg} onContextMenu={this.handleContextMenu}/>
              }
            </div>
          </div>
        </div>

        <ProductAdv advList={productAdv} />

        <RecommondSence recommondSence={recommondSence}/>

        <MoreRecommend more={more} />

        <style jsx>{`
          .func-show-wrap {
            width: 100%;
            background: #f5f5f5;
          }

          .func-intro {
            margin: 0 auto;
            width: 1200px;
            padding: 93px 0 108px 0;
          }

          .func-intro>h3{
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 54px;
          }

          .func-intro>p {
            width: 700px;
            color: #747578;
            font-size: 14px;
            line-height: 20px;
            margin: 0 auto;
            text-align: center;
          }

          .intro-list {
            width: 100%;
            margin-top: 36px;
            display: flex;
            justify-content: space-between;
          }

          .intro-item {
            width: 585px;
            height: 340px;
            background: #93F086;
          }

          .func-show {
            margin: 0 auto;
            width: 1200px;
            height: 100%;
            padding: 85px 0 109px 0;
          }

          .func-show>h2 {
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 54px;
          }

          .recognition-container {
            width: 1200px;
            height: 803px;
            background: #93F086;
          }

          .service-type-wrap {
            width: 100%;
            height: 714px;
            background: #f5f5f5;
          }

          .service-type {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 94px 0 100px 0;
          }

          .service-type>h4 {
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 111px;
          }

          .service-list {
            display: flex;
            justify-content: space-between;
          }

          .service-item {
            text-align: center;
            width: 360px;
            height: 356px;
            background: #fff;
            padding-top: 70px;
          }

          .service-item>span {
            font-family: PingFangSC-Medium;
            font-size: 22px;
            color: #333;
          }

          .service-item>p {
            margin: 0 auto;
            margin-top: 45px;
            width: 288px;
            font-size: 16px;
            color: #747578;
          }

        `}</style>
      </div>
    )
  }
}

