import React, { Component } from 'react'

import tuxiang from '../../img/ICON/图像技术'
import xiaoguo from '../../img/ICON/效果自然.png'

export default class RecommondSence extends Component {

  _getComputedWidth = (recList) => {
    const size = recList.length
    if (size === 2) {
      return {iWidth: 600, pWidth: 440}
    } else if (size === 4) {
      return {iWidth: 300, pWidth: 220}
    } else {
      return {iWidth: 400, pWidth: 293}
    }
  }

  render() {

    const { recommondSence } = this.props

    return (
      <div className='recommendScene_wrap'>
        <div className='recommendScene'>
          <h3>推荐场景</h3>
          <div className='sceneList'>
            {
              recommondSence.map((item, index) => (
                <div className='sceneItem'
                style={{backgroundImage: `url(${item.bg})` }}
                key={index}>
                  <div className='mask'></div>
                  <div className={`icon iconfont ${item.icon}`} ></div>
                  <span>{item.name}</span>
                  <p>{item.content}</p>
                </div>
              ))
            }
          </div>
        </div>

        <style jsx>{`
            .recommendScene_wrap {
                width: 100%;
                background: #222835;
            }
            .recommendScene {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 94px 0 100px 0;
            }
            .recommendScene>h3 {
                width: 800px;
                height: 53px;
                text-align: center;
                color: #fff;
                font-size: 32px;
                margin: 0 auto;
                margin-bottom: 53px;
            }
            .recommendScene .sceneList {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 1200px;
            }

            .recommendScene .sceneList .sceneItem {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: ${this._getComputedWidth(recommondSence).iWidth}px;
                height: 480px;
                background-size: contain;
            }

            .mask {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background: rgba(25,56,123,0.55) !important;
              transition: 0.2s;
            }

            .sceneItem:hover .mask {
              display: block;
              background: rgba(25,56,123,0.9) !important;
            }

            .sceneItem:hover .icon, .sceneItem:hover span {
              transform: translateY(-108px)
            }

            .sceneItem:hover p {
              visibility: visible !important;
              transform: translateY(-108px);
              opacity: 1 !important;
            }

            .sceneItem .icon {
              position: relative;
              z-index: 5;
              width: 80px;
              height: 80px;
              font-size: 70px;
              color: #fff;
              text-align: center;
              background-repeat: no-repeat;
              background-size: 100%;
              background-position: center center;
              border-radius: 50%;
              margin-top: 168px;
              transition: 0.3s;
            }

            .recommendScene .sceneList .sceneItem>span{
                position: relative;
                z-index: 5;
                color: #fff;
                font-size: 22px;
                margin-top: 33px;
                transition: 0.3s;
            }

            .recommendScene .sceneList .sceneItem>p {
                margin-top: 24px;
                visibility: hidden;
                width: ${this._getComputedWidth(recommondSence).pWidth}px;
                height: 100px;
                font-size: 14px;
                line-height: 20px;
                color:#ededed;
                opacity: 0;
                transition: 0.5s;
            }
        `}</style>
      </div>
    )
  }
}
