import React, {Component} from 'react'
import PropTypes from 'prop-types'

import './paging.css'

export default class Paging extends Component {
  static propTypes = {
    total: PropTypes.number, // 分页的总数
    current: PropTypes.number, // 当前的页码，用来初始化第一页
    onChange: PropTypes.func, // 页码改变的函数
    defaultPageSize: PropTypes.number // 默认每页显示几条数据
  }
  state = {
    currentPaging: 1, // 默认第一页
    numbersPaging: [], // 页码显示列表
    maxPageNum: ''
  }

  componentDidMount() {
    this.showPagingNumber()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.current === 1) {
      this.setState({
        currentPaging: 1
      })
      this.showPagingNumber()
    }
  }

  // 显示页面的数字
  showPagingNumber = () => {
    let arr = []
    const {defaultPageSize, total} = this.props
    const maxPage = Math.ceil(total / defaultPageSize)
    for (let i = 1; i <= maxPage; i++) {
      arr.push(i)
    }
    if (arr.length > 5) {
      arr = arr.splice(0, 5)
    }
    this.setState({
      numbersPaging: arr,
      maxPageNum: maxPage
    })
  }

  // 点击页码改变当前页数
  currentPageClick = (num) => {
    let arr = []
    let {maxPageNum} = this.state
    if (maxPageNum > 5) {
      if (num > 3 && num < (maxPageNum - 2)) {
        arr = [num -2, num - 1, num, num + 1, num + 2]
      } else if (num <= 3) {
        arr = [1, 2, 3, 4, 5]
      } else if((maxPageNum - 2) < num < maxPageNum) {
        arr = [maxPageNum-4, maxPageNum-3, maxPageNum-2, maxPageNum-1, maxPageNum]
      }
    } else {
      for (let i = 1; i <= maxPageNum; i++) {
        arr.push(i)
      }
    }
    this.props.onChange(num)
    this.setState({
      currentPaging: num,
      numbersPaging: arr
    })
  }


  // 点击上下页，页码
  numberClick = async (direction) => {
    let {currentPaging, numbersPaging, maxPageNum} = this.state
    const lastPageNum = numbersPaging[numbersPaging.length - 1]
    const middleNum = (numbersPaging[0] + lastPageNum) / 2
    if (direction === 'prev') {
      if ((maxPageNum > 5) && (currentPaging === middleNum) && (5 < lastPageNum)) {
        numbersPaging = numbersPaging.map((item, index) => {
          return item -1
        })
      }
      currentPaging = currentPaging === 1 ? currentPaging : currentPaging - 1
    } else {
      // 因为默认的初始值页码就是1，所以先判断这个页面是否是中间码
      if ((maxPageNum > 5) && (currentPaging === middleNum) && (lastPageNum < maxPageNum)) {
        numbersPaging = numbersPaging.map((item, index) => {
          return item + 1
        })
      }
      currentPaging = currentPaging === lastPageNum ? currentPaging : currentPaging + 1
    }
    await this.setState({
      currentPaging,
      numbersPaging
    })
    this.props.onChange(this.state.currentPaging)
  }


  render() {
    const {numbersPaging, currentPaging, maxPageNum} = this.state
    return (
      <div className="paging-wrap">
        <div className={`icon iconfont iconpc-zuojiantou-copy paging-div ${currentPaging === 1 ? 'isDisable' : ''}`} onClick={() => this.numberClick('prev')}></div>
        <ul className='paging-numbers'>
          {
            numbersPaging.map((num, index) => (
              <li className="paging-div" key={index} onClick={() => this.currentPageClick(num)}
                  style={{color: `${currentPaging === num ? '#19387B' : '#090909'}`}}>{num}
              </li>
            ))
          }
        </ul>
        <div className={`icon iconfont iconpc-youjiantou-copy paging-div ${currentPaging === maxPageNum ? 'isDisable' : ''}`} onClick={() => {this.numberClick('next')}}></div>
      </div>
    )
  }
}
