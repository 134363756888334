import React, { Component } from 'react'

import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'
import Features from '../../component/ProService/Features'
import jisu from '../../img/ICON/技术领先.png'
import wendin from '../../img/ICON/服务稳定.png'
import kekao from '../../img/ICON/保密可靠.png'
import didifanyi from './ziranyuyan/滴滴翻译/滴滴翻译.jpg'
import ssfy from './ziranyuyan/滴滴翻译/推荐场景X6/实时翻译.jpg'
import znyj from './ziranyuyan/滴滴翻译/推荐场景X6/智能硬件.jpg'
import hwmy from './ziranyuyan/滴滴翻译/推荐场景X6/海外贸易.jpg'
import wyfy from './ziranyuyan/滴滴翻译/推荐场景X6/网页翻译.jpg'
import spzm from './ziranyuyan/滴滴翻译/推荐场景X6/视频字幕.jpg'
import kgbg from './ziranyuyan/滴滴翻译/推荐场景X6/跨国办公.jpg'
import r_didifanyi from '../../data/语言处理技术/func-img/滴滴翻译.jpg'

export default class Translation extends Component {

  componentWillMount() {
    document.getElementById('root').scrollIntoView(true)
  }

  constructor(props) {
    super(props)
    this.state = {
      advList: [
        {
          title: '技术领先',
          desc: '由专业团队组成，经公司大量业务落地验证',
          icon: jisu
        },
        {
          title: '快速稳定',
          desc: '响应时间短，服务器稳定，多机房部署',
          icon: wendin
        },
        {
          title: '易用可靠',
          desc: '提供标准化接口，可快速部署，便于与其他技术结合使用，性能稳定',
          icon: kekao
        }
      ],
      recommondSence: [
				{
					name: '实时翻译',
          content: '大型演讲或者集会中，可实时翻译发言者讲话内容',
          icon: 'iconshishifanyi',
          bg: ssfy
				},
				{
					name: '跨国办公',
          content: '会议场景等多语言交流情况下，实时翻译语言内容，有助于参会者理解',
          icon: 'iconkuaguobangong',
          bg: kgbg
				},
				{
					name: '智能硬件',
          content: '将翻译能力赋予各种智能家居或车载设施，服务更多用户',
          icon: 'iconzhinengyuyin',
          bg: znyj,
				},
				{
					name: '视频字幕',
          content: '自动生成多语言字幕，有助于信息广泛迅速传播',
          icon: 'iconshipinzimu',
          bg: spzm
        },
        {
					name: '网页翻译',
          content: '可翻译不同语种的网页，提供多语种对照',
          icon: 'iconwangyefanyi',
          bg: wyfy
        },
        {
					name: '海外贸易',
          content: '基于翻译能力，打破语言障碍，降低贸易双方沟通成本，提升工作效率',
          icon: 'iconhaiwaimaoyi',
          bg: hwmy
				},
      ],
      funcList: [
        {
          desc: '基于滴滴海量数据积累及实际业务验证，采用业内先进技术，利用深度学习拓展语料库，实现从源语言文本到目标语言文本的自动翻译，且精准度达到业内领先水平',
          image: r_didifanyi
        }
      ]
    }
  }

  render() {
    return (
      <div>
        <Header headerImg={didifanyi} title={'滴滴翻译'} desc={'基于滴滴大规模数据积累，采用业内先进技术，建立双语语料库并自动学习翻译知识，实现从源语言文本到目标语言文本的自动翻译，已广泛应用于各项业务'} />

        <Features funcList={this.state.funcList} />

        <ProductAdv advList={this.state.advList}/>

        <RecommondSence recommondSence={this.state.recommondSence} />

        <div className='quickEntry_wrap'>
          <div className='quickEntry'>
            <div className='entry'>
              <span>更多推荐:</span>
              <p>语言处理技术  |  机器人平台</p>
            </div>
          </div>
        </div>
        <style jsx>{`
            .recommendScene_wrap {
                width: 100%;
                background: #202530;
            }
            .recommendScene {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 94px 0 100px 0;
            }
            .recommendScene>h3 {
                width: 800px;
                height: 53px;
                text-align: center;
                color: #fff;
                font-size: 38px;
                margin: 0 auto;
                margin-bottom: 53px;
            }
            .recommendScene .sceneList {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 1200px;
            }

            .recommendScene .sceneList .sceneItem {
                width: 380px;
                height: 480px;
                padding: 60px 0 0 40px;
                background: #474747;
                margin-bottom: 20px;
            }
            .recommendScene .sceneList .sceneItem>span{
                display: block;
                width: 221px;
                height: 30px;
                color: #fff;
                font-size: 22px;
            }
            .recommendScene .sceneList .sceneItem >.mark {
                width: 40px;
                height: 1px;
                color: #F37E43;
                border: 3px solid #F37E43;
                margin: 27px 0 20px 0;
            }
            .recommendScene .sceneList .sceneItem>p {
                width: 300px;
                height: 100px;
                font-size: 14px;
                line-height: 20px;
                color:#ededed;
            }

            .quickEntry_wrap {
              width: 100%;
              height: 300px;
              background: #f5f5f5
            }
            .quickEntry {
              width: 1200px;
              height: 100%;
              margin: 0 auto;
              padding: 100px 0;
            }
            .quickEntry .entry {
              width: 100%;
              height: 100px;
              background: #fff;
              padding: 36px 0 0 40px;
            }
            .quickEntry .entry>span {
              float: left;
              display: block;
              width: 99px;
              height: 28px;
              font-size: 20px;
              color: #747578;
              font-weight: bold;
              text-align: center;
            }
            .quickEntry .entry>p {
              float: left;
              color: #747578;
              font-size: 20px;
            }

            .proFunc_wrap {
                width: 100%;
                height: 2230px;
                background: #f5f5f5;
            }
            .proFunc {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 100px 0 100px 0;
            }
            .proFunc h2 {
                width: 152px;
                height: 45px;
                text-align: center;
                margin: 0 auto;
                font-size: 38px;
                color: #333;
                margin-bottom: 60px
            }
            .proFunc .proList {
                width: 1200px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .proFunc .proList .proItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 380px;
                height: 440px;
                background: #fff;
                margin-bottom: 30px;
            }
            .proFunc .proList .proItem>span {
                display: block;
                width: 140px;
                height: 30px;
                font-size: 20px;
                color: #333;
                text-align: center;
                margin: 20px 0;
            }
            .proFunc .proList .proItem>p {
                width: 310px;
                height: 42px;
                font-size: 14px;
                color: #747578;
                line-height: 22px;
                margin-bottom: 30px;
            }
            .proFunc .proList .proItem .select {
                width: 345px;
                height: 22px;
                display: flex;
                justify-content: space-around;
            }
            .proFunc .proList .proItem .select>a {
                color: #FF7F41 !important;
            }
        `}</style>

      </div>


    )
  }
}
