import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import publicImg from '../../img/public-icon.jpg'

require('./footer-msg.less')

export default class FooterMsg extends Component {
  state = {
    fullYear: 2020
  }
  componentDidMount() {
    this.setState({
      fullYear: new Date().getFullYear()
    })
  }
  render () {
    const {fullYear} = this.state
    return (
      <div className="footer-msg-wrap">
        <div className="footer-inner">
          <div className="footer-msg">
            <div className="footer-logo">
              <div className="logo"></div>
              <div className="logo-title">引领智能出行新时代</div>
            </div>
            <div className="line"></div>
            <div className="text">
              <div className="contact-us font-size">联系我们</div>
              <div className="contact-list">
                <Link to={'/support'} className="item-list">商务合作</Link>
                <a href='mailto:openai@didiglobal.com' className="item-list">售前咨询</a>
                <a href='mailto:openai@didiglobal.com' className="item-list">意见反馈</a>
                <a href='http://talent.didiglobal.com/' className="item-list">人才招聘</a>
              </div>
            </div>
            <div className="friend">
              <div className="font-size">友情链接</div>
              <div className="contact-list">
                <a href="http://www.xiaojukeji.com" className="item-list">滴滴官网</a>
                <a href='http://qunyan.didiglobal.com/' className="item-list">滴滴智能出行开放平台</a>
                <a href='https://outreach.didichuxing.com' className="item-list">滴滴科技合作</a>
                <a href='https://www.didiyun.com' className="item-list">滴滴云</a>
              </div>
            </div>
            <div className="public">
              <div className="public-title">滴滴AI公众号</div>
              <img className="puclic-img" src={publicImg}></img>
            </div>
          </div>
          <div className="didi-message">Copyright © 2012-{fullYear} 北京小桔科技有限公司 版权所有</div>
        </div>
      </div>
    )
  }
}
