export default [
    {
        id: 1,
        name: '图像技术',
        category: [
            {
                categoryName: '人脸识别',
                cateList: [
                    {
                        path: '/face/detection',
                        name: '人脸检测'
                    },
                    {
                        path: '/face/identify',
                        name: '人脸检索'
                    },
                    {
                        path: '/face/verify',
                        name: '人脸验证'
                    },
                    {
                        path: '/face/attribute',
                        name: '人脸属性分析'
                    },
                    {
                        path: '/face/towards',
                        name: '人脸朝向分析'
                    },
                    {
                        path: '/face/body',
                        name: '人脸活体检测'
                    },
                ],
                path: '/face',
            },
            {
                categoryName: '人体识别',
                path: '/body',
                cateList: [
                    {
                        path: '/body/keypoint',
                        name: '人体关键点检测'
                    },
                    {
                        path: '/body/Re-ID',
                        name: '行人Re-ID'
                    },
                    {
                        path: '/body/behavior',
                        name: '行为检测'
                    },
                    {
                        path: '/body/gesture',
                        name: '手势识别'
                    },
                ],
            },
            {
                categoryName: '文字识别',
                cateList: [
                    {
                        path: '/character/driver',
                        name: '驾驶证识别'
                    },
                    {
                        path: '/character/travel',
                        name: '行驶证识别'
                    },
                    {
                        path: '/character/IDcard',
                        name: '身份证识别'
                    },
                    {
                        path: '/character/bank',
                        name: '银行卡识别'
                    },
                    {
                        path: '/character/plate',
                        name: '车牌识别'
                    },
                    {
                        path: '/character/QRcode',
                        name: '二维码识别'
                    },
                    {
                        path: '/character/Barcode',
                        name: '条形码识别'
                    },
                    {
                        path: '/character/VINcode',
                        name: 'VIN码识别'
                    },
                    {
                        path: '/character/customOCR',
                        name: '自定义模版识别'
                    },
                    {
                        path: '/character/document',
                        name: '通用文档识别'
                    },
                    {
                        path: '/character/business',
                        name: '营业执照识别'
                    }
                ],
                path: '/character'
            },
            {
                categoryName: '车辆分析',
                path: '/car',
                cateList: [
                    {
                        path: '/car/model-reco',
                        name: '车型识别'
                    },
                    {
                        path: '/car/car-detect',
                        name: '车辆检测'
                    },
                   /*  {
                        path: '',
                        name: '车辆属性识别'
                    }, */
                    {
                        path: '/car/count',
                        name: '车流统计'
                    },
                ]
            },
            {
                categoryName: '内容审核',
                path: '/review',
                cateList: [
                    {
                        path: '/review/yellow',
                        name: '涉黄图像鉴别'
                    },
                    {
                        path: '/review/political',
                        name: '涉政图像鉴别'
                    },
                    {
                        path: '/review/custom',
                        name: '自定义内容检测'
                    },
                ]
            }
        ]
    },
    {
        id: 2,
        name: '语音技术',
        category: [
            {
                categoryName: '语音识别',
                cateList:  [],
                path: '/voice'
            },
            {
                categoryName: '语音合成',
                cateList: [],
                path: '/voicecompose'
            },
            {
                categoryName: '语言理解',
                cateList: [],
                path: '/understand'
            },
            {
                categoryName: '语音增强',
                cateList: [],
                path: '/voicesignal'
            },
            {
                categoryName: '语音唤醒',
                cateList: [],
                path: '/voicewake'
            },
            {
                categoryName: '声纹识别',
                cateList: [],
                path: '/voiceprint'
            }
        ]
    },
    {
        id: 3,
        name: '自然语言处理',
        category: [
            {
                categoryName: '语言处理技术',
                cateList: [
                    /* {
                        path: '/NLP/label',
                        name: '标注平台'
                    }, */
                    {
                        path: '/NLP/emotion',
                        name: '情感识别'
                    },
                    {
                        path: '/NLP/mood',
                        name: '情绪识别'
                    },
                    {
                        path: '/NLP/correction',
                        name: '中文纠错'
                    },
                    {
                        path: '/NLP/segment',
                        name: '中文分词'
                    },
                    {
                        path: '/NLP/entity',
                        name: '命名实体识别'
                    },
                    {
                        path: '/NLP/dataanony',
                        name: '数据匿名'
                    },
                    {
                        path: '/NLP/summary',
                        name: '文本摘要'
                    },
                    {
                        path: '/NLP/similarity',
                        name: '语义相识度'
                    },
                    {
                        path: '/NLP/vector',
                        name: '词向量'
                    },
                    {
                        path: '/NLP/keyword',
                        name: '关键词抽取'
                    },
                    {
                        path: '/NLP/wordMark',
                        name: '词性标注'
                    },
                    {
                        path: '/NLP/aVector',
                        name: '句向量'
                    }
                ],
                path: '/NLP'
            },
            {
                categoryName: '知识图谱',
                cateList: [],
                path: '/knowledge'
            },
            {
                categoryName: '滴滴翻译',
                cateList: [],
                path: '/translation'
            },
            {
                categoryName: '滴滴机器人平台',
                cateList: [],
                path: '/robot'
            }
        ]
    },
    {
        id: 4,
        name: '数据智能',
        category: [
            {
                categoryName: '智能预测',
                cateList: [

                ],
                path: '/intellPrediction'
            },
            {
                categoryName: '智能推荐',
                cateList: [

                ],
                path: '/intellRecommendation'
            },
            {
                categoryName: '智能匹配',
                cateList: [

                ],
                path: '/intellMatching'
            },
            {
                categoryName: '异常监控',
                cateList: [

                ],
                path: '/abnormalMonitoring'
            },
            /* {
                categoryName: '数据分析',
                cateList: [

                ],
                path: '/dataAnalysis'
            }, */
        ]
    },
    {
        id: 5,
        name: '时空智能',
        category: [
            {
                categoryName: '智能地图',
                cateList: [
                ],
                path: '/intelmap'
            },
            {
                categoryName: '时序预测',
                cateList: [],
                path: '/sequentialPredict'
            }
        ]
    }
]
