import bannerImg from './img/banner.jpg'
import comImage from './img/a.png'
import './main.css';

// export const BASE_URL = 'http://ai.intra.xiaojukeji.com/'; // 内网环境
export const BASE_URL = 'http://ai.didiglobal.com/'; // 外网环境
export const IMG_BASE_URL = 'http://100.69.238.155:8002/static/labelpbi/open/';
export const bannerHeader = {
    backgroundImage:`url(${bannerImg})`,
    backgroundPosition:'center',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    margin: 'auto',
    width:'100%',
    height:'340px',
}

export const bannerCap = {
    fontFamily: 'DFPKingGothicGB',
    fontSize: '40px',
    //color: '#272933',
    color:'white',
    letterSpacing: '0.81px',
    textAlign: 'center',
    paddingTop:'4%',
}

export const bannerDesc = {
    fontFamily: 'DFPKingGothicGB',
    fontSize: '20px',
    //color: '#272933',
    color:'white',
    lineHeight: '30px',
    width:'38%',
    maxWidth:'1136',
    fontWeight:'normal',
    margin: '0 auto',
    marginTop:'2%'
}

export const  secBbannerBtn = {
    fontSize: '26px',
    textAlign: 'center'
}

export const bannerBtn = {
    fontSize: '32px',
    textAlign: "center",
    marginLeft:'6%',
    marginTop:'2%',
    height:'80px'
}

export const bannerTitle = {
    fontFamily: 'DFPKingGothicGB',
    textAlign: "center",
    fontSize: '30px',
    fontWeight:'normal',
    paddingBottom:'2%',
    color:'rgba(0, 0, 0, 0.85)'
}

export const secBannerTitle = {
    fontFamily: 'DFPKingGothicGB',
    textAlign: "center",
    fontSize: '36px',
    fontWeight:'normal',
    paddingBottom:'3.5%'
}

export const cardTitle = {
    textAlign:'center',
    height:'80px',
    fontSize:'14px',
    color:'rgba(0, 0, 0, 0.65)'
};

export const prodFunc = {
    background: '#FAFAFA',
    padding: '40px',
    border: '3px',
    width:"100%"
};

export const prodAdva = {
    background: '#FFFFFF',
    padding: '30px',
    border: '3px',
    width:"100%",
    margin: 'auto',
    minWidth:'700px',
    paddingBottom:'9%'
};

export const recScen = {
    background: '#202530',
    padding: '30px',
    border: '3px',
    width:"100%",
    margin: 'auto',
    minWidth:'700px'
}

export const coopCase = {
    background: '#FFFFFF',
     padding: '30px',
     border: '3px',
     width:"100%",
     margin: 'auto'
}

export const coverYoushi = {
    width:'92px',
    margin:'auto',
    padding:'0px'
};

export const linkUse = {
    color:'#FC813F',
    position:'relative',
    left:'10%',
    margin:'15px'
};

export const linkUseDisable = {
    position:'relative',
    left:'10%',
    margin:'15px'
};

export const linkDetail = {
    color:'#FC813F',
    position:'relative',
    left:'30%',
    margin:'15px'
};

export const linkDetailDisable = {
    position:'relative',
    left:'30%',
    margin:'15px'
};

export const cardImgFunc = {
    width:'100%',
    position : 'relative',
    textAlign:'center',
    verticalAlign:'middle',
    padding:'10px'
}

export const cardImg = {
    width: '100%',
    maxWidth: '200px',
    height:'300px',
    textAlign:'center',
    verticalAlign:'middle'
}

export const cardCover = {
    width:'70%',
    margin:'auto',
}

export const cardCoverFunc = {
    width:'50%',
    margin:'auto',
}

export const comImageCover = {
    backgroundImage:`url(${comImage})`,
    backgroundPosition:'center',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    margin: 'auto',
    width:'100%',
    padding: '30px 20px'
}

export const comDesc = {
    color: '#EDEDED',
    fontFamily: 'MicrosoftYaHei',
    fontSize: '12px'
}

export const cooperCase = {
    width: '200px',
    height: '100px',
    backgroundPosition:'center',
    backgroundSize:'100%',
    backgroundRepeat:'no-repeat',
}
