import React, { Component } from 'react';
import {
	Form, Input, message, notification, Select, Button,
} from 'antd';

import axios from 'axios';
import scrollTop from '../../utils/scrollTop'
import success from '../../img/提交成功@2x.png'
import close from '../../img/close@2x.png'
import { BASE_URL } from '../../const.js';
import './support.css'
const Option = Select.Option;
const TextArea = Input;

const itemStyle = {
	marginBottom: '40px'
}

class SupportFrom extends Component {

	state = {
		isShow: false,
		open: false,
		tradeOpen: false
	}

	validatePhone = (rule, value, callback) => {
		if (!value) {
			callback('必填字段')
		}
		var mobile = /^1[0-9]{10}$/, phone = /^0\d{2,3}-?\d{7,8}$/;
		if (!phone.test(value) && !mobile.test(value)) {
			callback("请输入正确的联系电话");
		} else {
			callback();
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		var self = this
		this.props.form.validateFields((err, values) => {
			if (!err) {
				// console.log('Received values of form: ', values);
				axios.post(BASE_URL + '/support/coopandconsult',
					values
				).then(function (response) {
					// console.log(response);
					if (response.status === 200) {
						self.setState({ isShow: true })
						self.props.form.resetFields()
					}

				})
					.catch(function (error) {
						// console.log(error);
						self.props.form.resetFields()
					});
			}
		});
	}

	componentWillMount() {
		scrollTop()
	}

	 /* handleMouseLeave = () => {
			 var el = document.querySelector('.ant-select-dropdown')
			 if (el) {
					el.addEventListener('mouseleave', (e) => {
						console.log('open', el)
						this.setState({open: false})
					})
			 }
	 }

	 handleMouseLeaveOther = (e) => {
			var el = document.querySelector('.ant-select-dropdown-menu')
			if (el) {
				console.log('zhao dao select le ', el)
				el.addEventListener('mouseleave', () => {
					console.log('trade')
				})
			}
	 } */

	/*  handleClick = () => {
			 console.log('click')
			 this.props.form.validateFields((err, values) => {
			 })
	 } */

	render() {
		const { getFieldDecorator } = this.props.form
		const labelLayout = {
			offset: 1,
			span: 2,
		};
		const wrapperCol = {
			span: 9
		};
		const labelStyle = {
			fontFamily: 'DFPKingGothicGB',
			fontSize: '24px',
			color: '#7D7D7D',
			textAlign: 'center',
		};

		const {open, tradeOpen} = this.state

		return (

			<div style={{ position: 'relative', background: '#f9f9f9' }}>
				<div className='form-wrap'>
					<Form onSubmit={this.handleSubmit} style={{ ...labelStyle }}>
						<br />
						<Form.Item
							label="需求类型"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('type', {
								rules: [
									{ required: true, message: '请选择需求类型' },
								], initialValue: "cop"
							})(
								<Select style={{ width: 986 }}
								>
									<Option key='cop' value="cop">商务合作</Option>
									<Option key='consult' value="consult">产品使用咨询</Option>
									<Option key='accelerator' value="accelerator">产学研加速器</Option>
									<Option key='ai-accelerator' value="ai-accelerator">AI加速器</Option>
									<Option key='other' value="other">其他</Option>
								</Select>
							)}
						</Form.Item>
						<Form.Item
							label="需求描述"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('req-desc', {
								rules: [{
									required: true, message: '必填字段',
								}],
							})(
								<Input.TextArea placeholder="便于我们安排专业人员与您对接" style={{ width: 986 }} autosize={{ minRows: 3, maxRows: 3 }} />
							)}
						</Form.Item>
						<Form.Item
							label="企业名称"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('coop-name', {
								rules: [{
									required: true, message: '必填字段',
								}],
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item
							label="业务描述"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('business', {
								rules: [{
									required: false, message: '方便了解您的使用场景',
								}],
							})(
								<Input.TextArea placeholder="方便了解您的使用场景" autosize={{ minRows: 3, maxRows: 6 }} />
							)}
						</Form.Item>
						<Form.Item
							label="所属行业"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('industry', {
								rules: [
									{ required: true, message: '请选择行业类型' },
								], initialValue: "it"
							})(
								<Select placeholder="请选择行业类型"
								>
									<Option key='it' value="it">互联网行业（IT、电子、互联网）</Option>
									<Option key='finance' value="finance">金融行业（银行、保险、基金、证券等）</Option>
									<Option key='construction' value="construction">房地产/建筑业（建筑、装潢、物业等）</Option>
									<Option key='business' value="business">商业服务（广告、中介、外包、质检等）</Option>
									<Option key='trade' value="trade">贸易零售（进出口、租赁等）</Option>
									<Option key='comm' value="comm">通讯行业（电信服务、通讯设备等）</Option>
									<Option key='service' value="service">服务业（酒店、餐饮、旅游、度假等）</Option>
									<Option key='cultural-media' value="cultural-media">文化传媒（媒体、出版、娱乐等）</Option>
									<Option key='machining' value="machining">生产加工制造（印刷、医药、包装等）</Option>
									<Option key='logistics' value="logistics">物流行业（交通、运输、物流仓储等）</Option>
									<Option key='energy' value="energy">能源环保（矿产、石油、电气、水利等）</Option>
									<Option key='policy' value="policy">政府机构</Option>
									<Option key='agriculture' value="agriculture">农林牧渔</Option>
									<Option key='other' value="other">其他</Option>
								</Select>
							)}
						</Form.Item>
						<Form.Item
							label="联系人姓名"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('name', {
								rules: [{
									required: true, message: '必填字段',
								}],
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item
							label="联系人电话"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('phone', {
								rules: [{
									validator: this.validatePhone,
									required: true
								}],
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item
							label="联系人邮箱"
							labelCol={{ ...labelLayout }}
							wrapperCol={{ ...wrapperCol }}
							style={{ ...itemStyle }}
						>
							{getFieldDecorator('email', {
								rules: [{
									type: 'email', message: '请输入正确的邮箱格式!',
								}, {
									required: true, message: '必填字段',
								}],
							})(
								<Input />
							)}
						</Form.Item>
						<Form.Item
							wrapperCol={{ span: 5, offset: 10 }}
						>
							<Button style={{
								backgroundColor: '#19387B',
								fontFamily: 'DFPKingGothicGB', fontSize: '14px',
								width: 120, height: 40,
								color: '#FFFFFF', boxShadow: 'none', border: 'none', textShadow: 'none'
							}} type="primary" htmlType="submit"
								onClick={this.handleClick}
								onMouseEnter={(e) => e.target.style.background = '#132E69'}
								onMouseLeave={(e) => e.target.style.background = '#19387B'}
							>
								提交
                    </Button>
						</Form.Item>
					</Form>
				</div>

				{
					this.state.isShow ?
						<div style={{
							width: '100vw', height: 914,
							background: 'rgba(0,0,0,0.6)', position: 'absolute', top: 0, left: 0
						}}>
							<div className='s-mask'>
								<img alt='' src={success} style={{ width: 52, height: 52, marginTop: 66 }} />
								<div className='sumbit'>提交成功</div>
								<span style={{ fontSize: 14, marginBottom: 22 }}>工作人员会尽快与您沟通对接</span>
								<Button type='primary' onClick={() => this.setState({ isShow: false })}>好的</Button>
								<img className='close' alt='' src={close} onClick={() => this.setState({ isShow: false })} />
							</div>
						</div>
						: null
				}



				<style jsx>{`
                    .form-wrap {
                        width: 1200px;
                        margin: 0 auto;
                        padding-bottom: 100px;
                    }

                    .s-mask {
                        position: fixed;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 500px;
                        height: 302px;
                        background: #fff;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-radius: 10px;
                        border: 1px solid #f9f9f9;
                    }

                    .close {
                        position: absolute;
                        top: 22px;
                        right: 22px;
                        width: 10px;
                        height: 10px;
                        cursor: pointer
                    }

                    .sumbit {
                        font-family: PingFangSC-Semibold;
                        font-size: 20px;
                        color: #333333;
                        margin: 28px 0 6px 0;
                    }
                `}</style>
			</div>
		);
	}
}

export default Form.create()(SupportFrom);
