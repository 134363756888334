import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'

import './nav-header.css'

const navHeaderData = require('@/data/navHeaderData.json')

class NavHeader extends Component {
  render() {
    return (
      <div className="nav-header-wrap">
        <div className="nav-header-inner">
          <div className="nav-logo" onClick={() => this.props.history.replace('/')}></div>
          <div className="nav-navigation">
            <div className="nav-navigation-item">
              <span className="nav-item-text">产品服务</span>
              <i className="icon iconfont iconzhankaidown"></i>
              <div className="server-wrap">
                <HoverTable tableData={navHeaderData[0]} tableStyle={{width: '100vw', left: 0}}></HoverTable>
              </div>
            </div>
            <div className="nav-navigation-item">
              <span className="nav-item-text">解决方案</span>
              <i className="icon iconfont iconzhankaidown"></i>
              {/*<div className="nav-table table-solve">
              <div className="table-model">
                <div className="table-model-title">专项方案</div>
                <div className="table-class">
                  <div className="table-model-list">
                    <div className="model-list-item">
                      <div className="model-item-title">智能汽车</div>
                      <div className="title-list">
                        <div className="title-list-name">
                          <span className='list-name'>智能驾驶</span>
                        </div>
                        <div className="title-list-name">
                          <span className='list-name'>智能交互</span>
                        </div>
                      </div>
                    </div>
                    <div className="model-list-item">
                      <div className="model-item-title">智能汽车</div>
                      <div className="title-list">
                        <div className="title-list-name">
                          <span className='list-name'>智能驾驶</span>
                        </div>
                        <div className="title-list-name">
                          <span className='list-name'>智能交互</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
              <div className="solove-top">
                <HoverTable tableData={navHeaderData[1]} tableStyle={{width: 380, left: -12}}></HoverTable>
              </div>
            </div>
            <div className="nav-navigation-item">
              <span className="nav-item-text">生态合作</span>
              <i className="icon iconfont iconzhankaidown"></i>
              <div className="ecological">
                <HoverTable tableData={navHeaderData[2]} tableStyle={{ width: 180, left: -12}}></HoverTable>
              </div>
            </div>
            <div className="nav-navigation-item">
              <Link className="nav-item-text" to={'/labs'}>AI Labs</Link>
            </div>
            <div className="nav-navigation-item">
              <Link className="nav-item-text" to={'/cooperation'}>科技资讯</Link>
            </div>
            <div className="nav-navigation-item">
              <Link className="nav-item-text" to={'/resources'}>资源中心</Link>
            </div>
            <span className="nav-button-span">
              <Link to='/support' className="nav-button">合作咨询</Link>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

// 弹出表格的内容
class HoverTable extends Component {
  state = {
    isNavShow: false
  }
  handleCloseNav = (path) => {
    if (path !== '/404') {
      this.setState({
        isNavShow: true
      })
    }
  }

  render() {
    const {tableData, tableStyle} = this.props
    const {isNavShow} = this.state
    return (
      <div className={`nav-table ${isNavShow ? 'is-nav-show' : ''}`} style={{...tableStyle, zIndex: 2000}}
           onMouseLeave={() => this.setState({isNavShow: false})}>
        {
          tableData.classModel ? (
            tableData.classModel.map((item, index) => (
              <div className="table-model" key={index}>
                <div className="table-model-title">{item.title}</div>
                <div className="table-class">
                  {
                    item.children.map((claModItem, index) => (
                      <div className="table-model-list" key={index}>
                        {
                          claModItem.tableClass.map((modelList, index) => (
                            <div className="model-list-item" key={index}>
                              <Link to={modelList.path} className={`model-item-title ${modelList.name ? '' : 'margin-bottom2'}
                              ${modelList.path === '/404' ? 'no-pointer' : ''}`}
                                    onClick={() => {this.handleCloseNav(modelList.path)}}
                              >{modelList.name}</Link>
                              <div className="title-list">
                                {
                                  modelList.list ? (
                                    modelList.list.map((list, index) => (
                                      <div className={`title-list-name ${list.path === '/404' ? 'no-pointer' : ''}`} key={index}
                                           onClick={() => {this.handleCloseNav(list.path)}}>
                                        <Link to={list.path} className='list-name'>{list.text}
                                          {
                                            list.isHot ? (
                                              <span className="nav-hot">热门</span>
                                            ) : ''
                                          }
                                          {
                                            list.isDisable ? (
                                              <span className="nav-disable">敬请期待</span>
                                            ) : ''
                                          }
                                        </Link>
                                      </div>
                                    ))
                                  ) : ''
                                }
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          ) : (
            <div className="table-model">
              <div className="table-model-list">
                {
                  tableData.children.map((modelList, index) => (
                    <div className="model-list-item" key={index}>
                      <Link to={modelList.path} className={`model-item-title ${modelList.path === '/404' ? 'no-pointer' : ''}`}
                            onClick={() => {this.handleCloseNav(modelList.path)}}
                      >{modelList.name}</Link>
                      <div className="title-list">
                        {
                          modelList.list ? (
                            modelList.list.map((list, index) => (
                              <div className={`title-list-name ${list.path === '/404' ? 'no-pointer' : ''}`} key={index}
                                   onClick={() => {this.handleCloseNav(list.path)}}>
                                <Link to={list.path} className='list-name'>{list.text}</Link>
                              </div>
                            ))
                          ) : ''
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default withRouter(NavHeader)
