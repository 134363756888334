import React, {Component} from 'react';
import {Layout, Menu, Breadcrumb} from 'antd';
import routes from './data/routes'

import './common/reset.css'


import FooterMsg from '@/component/footer-msg/footer-msg.jsx'
import NavHeader from '@/component/nav-header/nav-header.jsx'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter
} from 'react-router-dom'
import Car from './pages/face/Car'
import Review from './pages/face/Review'
import Body from './pages/face/Body'
import SubCooper from './pages/tech-cooperation/SubCooper.jsx'
import Cooperation from './pages/tech-cooperation/Cooperation.jsx'
import SubNLP from './pages/NLP/subNLP'
import SpecialPlan from './pages/solution/SpecialPlan'
import Map from './pages/time-data/Map'
import Detection from './pages/face/Detection'
import Character from './pages/voice/character'
import Robot from './pages/NLP/robot'
import Translation from './pages/NLP/translation'
import Knowledge from './pages/NLP/knowledge'
import NotMatch from './pages/404/notMatch'
import VoicePrint from './pages/voice/voiceprint'
import Signal from './pages/voice/signal'
import FaceAnaly from './pages/face/face_analy'
import Support from './pages/support'
import {Row, Col} from 'antd';
import logo from './img/logo.png';
import './static/css/main.css'

import navData from './data/mockNav'
import solutionCase from './data/solutionCase'
import VoiceCompose from './pages/voice/voicecompose';

require('./main.css');


const MenuItemGroup = Menu.ItemGroup;
const SubMenu = Menu.SubMenu;


const {Header, Content, Footer} = Layout;

const headerStyle = {
  fontFamily: 'DFPKingGothicGB',
  fontSize: '16px',
  color: '#FFFFFF',
  textAlign: 'center',
}

const menuItemStyle = {
  position: 'fixed',
  top: '64px',
  left: '18%',
  zIndex: 10,
  width: '800px',
  height: '600px',
  backgroundColor: '#3D3F4A',
  opacity: 1,
}
let screen = 0

class Main extends Component {
  constructor() {
    super();
    this.state = {
      isShowNav: false,
      actived: false,
      navData,      // 产品服务数据
      showId: 0,    // 产品服务分类 id
      solutionCase: solutionCase,    // 解决方案数据
      isCooperation: false,
      isPublic: false,
      isToTop: false,
      isShowFooter: true,
    };
  }

  componentDidMount() {
    screen = window.screen.height
    this.showFooter()
    var _this = this
    window.onscroll = function () {
      if (window.scrollY >= screen) {
        _this.setState({
          isToTop: true
        })
      } else {
        _this.setState({
          isToTop: false
        })
      }
    }
    this.props.history.listen(() => {
      window.scrollTo(0, 0)
      this.showFooter()
    })
  }

  handleToTop = () => {
    window.scrollTo(0, 0);
  }

  handleMouseEnter = (showID) => {
    this.setState({
      isShowNav: showID
    })
  }

  switchName = (index) => {
    this.setState({
      showId: index
    })
  }

  // 判断是否显示底部导航栏
  showFooter = () => {
    const pathname = this.props.history.location.pathname
    if (pathname === '/labs') {
      this.setState({
        isShowFooter: false
      })
    } else {
      this.setState({
        isShowFooter: true
      })
    }
  }

  render() {

    const {productItemList, navData, showId, solutionCase, isShowNav, actived, isShowFooter} = this.state

    return (
      <div>

        <Layout className="layout" id='layout-top' style={{width: '100%', overflowX: 'hidden'}}>
          <div className="side-nabigator">
            <div className="cooperation-icon" onMouseEnter={() => {
              this.setState({isCooperation: true})
            }}
                 onMouseLeave={() => {
                   this.setState({isCooperation: false})
                 }}>
              {
                this.state.isCooperation ? (
                  <div className="cooperation-text" onClick={() => {
                    this.props.history.push('/support')
                  }}>合作咨询</div>
                ) : (
                  <img src={require('./img/ICON/coorperation.png')} alt="合作咨询"></img>
                )
              }
            </div>
            <div className="public-icon" onMouseEnter={() => {
              this.setState({isPublic: true})
            }}
                 onMouseLeave={() => {
                   this.setState({isPublic: false})
                 }}>
              {
                this.state.isPublic ? (
                  <div>
                    <div className="cooperation-text">AI公众号</div>
                    <div className="public-wrap">
                      <div className="triangle-text">关注AI公众号</div>
                      <div className="triangle-img"></div>
                      <div className="triangle"></div>
                    </div>
                  </div>
                ) : (
                  <img src={require('./img/ICON/ai-public.png')} alt="公众号"/>
                )
              }
            </div>
            {
              this.state.isToTop ? (
                <div className="toTop" onClick={this.handleToTop}>
                  <i className="icon iconfont iconfanhuishouye"></i>
                </div>
              ) : ('')
            }
          </div>
          <NavHeader></NavHeader>
          <Content style={{padding: '50px 0px 0', fontFamily: 'DFPKingGothicGB'}}>
            <Breadcrumb style={{margin: '0px 0'}}>
            </Breadcrumb>
            <div style={{overflow: 'hidden'}}>
              <Switch>
                {
                  routes.map((route, index) => (
                    <Route path={route.path} exact component={route.component} key={index}/>
                  ))
                }
                <Route path='/car' exact component={Car}/>
                <Route path='/review' exact component={Review}/>
                <Route path='/body' exact component={Body}/>
                <Route path='/cooperation/detail' component={SubCooper}/>
                <Route path='/cooperation' exact component={Cooperation}/>
                <Route path='/NLP/:id' exact component={SubNLP}/>
                <Route path='/solution/lineplanning' component={SpecialPlan}/>
                <Route path='/intelmap' component={Map}/>
                <Route path='/character/:id' exact component={Detection}/>
                <Route path='/body/:id' exact component={Detection}/>
                <Route path='/review/:id' exact component={Detection}/>
                <Route path='/car/:id' exact component={Detection}/>
                <Route path='/character' component={Character}/>
                <Route path='/knowledge' component={Knowledge}/>
                <Route path='/robot' component={Robot}/>
                <Route path='/translation' component={Translation}/>
                <Route path='/voiceprint' component={VoicePrint}/>
                <Route path='/voicesignal' component={Signal}/>
                <Route path="/support" component={Support}/>
                <Route component={NotMatch}/>
              </Switch>
            </div>
            {
              isShowFooter ? (<FooterMsg></FooterMsg>) : null
            }
          </Content>

        </Layout>


        <style jsx>{`
        .layout{
          width: 100%;
          overflow-x: hidden;
        }
					.selectActive {
						color: #f77b4c;
					}

					.solution_case {
						position: fixed;
						left: 23vw;
						top: 64px;
						z-index: 10;
						width: 65rem;
						height: 40rem;
						background: #41434D;
					}
					.solution_case .caseList {
						width:100%;
						height: 100%;
						padding: 2rem 0 0 4rem;
					}
					.solution_case .caseList .caseItem {
						margin-bottom: 10px;
					}
					.solution_case .caseList .caseItem>p {
						margin-bottom: 1.1rem;
						font-size: 15px;
						color: #6c6e7a;
					}
					.solution_case .caseList .caseItem>ul {
						display: flex;
						flex-wrap: wrap;
						padding-left: 0px;
					}
					.solution_case .caseList .caseItem>ul li{
						width: 120px;
						height: 20px;
						margin-bottom: 1rem;
						margin-right: 1.3rem;
						font-size: 14px;
						color: #f6f6f6;
						cursor: pointer;
					}
					.solution_case .caseList .caseItem>ul li:hover {
						color: #f77b4c;
					}
					.pro-active:hover {
						color: #f77b4c !important;
					}

					.cooper-partner {
						position: fixed;
						left: 23vw;
						top: 64px;
						z-index: 10;
						padding-bottom: 30px;
						width: 65rem;
						background: #41434D;
						padding: 2rem 0 0 4rem;
					}

					.cooper-item>p {
						margin-bottom: 1.1rem;
						font-size: 15px;
						color: #6c6e7a;
					}

					.cooper-item .find-partners-nav{
						color: #f6f6f6;
						cursor: pointer;
					}

					.cooper-item>ul {
						display: flex;
						flex-wrap: wrap;
						padding-left: 0px;
					}

					.cooper-item>ul li {
						width: 120px;
						height: 20px;
						margin-bottom: 1rem;
						margin-right: 1.3rem;
						font-size: 14px;
						color: #f6f6f6;
						cursor: pointer;
					}

					.cooper-item>ul li:hover {
						color: #f77b4c;
					}

					.develop-resource {
						position: fixed;
						left: 28vw;
						top: 64px;
						z-index: 10;
						width: 45rem;
						height: 180px;
						background: #41434D;
						padding: 2rem 0 0 4rem;
					}

					.resource-item {
						font-size: 15px;
						color: #f6f6f6;
						margin-bottom: 25px;
					}

				`}</style>

      </div>
    );
  }
}

export default withRouter(Main);
