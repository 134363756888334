import React, { Component } from 'react'
import scrollTop from '../../utils/scrollTop'

import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import Footer from '../../component/footer/footer'
import renlian from '../../img/renlianshuxing.jpg'
import jishulingxian from '../../img/ICON/技术领先.png'
import kuaisu from '../../img/ICON/查询快速.png'
import anquanbaozhang from '../../img/ICON/多维安全保障.png'
import zhinengchezai from '../../img/智能车载.jpg'

export default class SpecialPlan extends Component {

  constructor(props) {
    super(props)
    this.state = {
      advList: [
        {
          title: '技术领先',
          desc: '基于滴滴海量真实行驶数据，辅助模型训练及验证，准召率高达90%以上，能精准识别驾驶风险，提供有力的监管干预手段',
          icon: jishulingxian
        },
        {
          title: '识别速度快',
          desc: '驾驶员行为触发检测条件后，将迅速做出预警反馈并同步管理员，识别速度可达秒级，有助于避免因延迟带来的漏检及安全问题',
          icon: kuaisu
        },
        {
          title: '多维度安全保障',
          desc: '采用端云及数据平台一体化的布局，从驾驶行为、驾驶员生理特征、车辆传感器、路况、环境信息等多维度获取数据并分析数据，助力行为预测、司机教育、实时干预及自动驾驶接管',
          icon: anquanbaozhang
        },
      ]
    }
  }

  componentWillMount() {
    scrollTop()
  }

  render() {
    return (
      <div>
        <Header headerImg={zhinengchezai}
          title={'路线规划解决方案'}
          desc={'提供多车辆、多目标的路线规划和优化服务，适用于网约车合乘、物流零担等场景。得益于滴滴AI地图业界领先的路线规划、行程时间预估算法，优化结果更加准确可靠'}
          plate={1}
        />

        <div className='framework_wrap'>
          <div className='framework'>
            <div className='f_title'>方案架构</div>
            <div className='img'></div>
          </div>
        </div>

        <div className='recommendScene_wrap'>
          <div className='recommendScene'>
            <h3>推荐场景</h3>
            <div className='sceneList'>
              <div className='sceneItem' style={{ background: '#000' }}>
                <span>拼车合乘</span>
                <div className='mark'></div>
                <p>在网约车或顺风车等场景中，乘客的出行需求包含大量可以合并的行程路线。在保证用户体验的同时，合理的拼车合乘可以有效提升城市交通效率，提升司机和网约车平台的经济收益</p>
              </div>
              <div className='sceneItem'>
                <span>物流零担</span>
                <div className='mark'></div>
                <p>“零担”即是货物合乘，可有效提升货运效率。滴滴在人员合乘上沉淀的算法优势和地图领先的准确性保证了零担路线更加合理和可靠</p>
              </div>
              <div className='sceneItem' style={{ background: '#000' }}>
                <span>外卖配送</span>
                <div className='mark'></div>
                <p>外卖行业对时效性非常敏感，滴滴数据和算法的双重优势保证了行程时间预估的高准确性和高可靠性，提升配送效率和用户体验</p>
              </div>
            </div>
          </div>
        </div>

        <div className='cooperCase_wrap'>
          <div className='cooperCase'>
            <h3>合作案例</h3>
            <div className='cooperCaseList'>
              <div className='cooperCaseItem'>
                <div className='img'></div>
                <div className='cooperDesc'>
                  <span>滴滴网约车</span>
                  <p>滴滴路线规划调用超200亿次/天，行程时间预估（ETA）调用超70亿次/天。网约车派单是高用量、高并发使用地图服务的典型场景，耗费大量机器资源。基于滴滴AI地图的车辆路线规划，得益于高性能的地图服务，可缩减超过90%计算资源，节约可观的服务器成本</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProductAdv advList={this.state.advList} />

        <style jsx>{`

          .framework_wrap {
            width: 100%;
            height: 880px;
            background: #f5f5f5;
          }
          .framework {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 100px 0;
          }
          .f_title {
            width: 152px;
            height: 53px;
            font-size: 38px;
            color: #333;
            text-align: center;
            margin: 0 auto;
          }
          .framework .img {
            width: 1200px;
            height: 580px;
            background: #93f086;
            margin-top: 54px;
          }


          .recommendScene_wrap {
              width: 100%;
              height: 780px;
              background: #202530;
          }
          .recommendScene {
              width: 1200px;
              height: 100%;
              margin: 0 auto;
              padding: 94px 0 100px 0;
          }
          .recommendScene>h3 {
              width: 800px;
              height: 53px;
              text-align: center;
              color: #fff;
              font-size: 38px;
              margin: 0 auto;
              margin-bottom: 53px;
          }
          .recommendScene .sceneList {
              display: flex;
              justify-content: space-between;
              width: 1200px;
          }

          .recommendScene .sceneList .sceneItem {
              width: 380px;
              height: 480px;
              padding: 60px 0 0 40px;
              background: #474747;
          }
          .recommendScene .sceneList .sceneItem>span{
              display: block;
              width: 221px;
              height: 30px;
              color: #fff;
              font-size: 22px;
          }
          .recommendScene .sceneList .sceneItem >.mark {
              width: 40px;
              height: 1px;
              color: #F37E43;
              border: 3px solid #F37E43;
              margin: 27px 0 20px 0;
          }
          .recommendScene .sceneList .sceneItem>p {
              width: 300px;
              height: 100px;
              font-size: 14px;
              line-height: 20px;
              color:#ededed;
          }
          .cooperCase_wrap {
            width: 100%;
            background: #f5f5f5;
          }
          .cooperCase {
            width: 1200px;
            margin: 0 auto;
            padding: 95px 0 70px 0;
          }
          .cooperCase>h3 {
            width: 152px;
            height: 53px;
            font-size: 38px;
            color: #333;
            text-align: center;
            margin: 0 auto;
          }
          .cooperCaseList {
            margin-top: 54px;
            width: 1200px;
          }
          .cooperCaseItem {
            display: flex;
            width: 1200px;
            height: 262px;
            background: #fff;
            margin-bottom: 30px;
          }
          .cooperCaseItem .img {
            width: 384px;
            height: 262px;
            background: #93f086
          }
          .cooperDesc {
            width: 816px;
            height: 262px;
            padding: 57px 0 0 90px;
          }
          .cooperDesc>span {
            font-size: 20px;
            color: #333;
          }
          .cooperDesc>p {
            margin-top: 20px;
            width: 637px;
            height: 130px;
            font-size: 14px;
            color: #747578;
            line-height: 20px;
          }
        `}</style>
      </div>
    )
  }
}
