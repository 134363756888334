import React, { Component } from 'react'
import axios from 'axios'
import { fabric } from 'fabric'
import $ from 'jquery'
import {
  Button,
  Upload,
  message,
  Input,
  notification,
  Collapse,
  Spin,
  Icon
} from 'antd'
import scanCanvas from '../../utils/scanCanvas.js'

import id4 from './assets/driver/驾驶证01.png'
import id2 from './assets/driver/驾驶证02.png'
import id3 from './assets/driver/驾驶证03.png'
import id1 from './assets/driver/驾驶证04.png'
import { getData } from '../../utils/config'
import head from './assets/head.png'
import scan from './assets/scan.png'


const Search = Input.Search
const Panel = Collapse.Panel
const BASE_URL = 'http://ai.intra.xiaojukeji.com'

const imgData = [
  {
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_gabgUphcDa7ydSVzREtn',
    name: id1
  },
  {
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_HE5HDxKV5Guu6D5XcmYg',
    name: id2
  },
  {
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_RgmPMlyjBPinRvLExeh5',
    name: id3
  },
  {
    url: 'http://img-ys011.didistatic.com/static/aiopen/do1_MshNslp71mUrJLYQLqKl',
    name: id4
  }
]

export default class Driver extends Component {

  state = {
    index: 1,
    id: '5134331969022315X',
    name: '嘀嘀',
    country: '中国',
    gender: '男',
    address: '北京市海淀区中关村科技园高兴路1号',
    birthday: '1969-02-23',
    type: 'C1',
    issueDate: '1980-01-07',
    duration: '有效期限:1996-01-07 至 1990-01-07',
    sealText: '河南省商丘市公安局交通警察支队',
    loading: false,
    responseJson: `{
      "address": "北京市海淀区中关村科技园高兴路1号",
      "birthday": "1969-02-23",
      "class": "61年",
      "country": "中国",
      "id": "5134331969022315X",
      "issueDate": "1980-01-07",
      "name": "喻响",
      "seal_text": "河南省商丘市公安局交通警察支队",
      "sex": "男",
      "validFor": "1996-01-07",
      "validFrom": "1990-01-07"
    }`
  }

  // 初始化图片墙的样式
  initButtonStyle = () => {
    $('.ant-input').css('box-shadow', 'none')
    $(".ant-input-search-button").css("background-color", "#19387B")
    $(".ant-input-search-button").css("box-shadow", 'none');
    $(".ant-input-search-button").css("text-shadow", 'none');
    $('.small-img:first').css('border-color', "#19387B")
    $('.ant-collapse').css('border-radius', 0)
    const btn = document.querySelector('.ant-input-search-button')
    btn.addEventListener('mouseenter', function() {
      btn.style.background = '#132E69'
    })
    btn.addEventListener('mouseleave', function() {
      btn.style.background = '#19387B'
    })
  }

  initCanvas = () => {
    const canvas = new fabric.Canvas('canvas')
    this.canvas = canvas
  }

  // 在 canvas 上把图画出来
  drawImage = (imgUrl, type) => {
    if (!imgUrl) {
      imgUrl = id1
    }
    const objs = this.canvas.getObjects()
    objs.forEach(item => this.canvas.remove(item))
    const self = this
    fabric.Image.fromURL(imgUrl, function (oImg) {
      const center = self.canvas.getCenter()
      const scaleX = self.canvas.getWidth() / oImg.width
      const scaleY = self.canvas.getHeight() / oImg.height
      console.log(oImg)
      const scaleValue = scaleX > scaleY ? scaleY : scaleX
      oImg.set({
          originX: 'center',
          originY: 'center',
          left: center.left,
          top: center.top,
        })
      if (type === 2 || type === 3) {
        oImg.set({
          scaleX: scaleValue,
          scaleY: scaleValue
        })
      }
      self.canvas.add(oImg)
    })

    scanCanvas(scan, this.canvas)
  }

  handleClick = (e, index) => {
    if (this.state.index === index) {
      return
    }
    this.setState({ loading: true, index })
    const el = e.target
    const url = imgData[index-1].name
    $('.small-img').css('border-color', 'rgba(0,0,0,0)')
    $(el).css('border-color', "#19387B")
    this.drawImage(url, 1)
    this.postRequest(imgData[index-1].url)
  }

  handleUpload = ({ file, fileList, event }) => {
    console.log(file.status)
    if (file.status === 'error') {
      message.error('上传失败')
    } else if (file.status === 'done') {
      console.log(file.response.data.url)
      const imgUrl = file.response.data.url
      this.drawImage(imgUrl, 2)
      this.postRequest(imgUrl)
      message.success('图片上传成功')
    }

    $('.ant-upload-list').hide()
  }

  handleDetect = (value, event) => {
    if (!value) {
      return 
    }
    this.drawImage(value)
    this.postRequest(value, 3)
  }

  postRequest = (url) => {
    axios.post(BASE_URL + '/ocr/drvles', {
      url: url,
      type: 1
    })
      .then((res) => {
        if (res.status === 200) {
          const result = getData(res).data
          console.log(result)
          const { id, name, sex, country, seal_text, address, issueDate, birthday, validFor, validFrom } = result
          const duration = `有效期限:${validFrom} 至 ${validFor}`
          this.setState({
            id,
            name,
            gender: sex,
            country,
            address,
            sealText: seal_text,
            issueDate,
            birthday,
            duration,
            loading: false,
            responseJson: JSON.stringify(result, null, 4)
          })
        } else {
          message.error('服务繁忙，请稍后尝试')
        }
      })
  }

  componentDidMount() {
    this.initButtonStyle()
    this.initCanvas()
    this.drawImage()
  }

  render() {

    const { id, name, gender, country, birthday, address, duration, issueDate, type, sealText, loading, responseJson } = this.state
    // const responseJson = JSON.stringify(this.state.responseJson)
    return (
      <div>
        <div className='func-show'>
          <h3>功能演示</h3>
          <div className='show-container'>
            <div className='container-left'>
              <div className='big-img'>
                <div className='icon'>原始图片</div>
                <canvas id='canvas' width='800' height='448'></canvas>
              </div>
              <div className='mask'></div>
              <div className='thumbnails'>
                {
                  imgData.map((item, index) => (
                    <div className='small-img' 
                      style={{width: 159, height: 94, backgroundImage: `url(${item.name})`}} 
                      key={index}
                      onClick={(e) => this.handleClick(e, index + 1)} key={index} 
                    >
                    </div>
                  ))
                }
              </div>
              <div className='upload'>
                <Search
                  onFocus={() => $('.ant-input').css('border', '1px solid rgba(25,56,123,0.6)')}
                  onBlur={() => $('.ant-input').css('border', '1px solid #d9d9d9')}
                  placeholder="请输入网络图片URL"
                  style={{ width: 552 }}
                  enterButton="检测"
                  size="large"
                  onSearch={this.handleDetect}
                />
                <span style={{ marginTop: 12 }}>或</span>
                <Upload
                  accept='image/*'
                  action={BASE_URL + '/ocr/upload'}
                  onChange={this.handleUpload}
                  name='upfile'
                >
                  <Button className='btn'
                    onMouseEnter={(e) => e.target.style.background='#132E69'}
                    onMouseLeave={(e) => e.target.style.background='#19387B'}
                    style={{ width: 110, height: 40, backgroundColor: '#19387B', color: '#fff', boxShadow: 'none', border: 'none', textShadow: 'none' }}
                  >本地上传</Button>
                </Upload>
                <p className='msg'>图片类型支持PNG、JPG图片，图片大小不超过2M</p>
              </div>
            </div>
            <div className='container-right'>
              {/* <div className='result-title'>识别结果
                <img src={icon_up} alt='' className='icon' />
              </div>
              <div className='recognition-result'>

              </div>
              <div className='res-json'>Response Json
                <img src={icon_down} alt='' className='icon' />
              </div> */}
              <Collapse
                border={true}
                accordion={true}
                expandIconPosition='right'
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <Icon type="down" rotate={isActive ? 180 : 0} />}
              >
                <Panel
                  style={{background: '#fff'}}
                  header={<span className='result-title'>识别结果</span>} key="1"
                >
                  <div style={{ width: 384, height: 512 }} className='recognition-result'>
                    {
                      loading
                        ? <Spin style={{ marginLeft: '40%' }} />
                        : <div>
                          <p>中华人民共和国机动车行驶证</p>
                          <p>证号：{id}</p>
                          <p>姓名：{name}</p>
                          <p>性别：{gender}</p>
                          <p>住址：{address}</p>
                          <p>国籍：{country}</p>
                          <p>出生日期：{birthday}</p>
                          <p>初次领证日期：{issueDate}</p>
                          <p>准驾车型：{type}</p>
                          <p>有效期限：{duration}</p>
                          <p>发证机关：{sealText}</p>
                        </div>
                    }
                  </div>
                </Panel>
                <Panel
                  header={<span className='res-json'>Response Json</span>} key="2"
                  style={{background: '#fff', borderRadius: 0}}
                >
                  <div style={{ width: 350, height: 512 }} >
                    <pre style={{ width: 350, height: 540 }}>{responseJson}</pre>
                  </div>
                </Panel>  

              </Collapse>
            </div>
          </div>
        </div>

        <style jsx>{`
          .func-show {
            margin: 0 auto;
            width: 1200px;
            padding: 94px 0 168px 0;
          }

          .func-show h3 {
            font-size: 32px;
            color: #333;
            text-align: center;
            margin-bottom: 53px;
          }
          
          .show-container {
            display: flex;
            width: 1200px;
            height: 688px;
            background: #fff;
          }

          .container-left {
            position: relative;
            width: 800px;
          }

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 800px;
            height: 448px;
          }

          .big-img {
            width: 800px;
            height: 448px;
            background: #ddd;
          }

          .big-img .icon {
            width: 116px;
            height: 40px;
            background-image: url(${head});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            text-align: center;
            color: #fff;
            line-height: 40px;
            margin: 0;
            position: absolute;
            z-index: 5;
          }

          .thumbnails {
            width: 800px;
            height: 130px;
            background: #666;
            display: flex;
            align-items: center;
            padding: 0 30px;
            justify-content: space-between;
          }

          .small-img {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border: 1px solid rgba(0,0,0,0);
            cursor: pointer;
          }

          .small-img>img {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(0,0,0,0);
            cursor: pointer;
          }

          .upload {
            width: 800px;
            height: 110px;
            padding: 28px 30px 12px 30px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-right: 1px solid #E8E8E8;
            border-bottom: 1px solid #E8E8E8;
          }

          .msg {
            display: block;
            width: 346px;
            height: 24px;
            font-size: 12px;
            color: rgba(0,0,0,0.25);
            line-height: 24px;
          }

          .ant-input-search-button {
            background: #19387B !important;
          }

          .container-right {
            width: 400px;
            height: 688px;
          }

          .result-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #333333;
            justify-content: flex-end;
            cursor: pointer;
            height: 46px;
          }

          .res-json {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #333333;
            justify-content: flex-end;
            cursor: pointer;
            height: 46px;
          }

          .recognition-result {
            padding: 50px 40px 0 50px;
          }

          .recognition-result p {
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            line-height: 21px;
          }

          .icon {
            width: 14px;
            height: 14px;
            margin: 0 8px 0 18px;
          }
          .ant-input-affix-wrapper .ant-input:focus {
            border: none;
            border-bottom: 1px solid white !important;
            box-shadow: none;
          }
          .ant-input-search-button:hover {
            background: #fff !important;
          }

        `}</style>
      </div>
    )
  }
}
