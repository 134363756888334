import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import './face.css'

import rentiquyu from './images/人体区域检测.jpg'
import guanjiandian from './images/人体关键点检测.jpg'
import xingrenid from './images/行人re-iD.png'
import xingrenjiance from './images/行人检测.jpg'
import shoushishibie from './images/手势识别.jpg'
import hudongyule from '../../data/人体识别/人体识别X3/互动娱乐.jpg'
import anfangzhencha from '../../data/人体识别/人体识别X3/布控侦查.jpg'
import zhihuishangye from '../../data/人体识别/人体识别X3/智慧商业.jpg'
import zhunquekekao from '../../img/ICON/保密可靠.png'
import keyongqingao from '../../img/ICON/适应性强.png'
import duozhongzhichi from '../../img/ICON/通用性强.png'
import linghuo from '../../img/ICON/个性化定制.png'

const ProductFunc = ({features}) => (
	<div>
		<div className='proFunc_wrap'>
			<div className='proFunc'>
				<h2>产品功能</h2>
				<div className='proList'>
					{
						features.map((item, index) => (
							<div className='proItem' key={index}>
								<div className='back-img-wrap' style={{ width: 380, height: 260 }}>
									<div className='back-img' style={{ width: 380, height: 260, backgroundImage: `url(${item.img})` }}></div>
								</div>
								<span style={{ fontWeight: 100 }}>{item.name}</span>
								<p>{item.desc}</p>
								<div className='select'>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to='/support'>合作咨询</Link>
									<div style={{width: 1, height: 39, background: '#e8e8e8', position: 'absolute'}}></div>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to={item.path}>了解详情</Link>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>

		<style jsx>{`
			.proFunc_wrap {
					width: 100%;
					height: 1250px;
					background: #f5f5f5;
			}
			.proFunc {
					width: 1200px;
					height: 100%;
					margin: 0 auto;
					padding: 100px 0 100px 0;
			}
			.proFunc h2 {
					width: 152px;
					height: 45px;
					text-align: center;
					margin: 0 auto;
					font-size: 38px;
					color: #333;
					margin-bottom: 60px
			}
			.proFunc .proList {
					width: 1200px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
			}
			.proFunc .proList .proItem {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 380px;
					background: #fff;
					margin-bottom: 30px;
			}

			.back-img-wrap {
				overflow: hidden;
			}

			.back-img {
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center center;
				transition: 0.4s ease;
			}

			.back-img:hover {
				transform: scale(1.1)
			}

			.proFunc .proList .proItem>span {
					display: block;
					width: 140px;
					height: 30px;
					font-weight: bold;
					font-size: 20px;
					color: #333;
					text-align: center;
					margin: 20px 0;
			}
			.proFunc .proList .proItem>p {
					width: 310px;
					height: 42px;
					font-size: 14px;
					color: #747578;
					line-height: 22px;
					margin-bottom: 30px;
			}
			.proFunc .proList .proItem .select {
					width: 380px;
					height: 65px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					border-top: 1px solid #E8E8E8;
					position: relative;
			}

		`}</style>
	</div>
)

export default class Body extends Component {

	state = {
		features: [
			{
				name: '人体关键点检测',
				desc: '定位人体骨骼关键点，描述人体的姿态',
				img: guanjiandian,
				path: '/body/keypoint'
			},
			{
				name: '行人Re-ID',
				desc: '即行人重识别技术，实现跨多个设备从图像中识别出指定的行人目标',
				img: xingrenid,
				path: '/body/Re-ID'
			},
			{
				name: '行为检测',
				desc: '结合具体场景，检测图像中人的行为动作',
				img: xingrenjiance,
				path: '/body/behavior'
			},
			{
				name: '手势识别',
				desc: '检测图像中手的位置，支持静态和动态的常见手势识别，可应用于智能汽车、智能家居产品等设备的交互控制',
				img: shoushishibie,
				path: '/body/gesture'
			}
		],

		productAdv: [
			{
				title: '准确可靠',
				desc: '技术领先，性能表现优异。服务于实际业务多个环节，识别准确度可达98%',
				icon: zhunquekekao
			},
			{
				title: '可用性高',
				desc: '对不同拍摄条件下形成的多种图像噪声具备一定的排除能力。服务成熟稳定，可支持高并发计算',
				icon: keyongqingao
			},
			{
				title: '多种支持',
				desc: '配合客户需求可提供API/SDK支持，若涉及敏感信息或数据有特殊需要，亦能提供私有化部署服务',
				icon: duozhongzhichi
			},
			{
				title: '灵活配置',
				desc: '各功能模块可根据用户实际需求自由选择，支持多模块灵活组合，为用户量身打造综合解决方案',
				icon: linghuo
			}
		],
		recommondSence: [
			{
				name: '互动娱乐',
				content: '识别人体姿态、手势，可应用到体感游戏、自动贴图、视频特效等娱乐功能中，为人机交互提供更多元化、更富有趣味性的实现方式',
				bg: hudongyule,
				icon: 'iconhudongyule'
			},
			{
				name: '智慧商业',
				content: '大型商场、无人超市等可利用人体识别技术对客流量和客户行为进行记录和分析，为商业决策提供依据',
				bg: zhihuishangye,
				icon: 'iconzhihuishangye'
			},
			{
				name: '安防侦查',
				content: '视频分析系统或安全监测平台可运用人体识别技术实现可疑人员监测、异常行为预警、目标定位追踪等功能，与人力筛查相比更高效快捷',
				bg: anfangzhencha,
				icon: 'iconanfangjiance'
			},
		],
		more: [
			{
				name: '人脸识别',
				path: '/face'
			},
			{
				name: '文字识别',
				path: '/character'
			}
		]
	}

	render() {
		return (
			<div>
				<Header headerImg={rentiquyu} title={'人体识别'} desc={'以人工智能技术高效提取图像中的人体相关特征，对人体位置、姿态、属性等进行分析'} />

				<ProductFunc features={this.state.features} />

				<ProductAdv advList={this.state.productAdv} />

				<RecommondSence recommondSence={this.state.recommondSence} />

				<MoreRecommend more={this.state.more} />

			</div>
		)
	}
}
