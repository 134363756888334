import React, { Component } from 'react'

import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import Footer from '../../component/footer/footer'
import RecommondSence from '../../component/ProService/RecommondSence'
import Features from '../../component/ProService/Features'
import jisu from '../../img/ICON/技术领先.png'
import jiqiren from './ziranyuyan/机器人平台/机器人平台.jpg'
import grzl from './ziranyuyan/机器人平台/推荐场景X3/个人助理.jpg'
import znkf from './ziranyuyan/机器人平台/推荐场景X3/智能客服.jpg'
import znqc from './ziranyuyan/机器人平台/推荐场景X3/智能汽车.jpg'
import duoqudaojieru  from '../../data/ICON一期/3.拓展性强.png'
import yiyongkekao from '../../data/ICON一期/4.保密可靠.png'
import r_robot from '../../data/语言处理技术/func-img/智能机器人.jpg'


export default class Robot extends Component {

  componentWillMount() {
    document.getElementById('root').scrollIntoView(true)
  }

  constructor(props) {
    super(props)
    this.state = {
      advList: [
        {
          title: '技术领先',
          desc: '专业团队支持，具备业界领先的对话理解和交互能力',
          icon: jisu
        },
        {
          title: '多渠道接入',
          desc: '支持微信公众号、H5网页、PC网站、API等多种方式集成接入',
          icon: duoqudaojieru
        },
        {
          title: '易用可靠',
          desc: '操作步骤简单易上手，无需代码基础也可轻松配置机器人',
          icon: yiyongkekao
        }
      ],
      recommondSence: [
				{
					name: '智能客服',
          content: '理解用户语义后，快速匹配并回复标准答案，减轻人工客服压力',
          icon: 'iconzhinengkefu',
          bg: znkf
				},
				{
					name: '个人助理',
          content: '与用户进行交互，为用户提供出行路线查询、外卖点餐等便利功能',
          icon: 'icongerenzhuli',
          bg: grzl
				},
				{
					name: '智能汽车',
          content: '结合语音、图像等AI技术，使人车交互更加便捷，车辆行驶更加安全',
          icon: 'iconzhinengqiche',
          bg: znqc
				},
      ],
      funcList: [
        {
          desc: '运用自然语言理解及深度学习算法为行业企业提供智能机器人应用平台。经由海量数据训练与实际业务验证，有效提升服务质量与效率',
          image: r_robot
        }
      ]
    }
  }

  render() {
    return (
      <div>
        <Header headerImg={jiqiren} title={'机器人平台'} desc={'采用机器学习领域先进技术，经实际业务长期使用验证，沉淀出极强的自然语言理解及处理的算法能力，适用于多种业务场景'} />

        <Features funcList={this.state.funcList} />

        <ProductAdv advList={this.state.advList}/>

        <RecommondSence recommondSence={this.state.recommondSence} />

        <div className='quickEntry_wrap'>
          <div className='quickEntry'>
            <div className='entry'>
              <span>更多推荐:</span>
              <p>语言处理技术  |  滴滴翻译</p>
            </div>
          </div>
        </div>
        <style jsx>{`
            .recommendScene_wrap {
                width: 100%;
                height: 780px;
                background: #202530;
            }
            .recommendScene {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 94px 0 100px 0;
            }
            .recommendScene>h3 {
                width: 800px;
                height: 53px;
                text-align: center;
                color: #fff;
                font-size: 38px;
                margin: 0 auto;
                margin-bottom: 53px;
            }
            .recommendScene .sceneList {
                display: flex;
                justify-content: space-between;
                width: 1200px;
            }

            .recommendScene .sceneList .sceneItem {
                width: 380px;
                height: 480px;
                padding: 60px 0 0 40px;
                background: #474747;
            }
            .recommendScene .sceneList .sceneItem>span{
                display: block;
                width: 221px;
                height: 30px;
                color: #fff;
                font-size: 22px;
            }
            .recommendScene .sceneList .sceneItem >.mark {
                width: 40px;
                height: 1px;
                color: #F37E43;
                border: 3px solid #F37E43;
                margin: 27px 0 20px 0;
            }
            .recommendScene .sceneList .sceneItem>p {
                width: 300px;
                height: 100px;
                font-size: 14px;
                line-height: 20px;
                color:#ededed;
            }

            .quickEntry_wrap {
              width: 100%;
              height: 300px;
              background: #f5f5f5
            }
            .quickEntry {
              width: 1200px;
              height: 100%;
              margin: 0 auto;
              padding: 100px 0;
            }
            .quickEntry .entry {
              width: 100%;
              height: 100px;
              background: #fff;
              padding: 36px 0 0 40px;
            }
            .quickEntry .entry>span {
              float: left;
              display: block;
              width: 99px;
              height: 28px;
              font-size: 20px;
              color: #747578;
              font-weight: bold;
              text-align: center;
            }
            .quickEntry .entry>p {
              float: left;
              color: #747578;
              font-size: 20px;
            }

            .proFunc_wrap {
                width: 100%;
                height: 2230px;
                background: #f5f5f5;
            }
            .proFunc {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                padding: 100px 0 100px 0;
            }
            .proFunc h2 {
                width: 152px;
                height: 45px;
                text-align: center;
                margin: 0 auto;
                font-size: 38px;
                color: #333;
                margin-bottom: 60px
            }
            .proFunc .proList {
                width: 1200px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .proFunc .proList .proItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 380px;
                height: 440px;
                background: #fff;
                margin-bottom: 30px;
            }
            .proFunc .proList .proItem>span {
                display: block;
                width: 140px;
                height: 30px;
                font-size: 20px;
                color: #333;
                text-align: center;
                margin: 20px 0;
            }
            .proFunc .proList .proItem>p {
                width: 310px;
                height: 42px;
                font-size: 14px;
                color: #747578;
                line-height: 22px;
                margin-bottom: 30px;
            }
            .proFunc .proList .proItem .select {
                width: 345px;
                height: 22px;
                display: flex;
                justify-content: space-around;
            }
            .proFunc .proList .proItem .select>a {
                color: #FF7F41 !important;
            }
        `}</style>

      </div>


    )
  }
}
