import React, {Component} from 'react'
import Header from '../../component/header/header.js'

import icon_time from './imgs/icon_time@2x.png'
import kejihezuo from './imgs/科技合作.jpg'
import {titleList} from './components/cooperation-co'

import Paging from '@/component/paging/paging'

import './common.css'
import lixianyanzheng from "../voice/image/useful/离线声纹验证.jpg";

const navList = [
  {
  title: '所有资讯',
  flag: 'syzx'
},{
  title: '业界观察',
  flag: 'yjgc'
},
  {
    title: '科技前沿',
    flag: 'kjqy'
  },
  {
    title: '学术交流',
    flag: 'xsjl'
  },
  {
    title: '平台动态',
    flag: 'ptdt'
  }
]
let pageList = titleList

export default class Cooperation extends Component {
  state = {
    listData: titleList,
    pageCurrent: 1,
    navActive: 'syzx'
  }

  jumpToSub = () => {
    this.props.history.push('/cooperation/detail')
  }

  componentDidMount() {
    this.formatData(1)
  }

  // 页码改变
  changeCurrent = (page) => {
    this.setState({
      pageCurrent: page
    })
    this.formatData(page)
    /*设置锚点，在DOM元素上面设置ID，使用原生js跳转window.location.href = #ID， 这时要在这个ID前面加上#*/
    window.location.href = '#listShow'
  }

  // 每页展示7条数据
  formatData = (page) => {
    let listData = pageList.slice(6 * (page - 1), 6 * page)
    this.setState({
      listData
    })
  }

  // 跳转到咨询详情页
  newTarget = (item) => {
    const path = item.path
    if (path.includes('weixin')) {
      window.open(item.path)
    } else {
      this.props.history.push(item.path)
    }
  }

  // 改变导航栏
  changeNav = (name) => {
      this.setState({
        navActive: name
      })
      if (name === 'syzx') {
        pageList = titleList
        this.setState({
          listData: titleList,
          pageCurrent: 1
        })
      } else {
        pageList = titleList.filter(item => item.flag === name)
        this.setState({
          listData: pageList,
          pageCurrent: 1
        })
      }
      this.formatData(1)
  }

  render() {
    const {listData, pageCurrent, navActive} = this.state
    return (
      <div>
        <Header headerImg={kejihezuo} title={'科技资讯'} desc={'聚焦滴滴AI科技合作最新资讯'} plate={1}/>

        <div className='cooperation-wrap'>
          <div className="cooper-inner">
            <div className="cooperation-navigation">
              {
                navList.map((item, index) => (
                  <div className={`navigation-item ${item.flag === navActive ? 'navigation-active' : ''}`} key={index}
                       onClick={() => {this.changeNav(item.flag)}}>{item.title}</div>
                ))
              }
            </div>
          </div>
          <div className='cooperation' id='listShow'>
            <div className='cooper-list'>
              {
                listData.map((item, index) => (
                  <div className='cooper-item' key={index} onClick={() => this.newTarget(item)}>
                    <div className="img" style={{backgroundImage: `url(${item.imgUrl})`}}></div>
                    <div className='info'>
                      <p>{item.title}</p>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={icon_time} alt='loading'/>
                        <div className="img" style={{backgroundImage: `url(${icon_time})`}}></div>
                        <span>{item.time}</span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="par-ai-pagination">
          <Paging total={pageList.length} current={pageCurrent} defaultPageSize={6}
                  onChange={this.changeCurrent}></Paging>
        </div>

        <style jsx>{`
        .par-ai-pagination{
        width: 100%;
          padding: 0 0 70px;
          overflow: hidden;
        }
          .cooperation-wrap {
            width: 100%;
            height: 100%;
          }
          .cooper-inner{
            width: 100%;
            height: 52px;
            box-shadow: 4px 8px 11px 0 rgba(0,0,0,0.04);
            background-size: #f0f2f5;
          }
         .cooperation-navigation{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            height: 100%;
            cursor: pointer;
         }
         .navigation-item:hover{
            background: #EAEAEA;
         }
         .navigation-item{
           height: 100%;
           line-height: 52px;
           width: 20%;
          font-size: 18px;
          color: #333333;
          text-align: center;
         }
        .navigation-active{
            color: #fff !important;
            background-color: #19387B !important;
         }
          .cooperation {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 60px 0 100px 0;
          }

          .cooper-list {
            width: 1200px;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
          }

          .cooper-item {
            width: 380px;
            height: 350px;
            background: #D8D8D8;
            margin-bottom: 30px;
            cursor: pointer;
            margin-right: 30px;
          }
          .cooper-item:nth-child(3n) {
            margin-right: 0;
          }
          .cooper-item>.img {
            width: 380px;
            height: 210px;
            background: contain;
          }

          .cooper-item .info {
            width: 380px;
            height: 140px;
            background: #fff;
            padding: 30px 0 0 22px;
          }

          .cooper-item .info>p {
            width: 342px;
            height: 42px;
            font-size: 16px;
            color: #000;
            margin-bottom: 20px;
            line-height: 20px !important;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-line-clamp:2;
            -webkit-box-orient:vertical;
          }

          .info img {
            width: 13px;
            height: 13px;
            margin-right: 6px;
          }

          .cooper-item .info span {
            font-size: 14px;
            color: #747578;
          }
          .list-more, .list-none{
            font-size: 16px;
            text-align: center;
            cursor: pointer;
          }
          .list-none{
            cursor: default;
          }
        `}</style>
      </div>


    )
  }
}
