import React, { Component } from 'react'

import chuzuche from '../img/chuzuche.png'

export default class CoopCase extends Component {
  render() {
    return (
      <div className='coopCase_wrap'>
        <div className='coopCase'>
          <h4>合作案例</h4>
          <div className='caseList'>
            <div className='caseItem'>
              <img src={chuzuche} alt='' />
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
            <div className='caseItem'>
              <img src={chuzuche} alt=''/>
            </div>
          </div>
        </div>

        <style jsx>{`
          .coopCase_wrap {
            width: 100%;
            height: 530px;
            background: #fff;
          }
          .coopCase_wrap .coopCase {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 94px 0 100px 0
          }
          .coopCase_wrap .coopCase>h4 {
            width: 152px;
            height: 53px;
            font-size: 32px;
            color:#333;
            text-align: center;
            margin: 0 auto;
          }
          .coopCase_wrap .coopCase .caseList {
            margin-top: 53px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .coopCase_wrap .coopCase .caseList .caseItem {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 278px;
            height: 100px;
            margin-bottom: 30px;
          }
          .coopCase_wrap .coopCase .caseList .caseItem>img {
            width: 200px;
            height: 80px;
          }
      `}</style>
      </div>
    )
  }
}
