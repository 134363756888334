import {fabric} from 'fabric'

/**
 * 在 canvas 图层上实现扫描效果
 * @param {*} scan  扫描的透明图
 * @param {*} canvas 
 */
const scanCanvas = (scan, canvas) => {
  fabric.Image.fromURL(scan, function(img) {
    const center = canvas.getCenter()
    img.set({
      originX: 'center',
      originY: 'center',
      left: center.left,
      top: 0,
      scaleY: 0.8,
    })
    canvas.add(img)
    img.animate('top', 300, {
      duration: 600,
      onChange: canvas.renderAll.bind(canvas),
      onComplete: function() {
        var o = canvas.getObjects()
        o.forEach(item => {
          if (item.height === 372) {
            canvas.remove(item)
          }
        })
      },
      easing: fabric.util.ease['easeInQuad']
    })
  })
}

export default scanCanvas