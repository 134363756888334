import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Card, Avatar } from 'antd';
import {
	BrowserRouter as Router,
	Route,
	Link,
	withRouter
} from 'react-router-dom'
import $ from 'jquery';
import Footer from '../../component/footer/footer'
import Header from '../../component/header/header'
import ProductAdv from '../../component/ProductAdv'
import RecommondSence from '../../component/ProService/RecommondSence'
import MoreRecommend from '../../component/ProService/more-recommend.jsx'
import '../face/face.css'

import shibiedu from '../../img/ICON/识别度高.png'
import wendinxing from '../../img/ICON/服务稳定.png'
import gexinghua from '../../img/ICON/拓展性强.png'
import Vincode from '../../data/images/文字识别.jpg/S/vin码识别.jpg'
import QRcode from '../../data/images/文字识别.jpg/S/二维码识别.jpg'
import customOCR from '../../data/images/文字识别.jpg/S/定制OCR.jpg'
import Barcode from '../../data/images/文字识别.jpg/S/条形码识别.jpg'
import yinyezhizhao from '../../data/images/文字识别.jpg/S/营业执照识别.jpg'
import xingshizheng from '../../data/images/文字识别.jpg/S/行驶证识别.jpg'
import IDcard from '../../data/images/文字识别.jpg/S/身份证识别.jpg'
import chepai from '../../data/images/文字识别.jpg/S/车牌识别.jpg'
import DOC from '../../data/images/文字识别.jpg/S/通用文档识别.jpg'
import yinhangka from '../../data/images/文字识别.jpg/S/银行卡识别.jpg'
import jiashizheng from '../../data/images/文字识别.jpg/S/驾驶证识别.jpg'
import wenzhi from '../../data/images/文字识别.jpg/big/文字识别.jpg'
import b_zuce from '../../data/文字识别JPG/文字识别/推荐场景x4/注册录入.jpg'
import b_denglu from '../../data/文字识别JPG/文字识别/推荐场景x4/认证登录.jpg'
import b_zizhijianbie from '../../data/文字识别JPG/文字识别/推荐场景x4/资质鉴别.jpg'
import b_cheliangguanli from '../../data/文字识别JPG/文字识别/推荐场景x4/车辆管理.jpg'

import yinghangka from '../../img/zhengjian-yinhangka.jpg'
import renlianshibie from '../../img/renlianshibie.jpg'

const ProductFunc = ({ features }) => (
	<div>
		<div className='proFunc_wrap'>
			<div className='proFunc'>
				<h2>产品功能</h2>
				<div className='proList'>
					{
						features.map((item, index) => (
							<div className='proItem' key={index}>
								<div className='back-img-wrap' style={{ width: 380, height: 260 }}>
									<div className='back-img' style={{ width: 380, height: 260, backgroundImage: `url(${item.img})` }}></div>
								</div>
								<span style={{ fontWeight: 100 }}>{item.name}</span>
								<p>{item.desc}</p>
								<div className='select'>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to='/support'>合作咨询</Link>
									<div style={{ width: 1, height: 39, background: '#e8e8e8', position: 'absolute' }}></div>
									<Link className='actived' style={{ color: '#19387B', fontSize: 14 }} to={item.path}>了解详情</Link>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</div>

		<style jsx>{`
			.proFunc_wrap {
					width: 100%;
					background: #f5f5f5;
			}
			.proFunc {
					width: 1200px;
					height: 100%;
					margin: 0 auto;
					padding: 100px 0 100px 0;
			}
			.proFunc h2 {
					width: 152px;
					height: 45px;
					text-align: center;
					margin: 0 auto;
					font-size: 38px;
					color: #333;
					margin-bottom: 60px
			}
			.proFunc .proList {
					width: 1200px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
			}
			.proItem:nth-child(11) {
				margin-right: 410px;
			}
			.proFunc .proList .proItem {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 380px;
					background: #fff;
					margin-bottom: 30px;
			}

			.back-img-wrap {
				overflow: hidden;
			}

			.back-img {
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center center;
				transition: 0.5s linear;
			}

			.back-img:hover {
				transform: scale(1.1)
			}

			.proFunc .proList .proItem>span {
					display: block;
					width: 140px;
					height: 30px;
					font-weight: bold;
					font-size: 20px;
					color: #333;
					text-align: center;
					margin: 20px 0;
			}
			.proFunc .proList .proItem>p {
					width: 310px;
					height: 42px;
					font-size: 14px;
					color: #747578;
					line-height: 22px;
					margin-bottom: 30px;
			}
			.proFunc .proList .proItem .select {
					width: 380px;
					height: 65px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					border-top: 1px solid #E8E8E8;
					position: relative;
			}

		`}</style>
	</div>
)

class Character extends Component {
	constructor() {
		super();
		this.state = {
			features: [
				{
					name: '驾驶证识别',
					desc: '支持对中国大陆机动车驾驶证正页的全字段识别',
					img: jiashizheng,
					path: '/character/driver'
				},
				{
					name: '行驶证识别',
					desc: '支持对中国大陆机动车行驶证正页的全字段识别',
					img: xingshizheng,
					path: '/character/travel'
				},
				{
					name: '身份证识别',
					desc: '支持对中国大陆二代居民身份证正反面的全字段识别',
					img: IDcard,
					path: '/character/IDcard'
				},
				{
					name: '银行卡识别',
					desc: '支持对主流银行卡卡号识别，并返回发卡行和卡片类型信息',
					img: yinhangka,
					path: '/character/bank'
				},
				{
					name: '车牌识别',
					desc: '支持对街景、定制场景等多种场景下汽车车牌号码的识别',
					img: chepai,
					path: '/character/plate'
				},
				{
					name: '营业执照识别',
					desc: '支持对中国大陆竖版营业执照的识别，提供关键字段的信息',
					img: yinyezhizhao,
					path: '/character/business'
				},
				{
					name: '二维码识别',
					desc: '能够识别出二维码中对应的内容',
					img: QRcode,
					path: '/character/QRcode'
				},
				{
					name: '条形码识别',
					desc: '能够识别出条形码中对应的内容',
					img: Barcode,
					path: '/character/Barcode'
				},
				{
					name: 'VIN码识别',
					desc: '对车辆车架上、挡风玻璃上的VIN码进行识别',
					img: Vincode,
					path: '/character/VINcode'
				},
				{
					name: '通用文档识别',
					desc: '支持对篇章及印刷文档的识别',
					img: DOC,
					path: '/character/document'
				},
				{
					name: '自定义模版识别',
					desc: '以深厚的技术积淀为依托，配合用户的个性化场景及需求打磨定制化的文字识别服务',
					img: customOCR,
					path: '/character/customOCR'
				}
			],
			productAdv: [
				{
					title: '识别精度高',
					desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
					icon: shibiedu
				},
				{
					title: '服务稳定性',
					desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
					icon: wendinxing
				},
				{
					title: '支持个性化定制',
					desc: '可配合用户的个性化场景及业务需求，对有版式票据、无版式文档等均可提供定制化、个性化的专用文字识别服务',
					icon: gexinghua
				}
			],
			recommondSence: [
				{
					name: '注册录入',
					content: '无需手动录入，提升注册效率和用户体验',
					icon: 'iconzhucebangka',
					bg: b_zuce
				},
				{
					name: '认证登录',
					content: '轻松识别证件信息，可与人脸识别配合使用，提升安全验证能力',
					icon: 'icongerenzhuli',
					bg: b_denglu
				},
				{
					name: '车辆管理',
					content: '精准识别驾驶证、车牌等核心信息，提升自动化管理水平和效率',
					icon: 'iconcheliangxinxiruku',
					bg: b_cheliangguanli
				},
				{
					name: '资质鉴别',
					content: '证件信息自动识别为文字，为商业保驾护航',
					icon: 'iconneirongfenxiheshenhe',
					bg: b_zizhijianbie
				},
			],
			more: [
				{
					name: '人脸识别',
					path: '/face'
				},
				{
					name: '人体识别',
					path: '/'
				}
			]
		};
	}

	componentWillMount(e) {
		document.getElementById('root').scrollIntoView(true)
	}

	componentDidMount() {
		$(".ant-card-actions").css('background-color', '#FFFFFF');

		let anchorName = window.location.hash;
		if (anchorName) {
			anchorName = anchorName.replace("#", "");
			let anchorElement = document.getElementById(anchorName);
			if (anchorElement) {
				anchorElement.scrollIntoView();
			}
		}
	}

	onConntact = () => {
		var path = "../support"
		this.props.history.push(path);
	}

	render() {
		return (
			<div style={{ fontFamily: 'DFPKingGothicGB' }}>
				<Row >
					<Header headerImg={wenzhi} title={'文字识别 '} desc={'依托海量出行数据，技术稳定成熟，支持多种场景下各类卡证、车牌等文字识别服务，可满足多样化、定制化的场景与需求'} />

					<Col span={24}>
						<ProductFunc features={this.state.features} />
					</Col>

					<Col span={24}>
						<ProductAdv advList={this.state.productAdv} />
					</Col>

					<Col span={24}>
						<RecommondSence recommondSence={this.state.recommondSence} />
					</Col>

					<Col span={24}>
						<div style={{ background: '#FAFAFA', padding: '0px', border: '3px', width: "100%", margin: 'auto' }}>
						</div>
					</Col>
					<Col span={24}>
						<MoreRecommend more={this.state.more} />
					</Col>
				</Row>

			</div>
		);
	}
}

export default withRouter(Character);
