import React, { Component } from 'react';
import './face.css';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { Card } from 'antd';
import axios from 'axios';
import { Upload, message, Button, Icon, Input, notification } from 'antd';
import { fabric } from 'fabric';
import $ from 'jquery';
import { BASE_URL,IMG_BASE_URL } from '../../../const.js';
import Thumbnail from '../../thumbnails/thumbnail'
import VThumbnail from '../../vthumbnails/vthumbnails'
import "react-image-gallery/styles/css/image-gallery.css";

fabric.LabelRect = fabric.util.createClass(fabric.Rect, {
  _render: function (ctx, noTransform) {
    this.callSuper("_render", ctx, noTransform);
    var text = "" + this.index;
    var charWitdh = 30;
    var charHeight = 30;//字体字号
    var textWidth = text.length * charWitdh;
    ctx.fillStyle = "Cyan";//ID数字颜色//this.stroke;
    ctx.font = charHeight + "px Monospace"; //这里使用等宽字体
    //ctx.strokeText(""+this.index,-textWidth/3,10);//空心字效果
    ctx.fillText("" + this.index, -textWidth / 3, 10);
  },
});

let images = [
  {
    original: 'http://100.69.238.155:8002/static/labelpbi/do1_FCfvW1vrzaQEacVfxFnJ',
    thumbnail: 'http://100.69.238.155:8002/static/labelpbi/do1_FCfvW1vrzaQEacVfxFnJ',
    borderColor:'#F77B4C',
  },
  {
    original: 'http://100.69.238.155:8002/static/labelpbi/do1_P84QKXVHSYlNZjGdqLWG',
    thumbnail: 'http://100.69.238.155:8002/static/labelpbi/do1_P84QKXVHSYlNZjGdqLWG',
    borderColor:'#E2E2E2',
  }
];

const tagStyle = {
  fontFamily: 'DFPKingGothicGB',
  fontSize: '14px',
  color: '#FFFFFF',
  textAlign: 'center',
  backgroundColor: '#F77B4C',
  width: '20%',
  marginLeft: '0px',
  height: '23px',
  borderStyle: 'none',
}


let fabricCanvas = null;
let canvasFixedHeight = 370;
let canvasFixedWidth = 560;
let thumbFixedWidth = 526;
let thumbFixedMarginLeft = 24;
let resultJsonFixedHeight = 110;

let testJson = {
  "candidate": [
      {
          "age": 3,
          "emotion": 3,
          "gender": 0,
          "location": {
              "bot": 224.0333404541016,
              "left": 72.53633880615234,
              "right": 196.7655029296875,
              "top": 65.55526733398438
          }
      }
  ]
};

class Analy extends Component {
  constructor() {
    super();
    this.state = {
      resultJson: '',
      faces:[]
    };
  }

  onUrlBlur = () => {
    var imgURL = $('#imgURL').val();
    if (imgURL === null || imgURL === "") {
      notification.open({
        message: 'url格式错误',
        description: '检测图片url不能为空'
      });

      return;
    }

    var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
    var objExp = new RegExp(Expression);
    if (false == objExp.test(imgURL)) {
      notification.open({
        message: 'url格式错误',
        description: 'url必须以http://或者https://开始,并包含host和path信息'
      });
    }
  }

  onDetect = () => {
    var imgURL = $('#imgURL').val();
    this.delAllFace();
    this.setImg(imgURL);
    this.postRequest(imgURL, 3);
  }

  beforeUploadCheck = (file, fileList) => {
    // console.log(file, fileList);
    var retValue = true;
    fileList.forEach(function (fileInfo) {
      if (fileInfo.size >= 5242880) {
        message.error("请上传小于5M的文件");
        retValue = false;
      }
    });

    $(".ant-upload-list").hide();
    return retValue;
  }

  onUpload = (info) => {
    // console.log(info)
    if (undefined === info) {
      return;
    }

    if (info.file.status !== 'uploading') {
      console.log(info.file.name, info.fileList);
    }
    if (info.file.status === 'done') {
      var fileUrl = IMG_BASE_URL + info.file.name;
      this.delAllFace();
      this.setImg(fileUrl);
      this.postRequest(fileUrl, 2);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }

    $(".ant-upload-list").hide();
  }

  setImg = (imgUrl,firstLoad,testJson) => {
    $(".image-gallery-image").hide();

    var scaleValue = 0;
    var outerThis = this;
    var center = fabricCanvas.getCenter();
    fabric.Image.fromURL(
      imgUrl,
      function (img) {
        center = fabricCanvas.getCenter();
        var scaleX = (fabricCanvas.getWidth() - 50) / img.width;
        var scaleY = (fabricCanvas.getHeight() - 50) / img.height;
        scaleValue = scaleX > scaleY ? scaleY : scaleX;

        fabricCanvas.setBackgroundImage(img,
          fabricCanvas.renderAll.bind(fabricCanvas), {
            scaleX: scaleValue,
            scaleY: scaleValue,
            top: center.top,
            left: center.left,
            originX: 'center',
            originY: 'center',
          }
        );
        fabricCanvas.centerObject(img);

        if(firstLoad != undefined){
          outerThis.drawFaceRect(imgUrl, testJson,scaleValue);
          outerThis.addProperty(testJson,outerThis);
        }
      }
    )

    return scaleValue;
  }

  delAllFace = () => {
      var objs = fabricCanvas.getObjects();

      objs.forEach(function(ele){
        if(ele.type == "face"){
          fabricCanvas.remove(ele);
        }
      });
  }

  drawFaceRect = (imgURL,data,scaleValue) => {
    var bkgImg = fabricCanvas.backgroundImage;
    var scaleX = fabricCanvas.getWidth() / bkgImg.width;
    var scaleY = fabricCanvas.getHeight() / bkgImg.height;
    scaleValue = scaleX > scaleY ? scaleY : scaleX;
    var center = fabricCanvas.getCenter();

    var bkgImgLeft = center.left - (scaleValue*bkgImg.width)/2;
    var bkgImgTop = center.top - (scaleValue*bkgImg.height)/2;

    data.candidate.forEach(function(elem,idx){
      var left = elem.location.left;
      var top = elem.location.top;

      var right = elem.location.right;
      var bot = elem.location.bot;

      var rct = new fabric.LabelRect({
        left: bkgImgLeft + left * scaleValue,
        top: bkgImgTop + top * scaleValue,
        width: (right-left) *scaleValue,
        height: (bot-top) *scaleValue,
        index:idx + 1,
        stroke: 'rgb(247, 123, 76)',
        opacity: 1,
        strokeWidth: 2,
        fill: 'rgba(0,255,255,0.15)'
      });

      rct.type = "face";
      fabricCanvas.add(rct);
    });
  }

  addProperty = (data,outerThis) => {
    var age;
    var emotion;
    var gender;

    var facesInfos = []
    data.candidate.forEach(function(elem,idx){
      if(elem.gender == 0){
        gender = "女";
      } else if(elem.gender == 1){
        gender = "男";
      } else {
        gender = "不确定";
      }

      if(elem.emotion == 0){
        emotion = "生气";
      } else if (elem.emotion == 1){
        emotion = "厌恶";
      } else if (elem.emotion == 2){
        emotion = "恐惧";
      } else if (elem.emotion == 3){
        emotion = "开心";
      } else if (elem.emotion == 4){
        emotion = "伤心";
      } else if (elem.emotion == 5){
        emotion = "惊讶";
      } else if(elem.emotion == 6){
        emotion = "中性";
      }

      if(elem.age == 0){
        age = "0-3岁";
      } else if (elem.age == 1){
        age = "4-6岁";
      } else if (elem.age == 2){
        age = "7-13岁";
      } else if (elem.age == 3){
        age = "14-20岁";
      } else if (elem.age == 4){
        age = "25-32岁";
      } else if (elem.age == 5){
        age = "33-43岁";
      } else if(elem.age == 6){
        age = "48-53岁";
      } else if(elem.age == 7){
        age = "60岁以上";
      }

      facesInfos.push({idx:idx+1,age:age,gender:gender,emotion:emotion});
    });

    outerThis.setState({
        faces:facesInfos
    });
  }

  postRequest = (postUrl, type,scaleValue) => {
    var outerThis = this;
    var svrData ;
    axios.post(BASE_URL + '/face/faceanalyse',
      {
        url: postUrl,
        type: type
      }
    ).then(function (response) {
        if (response.status === 200) {
          outerThis.setState({
              resultJson: JSON.stringify(response.data.data, null, 4)
          });

          outerThis.drawFaceRect(postUrl, response.data.data, scaleValue);
          outerThis.addProperty(response.data.data, outerThis);
        }
      }).catch(function (error) {
        console.log(error);
      });

      return svrData;
  }

  onThumbnailClick = (url) => {
    this.delAllFace();
    var scaleValue = this.setImg(url);
    this.postRequest(url, 1, scaleValue);
  }

  componentDidMount() {
    fabricCanvas = new fabric.Canvas('c');
    fabricCanvas.setHeight(canvasFixedHeight);
    fabricCanvas.setWidth(canvasFixedWidth);
    $(".ant-card-body").css("padding","0");
    $(".ant-input-search-button").css("background-color", "#F77B4C");
    $(".ant-input-search-button").css("border-color", "#d9d9d9");
    $(".ant-input-search-button").css("box-shadow", 'none');
    $(".ant-input-search-button").css("border", 'none');
    $(".ant-input-search-button").css("text-shadow", 'none');
    this.setImg(images[0].original,true,testJson);
    this.setState({
      resultJson:
        `{
          "candidate": [
              {
                  "age": 2,
                  "emotion": 6,
                  "gender": 1,
                  "location": {
                      "bot": 394.3056335449219,
                      "left": 277.3720092773438,
                      "right": 492.4472961425781,
                      "top": 109.674560546875
                  }
              }
          ]
      }`
    });
  }

  render() {
    const Search = Input.Search;
    return (
      <div >
        <Row style={{ background: '#FBFBFB',height: canvasFixedHeight, width: '80%', margin: 'auto', textAlign: "center", display: 'flex', justifyContent: 'center' }}>
            <div style={{width:canvasFixedWidth}}>
              <Col span={2} style={{ ...tagStyle, width: '66px' }}>原始图片</Col>
              <canvas id='c' style={{ width: '100%', border: '1px solid #E2E2E2' }}></canvas>
            </div>
            <div style={{width:thumbFixedWidth,marginLeft:thumbFixedMarginLeft,backgroundColor:'white',border:'1px solid #E2E2E2'}}>
              <VThumbnail items={this.state.faces} nailWidth={thumbFixedWidth} nailheight={canvasFixedHeight}/>
            </div>
        </Row>
        <br />
        <Row style={{ height: '250px', width: '80%', margin: 'auto', textAlign: "center", display: 'flex', justifyContent: 'center' }}>
          <div>
            <div style={{width:canvasFixedWidth-2}}>
              <div ><Thumbnail images={images} id="gallery" onThumbnailClick={this.onThumbnailClick} /></div>
            </div>
            <Row>
              <Col span={3} style={{ marginTop: '10px' }}>
                <Upload accept='image/jpeg,image/jpg' name='upfile' action={BASE_URL + '/ocr/upload'} onChange={this.onUpload} beforeUpload={this.beforeUploadCheck}>
                  <Button style={{ backgroundColor: '#F77B4C', fontFamily: 'DFPKingGothicGB', fontSize: '14px', color: '#FFFFFF', textAlign: 'center', boxShadow: 'none', border: 'none', textShadow: 'none' }}>
                    本地上传
                    </Button>
                </Upload>
              </Col>
              <Col offset={5} span={16} style={{ fontFamily: 'DFPKingGothicGB', backgroundColor: '#FFFFFF', marginTop: '10px' }}>
                <Search style={{ border: 'none', textShadow: 'none' }}
                  id="imgURL"
                  placeholder="图片Url,以http://或者https://开始"
                  enterButton="检测"
                  onSearch={this.onDetect}
                  style={{ boxShadow: 'none', border: 'none' }}
                />
              </Col>
            </Row>
          </div>
          <div span={11} style={{ height: resultJsonFixedHeight + 44, marginLeft: thumbFixedMarginLeft ,width:thumbFixedWidth,marginTop:'3px'}}>
            <Row style={{ border: '1px solid #E2E2E2', background: 'white' }}>
              <Col offset={0} span={4} style={{ ...tagStyle, width: '126px' }}>RESPONSE JSON</Col>
              <br/>
              <Card style={{ textAlign: 'left',border:'none' }}>
                <pre>
                  <Col style={{ height: resultJsonFixedHeight + 22, overflowY: 'scroll', textAlign: 'left', fontFamily: 'DFPKingGothicGB' }}>
                    {this.state.resultJson}
                  </Col>
                </pre>
              </Card>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}

export default Analy;
