import React, { Component } from 'react'
import Footer from '../../component/footer/footer.js'
import {Link} from 'react-router-dom'

import icon_time from './imgs/icon_time@2x.png'
import icon_back from './imgs/icon_back@2x.png'
import bannerTop from './imgs/detail1-1.jpg'
import itime from './imgs/detail1-10.jpg'
import './common.css'
import CooperationCom from "./components/cooperation-com";

export default class SubCooper extends Component {
  render() {
    return (
      <div>
        <div className='sub-cooper-wrap'>
          <div className='sub-cooper'>
            <CooperationCom></CooperationCom>
            <div className='cooper-right'>
              <h2>全球智博会@滴滴专场论坛：赋能新出行· AI 绘未来</h2>
              <div className='timer' style={{marginBottom: 60, display: 'flex', alignItems: 'center'}}>
                <div className='time-logo'></div>
                2019-05-09  <span className="laiyuan">来源：滴滴技术</span>
              </div>
              <p>
                导读：由国家科学技术部、工业和信息化部、江苏省人民政府指导，苏州市人民政府和新一代人工智能产业技
                术创新战略联盟联合主办，苏州工业园区管理委员会承办，苏州工业园区科技发展有限公司协办的2019
                全球智博会将在5月9-11日在苏州市国际博览中心召开。
              </p> 
              <p>滴滴出行将于5月10日下午14：00在苏州国际博览中心 A210-211 举办“赋能新出行 · AI绘未来”论坛。</p>
              <div class="bgimagesize" style={{width: 810, height: 293, backgroundImage: 'url('+bannerTop+')', marginBottom: 15, backgroundSize: 'contain'}}></div>
              <p>
                滴滴论坛将围绕企业如何更好的利用AI 技术、服务以及多元化的解决方案展开，将同各界探讨开放协作之路，推动智能出行生态建设与发展之道，着眼赋能新出行的目标。
              </p>
              <p>时间：5月10日，14：00 - 17：30</p>
              <p>地点：金鸡湖国际会议中心A210 - A211</p>
              <p>▍论坛阵容</p>
              <p>论坛主席由滴滴出行副总裁、AI Labs负责人叶杰平出任，将邀请滴滴AI基础平台，AI能力开放，解决方案的各方负责人，协同滴滴合作伙伴阐述如何通过智能出行开放平台赋能新出行。</p>
              <div class="bgimagesize" style={{width: 810, height: 1133, backgroundImage: 'url('+itime+')', marginBottom: 60, backgroundSize: 'contain'}}></div>

              <Link className='pre-next' to='/cooperation/kaifangpingtai'>上一篇：滴滴启动"群雁计划" 打造滴滴智能出行开放平台</Link>
              <Link className='pre-next' to='/cooperation/cooper-detail2' style={{marginBottom: 100}}>下一篇：滴滴亮相2019全球智博会：智能出行时代的开放与赋能</Link>
            </div>
          </div>
        </div>


        <style jsx>{`
          .sub-cooper-wrap {
            width: 100%;
            height: 100%;
            background: #fff;
          }

          .sub-cooper {
            display: flex;
            width: 1200px;
            margin: 0 auto;
            background: #fff;
          }

          .cooper-left {
            width: 280px;
            padding-top: 70px;
          }

          .back {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #333333;
            margin-bottom: 175px;
            cursor: pointer;
          }

          .back>img {
            width: 20px;
            height: 20px;
            margin-bottom: 2px;
            margin-right: 12px;
          }

          .detail>span{
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #333333;
          }

          .detail-list {
            margin-top: 30px;
            margin-left: 15px;
            padding-left: 0;
          }

          .detail-item {
            width: 270px;
            height: 40px;
            font-size: 14px;
            color: #333;
            margin-bottom: 20px;
            cursor: pointer;
            list-style: disc;
          }

          .detail-item:hover {
            color: #19387B;
          }

          .cooper-right {
            width: 810px;
            margin: 110px 0 0 110px;
          }

          .cooper-right>h2 {
            font-family: PingFangSC-Medium;
            font-size: 34px;
            color: #333333;
            text-align: left;
          }

          .time-logo {
            width: 13px;
            height: 13px;
            background-image: url(${icon_time});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            margin-right: 6px;
          }

          p {
            width: 810px;
            color: #333;
            font-size: 16px;
            line-height: 30px;
          }

          .pre-next {
            display: block;
            font-size: 14px;
            color: #999;
            font-family: PingFangSC-Medium;
            cursor: pointer;
          }

          .pre-next:hover {
            color: #19387B;
          }
        `}</style>
      </div>
    )
  }
}
