import zhunquekekao from '../img/ICON/保密可靠.png'
import keyong from '../img/ICON/通用性强.png'
import shiyingxing from '../img/ICON/适应性强.png'
import gexinghuadingzhi from '../img/ICON/个性化定制.png'
import jingdugao from './ICON一期/24.识别度高.png'
import xiaoguoyou from './ICON一期/13.效果自然.png'
import wendinkekao from './ICON一期/23.服务稳定.png'
import zhuanyehua from './ICON一期/10.专业化.png'
import duolidu from './ICON一期/7.多颗粒度支持.png'
import yuliaoguimo from './ICON一期/6.语料规模大.png'
import linhuoxingao from './ICON一期/21.通用性强.png'
import fanweiguang from './ICON一期/22.适应性强.png'
import hailiangshuju from './ICON一期/8.海量数据支持.png'
import jisulinxian from './ICON一期/19.技术领先.png'
import biaoqianfengfu from './ICON一期/5.维度丰富.png'


import shuxing from '../pages/face/images/人脸属性分析.jpg'
import chaoxiang from '../pages/face/images/人脸朝向分析.jpg'
import jiance from '../pages/face/images/人脸检测.jpg'
import huoti from '../pages/face/images/人脸活体检测.jpg'
import shibie from '../pages/face/images/人脸识别.jpg'
import yanzheng from '../pages/face/images/人脸验证.jpg'


import guanjiandian from './images/人体关键点检测.jpg'
import shoushi from './images/手势识别.jpg'
import ReID from './images/行人re-iD.png'
import xingwei from './images/行人检测.jpg'

import VINcode from './images/文字识别.jpg/big/VIN码识别.jpg'
import customOCR from './images/文字识别.jpg/big/定制OCR.jpg'
import yinyezhizhao from './images/文字识别.jpg/big/营业执照识别.jpg'
import xingshi from './images/文字识别.jpg/big/行驶证识别.jpg'
import shenfen from './images/文字识别.jpg/big/身份证识别.jpg'
import chepai from './images/文字识别.jpg/big/车牌识别.jpg'
import tongyongwendang from './images/文字识别.jpg/big/通用文档识别.jpg'
import yinhangka from './images/文字识别.jpg/big/银行卡识别.jpg'
import jiashizheng from './images/文字识别.jpg/big/驾驶证识别.jpg'
import QRcode from './images/文字识别.jpg/big/头图-二维码识别.png'
import Barcode from './images/文字识别.jpg/big/头图-条形码识别.jpg'

import fenci from './语言处理技术/中文分词/中文分词.jpg'
import jiucuo from './语言处理技术/中文纠错/中文纠错.jpg'
import guanjianci from './语言处理技术/关键词抽取/关键词抽取.jpg'
import juxiangliang from './语言处理技术/句向量/句向量.jpg'
import minmingshibie  from './语言处理技术/命名实体识别/命名实体识别.jpg'
import qingganshibie from './语言处理技术/情感识别/情感识别.jpg'
import qingxushibie from './语言处理技术/情绪识别/情绪识别.jpg'
import shujunimin from './语言处理技术/数据匿名/数据匿名.jpg'
import wenbenzaiyao from './语言处理技术/文本摘要/文本摘要.jpg'
import cixiangliang from './语言处理技术/词向量/词向量.jpg'
import cixinbiaozhu from './语言处理技术/词性标注/词性标注.jpg'
import yuyi from './语言处理技术/语义相似度/语义相似度.jpg'

import xinxiliucun  from './文字识别JPG/营业执照/推荐场景X2/营业执照-信息留存.jpg'
import zizhishenhe from './文字识别JPG/营业执照/推荐场景X2/资质审核.jpg'
import neirongcunliu from './文字识别JPG/自定义模板/推荐场景X2/内容留存及审核.jpg'
import wuzhihuaguanliu from './文字识别JPG/自定义模板/推荐场景X2/无纸化文档管理.jpg'
import zhilinjiexi from './语言处理技术/中文分词/推荐场景X3/指令解析.png'
import zhilinjiexi400 from './语言处理技术/中文分词/推荐场景X3/机器翻译400.png'
import wenbenshousuo from './语言处理技术/中文分词/推荐场景X3/文本搜索.jpg'
import zidongwenda from './语言处理技术/中文分词/推荐场景X3/自动问答.jpg'
import sousuojiucuo from './语言处理技术/中文纠错/推荐场景X3/搜索纠错.jpg'
import shurufajiucuo from './语言处理技术/中文纠错/推荐场景X3/输入法纠错.jpg'
import wenbenjulei from './语言处理技术/关键词抽取/推荐场景X2/文本聚类.jpg'
import wenbentuijian from './语言处理技术/句向量/推荐场景X2/文本推荐.jpg'
import yuyisousuo from './语言处理技术/句向量/推荐场景X2/语义搜索.jpg'
import zhishikugoujian from './语言处理技术/命名实体识别/推荐场景X3/知识库构建.jpg'
import yuqingfenxi from './语言处理技术/命名实体识别/推荐场景X3/舆情分析.jpg'

import koubeifenxi from './语言处理技术/情感识别/推荐场景X3/口碑分析.png'
import yuqingjiankong from './语言处理技术/情感识别/推荐场景X3/舆情监控.png'
import pinlunfenxi from './语言处理技术/情感识别/推荐场景X3/评论分析与决策.png'

import changjingduihua from './语言处理技术/情绪识别/推荐场景X3/场景对话.jpg'
import zhinengkefu from './语言处理技术/情绪识别/推荐场景X3/智能客服.jpg'
import xianliao from './语言处理技术/情绪识别/推荐场景X3/闲聊.jpg'
import gerenyinsi from './语言处理技术/数据匿名/推荐场景X2/个人隐私保护.jpg'
import qiyeyinsi from './语言处理技术/数据匿名/推荐场景X2/企业隐私保护.jpg'
import biaotishengcheng from './语言处理技术/文本摘要/推荐场景X2/标题生成.jpg'
import yuyizhaohui from './语言处理技术/词向量/推荐场景X2/语义召回.jpg'
import xinxisousuo from './语言处理技术/词性标注/推荐场景X2/信息搜索.jpg'
import gaixie from './语言处理技术/语义相似度/推荐场景X2/query改写.jpg'
import cheliangxinxi from './文字识别JPG/vin码识别/推荐场景X2/车辆信息入库.jpg'
import cheliangguanli  from './文字识别JPG/vin码识别/推荐场景X2/车辆智能管理.jpg'
import changkuguanli from './文字识别JPG/二维码识别/推荐场景X2/仓储管理登记.jpg'
import danjujindu from './文字识别JPG/二维码识别/推荐场景X2/单据进度查询.jpg'
import b_shujuluru from './文字识别JPG/条形码识别/推荐场景X2/数据录入和查询.jpg'
import zucexinxi from './文字识别JPG/行驶证识别/推荐场景X3/注册信息录入.jpg'
import yonghupinzheng from './文字识别JPG/行驶证识别/推荐场景X3/用户凭证登录.jpg'
import yonghujianbie from './文字识别JPG/行驶证识别/推荐场景X3/用户资质鉴别.jpg'
import b_renliziyuan from './文字识别JPG/身份证识别/推荐场景X2/人力资源管理.jpg'
import kehushenfen from './文字识别JPG/身份证识别/推荐场景X2/客户身份认证.jpg'
import chezhushenfen from './文字识别JPG/车牌识别/推荐场景X2/车主身份认证.jpg'
import neirongshenhe from './文字识别JPG/通用文档识别/推荐场景X2/内容分析审核.jpg'
import bangongwendang from './文字识别JPG/通用文档识别/推荐场景X2/办公文档电子化管理.jpg'
import zucebangka from './文字识别JPG/银行卡识别/推荐场景X3/注册绑卡.jpg'
import shenfenrenzheng from './文字识别JPG/银行卡识别/推荐场景X3/身份认证.jpg'
import renliziyuan from './文字识别JPG/银行卡识别/推荐场景X3/人力资源管理.jpg'
import yulejiaohu from './人体识别/人体关键点检测/推荐场景x3/娱乐交互.jpg'
import anfangjiankong_bg from './人体识别/行人检测/推荐场景X2/安防监控.png'
import jiaoxuefuzhu from './人体识别/人体关键点检测/推荐场景x3/教学辅助.jpg'
import zhenchasousuo from './人体识别/行人REID/推荐场景X2/侦查搜索.jpg'
import xingweifenxi from './人体识别/行人REID/推荐场景X2/行为分析.jpg'
import fuzhujiashi from './人体识别/行人检测/推荐场景X2/辅助驾驶.jpg'
import jiaohukongzhi from './人体识别/手势识别/推荐场景X2/交互控制.jpg'
import yuletexiao from './人体识别/手势识别/推荐场景X2/娱乐特效.jpg'
import anfangjiance from './人脸识别 /人脸识别/人脸验证/推荐场景x3/安防检测.jpg'
import h_anfang from './人脸识别 /人脸识别/人脸活体检测/推荐场景X2/安防检测.jpg'
import h_shenfen from './人脸识别 /人脸识别/人脸活体检测/推荐场景X2/身份认证.jpg'
import bangongkaoqin from './人脸识别 /人脸识别/人脸验证/推荐场景x3/办公考勤.jpg'
import zhihuishangye from './人脸识别 /人脸识别/人脸识别/推荐场景X2/智慧商业.jpg'
import kaoqinqiandao from './人脸识别 /人脸识别/人脸识别/推荐场景X2/考勤签到.jpg'
import hudongyule from './人脸识别 /人脸识别/人脸检测/推荐场景X2/互动娱乐.jpg'
import changjingfenxi from './人脸识别 /人脸识别/人脸检测/推荐场景X2/场景分析.jpg'
import shigupanduan from './人脸识别 /人脸识别/人脸朝向分析/推荐场景X2/事故判责.jpg'
import anquanjiance from './人脸识别 /人脸识别/人脸朝向分析/推荐场景X2/安全检测.jpg'
import yonghuhuaxiang from './人脸识别 /人脸识别/人脸属性分析/推荐场景X2/用户画像.jpg'
import tupianneirong from './内容审核JPG/涉政图片鉴别/推荐场景x3/图片内容审核.png'
import b_shejiao from './内容审核JPG/涉政图片鉴别/推荐场景x3/社交监管.jpg'
import shiping from './内容审核JPG/涉政图片鉴别/推荐场景x3/视频.jpg'
import r_tupianneirong from './内容审核JPG/涉黄图片鉴别/推荐场景x3/图片内容审核.jpg'
import r_shejiao from './内容审核JPG/涉黄图片鉴别/推荐场景x3/社交监管.jpg'
import r_shipin from './内容审核JPG/涉黄图片鉴别/推荐场景x3/视频.jpg'
import h_shehuangjiance from './内容审核JPG/header//涉黄图像检测.jpg'
import h_shezhen from './内容审核JPG/header/涉政图像检测.jpg'
import h_zidingyi from './内容审核JPG/header/自定义内容检测.jpg'
import chexing from './车辆分析/车型识别/车型识别.jpg'
import cheliu from './车辆分析/车流统计/车流统计.jpg'
import cheliangjiance from './车辆分析/车辆检测/车辆检测.jpg'
import zhihuitingche from './车辆分析/车辆检测/推荐场景x3/智慧停车场.jpg'
import weiguitingche from './车辆分析/车辆检测/推荐场景x3/违规停车管理.jpg'
import r_zhihuijiaotong from './车辆分析/车流统计/推荐场景x3/智慧交通.jpg'
import r_zhihuichuxing from './车辆分析/车流统计/推荐场景x3/智慧出行.jpg'
import tukuguanli from './车辆分析/车型识别/推荐场景x3/图库管理.jpg'
import bukongzhencha from './车辆分析/车型识别/推荐场景x3/布控侦查.jpg'
import wenzhangjuanxie from './语言处理技术/中文纠错/推荐场景X3/文章撰写助手.jpg'
import zhuanminwajue from './语言处理技术/语义相似度/推荐场景X2/专名挖掘.jpg'
import gexinghuatuijian from './语言处理技术/词向量/推荐场景X2/个性化推荐.jpg'
import wenzhangzhansi from './语言处理技术/文本摘要/推荐场景X2/文章展示推送.jpg'

import g_rentiguanjiandian  from './func_gif/人体关键点识别.gif'
import g_renlianjiance from './func_gif/人脸检测（已转换）.gif'
import g_renlianjiansuo from './func_gif/人脸检索.gif'
import g_renlianyanzheng from './func_gif/人脸验证_1.gif'
import g_renlianshuxing from './func_gif/人脸属性检测.gif'
import g_chaoxiang from './func_gif/人脸朝向分析0.jpg'
import g_renlianhuoti from './func_gif/人脸活体检测.gif'
import g_id from './func_gif/行人id_1.gif'
import g_xingwei from './func_gif/行为检测-.gif'
import g_shoushi from './func_gif/手势识别.gif'
import g_jiashi from './func_gif/驾驶证识别_1.gif'
import g_xingshi from './func_gif/行驶证识别.gif'
import g_shenfenzheng from './func_gif/身份证识别_1.gif'
import g_yinhangka from './func_gif/银行卡识别.gif'
import g_chepai from './func_gif/车牌识别.gif'
import g_qrcode from './func_gif/二维码识别.gif'
import g_barcode  from './func_gif/条形码.gif'
import g_vincode from './func_gif/VIN码.jpg'
import g_zidingyi from './func_gif/通用文档识别.gif'
import g_tongyong from './func_gif/自定义模板识别.gif'
import g_yingye from './func_gif/营业执照识别_1.gif'
import g_chexing from './func_gif/车型识别.gif'
import g_cheliang from './func_gif/车辆检测.gif'

import g_shehuang from './func_gif/涉黄图像识别.gif'
import g_shezhen from './func_gif/涉政.gif'
import g_neirong from './func_gif/自定义内容审核.gif'

import r_renlianshuxing from './静态图/人脸属性.jpg'
import r_shehuang from './静态图/涉黄图片.jpg'
import r_renlianhuoti from './静态图/人脸活体检测.jpg'
import r_renlianshibie from './静态图/人脸识别img.jpg'
import r_rentiguanjian from './静态图/人体关键点img.jpg'
import r_renlianyanzheng from './静态图/人脸验证图 2.jpg'
import r_renlianjiance from './静态图/人脸检测图 2.jpg'
import r_rentichaoxiang from './静态图/人体区域检测img.jpg'
import r_reid from './静态图/行人re-id.jpg'
import r_xingwei from './静态图/行为识别img.png'
import r_shoushi from './静态图/手势识别-dt.png'
import r_jiashi from './静态图/驾驶证 2.jpg'
import r_xingshizheng from './静态图/行驶证 2.jpg'
import r_shenfenzheng from './静态图/身份证图 改 2.jpg'
import r_yinhangka from './静态图/银行卡 2.jpg'
import r_chepai from './静态图/车牌识别img.jpg'
import r_qrcode from './静态图/二维码图.jpg'
import r_barcode from './静态图/条形码.jpg'
import r_zidingyi from './静态图/通用文档识别 2.png'
import r_tongyong from './静态图/自定义模板识别img.png'
import r_yingye from './静态图/营业执照img.png'
import r_chexing from './静态图/车型识别img.jpg'
import r_shezhen from './静态图/涉政jpg 2.jpg'
import r_neirong from './静态图/自定义内容审核图 2.jpg'
import r_cheliangjiance from './静态图/目标检测img05.jpg'
import r_cheliu from './静态图/目标追踪img.jpg'

import r_zhongwenfenci from './语言处理技术/func-img/中文分词的副本.jpg'
import r_zhongwenjiucuo from './语言处理技术/func-img/中文纠错.jpg'
import r_guanjianci from './语言处理技术/func-img/关键词抽取.jpg'
import r_juciangliang from './语言处理技术/func-img/句向量.jpg'
import r_shujunimin from './语言处理技术/func-img/数据匿名.jpg'
import r_cixiangliang  from './语言处理技术/func-img/词向量.jpg'
import r_cixing from './语言处理技术/func-img/词性标注.jpg'
import r_minmingshiti from './语言处理技术/func-img/命名实体区别.jpg'
import r_yuyixiangshi from './语言处理技术/func-img/语义相似度.jpg'
import r_qinggan from './语言处理技术/func-img/情感识别.jpg'
import r_qingxu from './语言处理技术/func-img/情绪识别.jpg'

export const renlianshibie = [
  {
    path: 'detection',
    title: '人脸检测',
    headerImg: jiance,
    funcImg: r_renlianjiance,
    funcGif: g_renlianjiance,
    desc: '根据提供的图像，检测人脸及其所在区域，返回对应位置信息',
    funcIntro: '在输入的图像中检测人脸，返回人脸所在区域的坐标，可支持大规模密集人脸检测',
    productAdv: [
      {
        title:'准确可靠',
        desc: '每日百万级别线上数据验证，识别精度达99.9%以上',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '实时识别、支持高并发计算，提供高可用性保证',
        icon: keyong
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂场景，不同拍摄条件的图片均可成功识别',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '场景分析',
        content: '通过人脸检测可实现多种场所的人流量分析与检测，结合实际需求识别关键场景',
        icon: 'iconchangjingfenxi',
        bg: changjingfenxi
      },
      {
        name: '互动娱乐',
        content: '以人脸检测为基础，可实现人脸美颜，照片贴图，表情包制作等娱乐功能',
        icon: 'iconhudongyule',
        bg: hudongyule
      }
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本',
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/face/identify',
        name: '人脸检索'
      },
      {
        path: '/face/verify',
        name: '人脸验证'
      }
    ]
  },
  {
    path: 'verify',
    title: '人脸验证',
    headerImg: yanzheng,
    desc: '对比两张人脸的相似度，判断是否为同一人， 可用于多种形式的身份认证',
    funcImg: r_renlianyanzheng,
    funcGif: g_renlianyanzheng,
    funcIntro: '',
    productAdv: [
      {
        title:'准确可靠',
        desc: '每日百万级别线上数据验证，识别精度达99.9%以上',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '实时识别、支持高并发计算，提供高可用性保证',
        icon: keyong
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂场景，不同拍摄条件的图片均可成功识别',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '考勤签到',
        content: '通过人脸验证实现签到、考勤，可降低人工成本、精简办公流程、优化办公体验',
        icon: 'iconkaoqinqiandao',
        bg: bangongkaoqin
      },
      {
        name: '身份认证',
        content: '线上注册、实名认证或其他需要确认身份的高风险操作，可通过验证提升安全性',
        icon: 'icongerenzhuli',
        bg: shenfenrenzheng
      },
      {
        name: '安防检测',
        content: '适用于有重点安保需求的场所，可设置人脸门禁，防止侵入，提高安全系数',
        icon: 'iconanfangjiance',
        bg: anfangjiance
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/face/identify',
        name: '人脸检索'
      },
      {
        path: '/face/detection',
        name: '人脸检测'
      }
    ]
  },
  {
    path: 'identify',
    title: '人脸检索',
    headerImg: shibie,
    funcImg: r_renlianshibie,
    funcGif: g_renlianjiansuo,
    desc: '在已有图片集合中，识别出与输入人脸最相似的结果，返回相似度和数据库中用户身份信息',
    funcIntro: '输入一张人脸与给定人脸库中的图片进行比对，返回相似度最高的TopN结果。支持定制化服务，可依据具体需求实现相似人脸检索、人脸身份识别等功能',
    productAdv: [
      {
        title:'准确可靠',
        desc: '每日百万级别线上数据验证，识别精度达99.9%以上',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '实时识别、支持高并发计算，提供高可用性保证',
        icon: keyong
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂场景，不同拍摄条件的图片均可成功识别',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '考勤签到',
        content: '通过人脸验证实现签到、考勤，可降低人工成本、精简办公流程、优化办公体验',
        icon: 'iconkaoqinqiandao',
        bg: kaoqinqiandao
      },
      {
        name: '智慧商业',
        content: '商业经营场所可利用人脸识别技术，实现客户分群、精准营销，为消费者提供更优质的服务',
        icon: 'iconzhihuishangye',
        bg: zhihuishangye
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/face/detection',
        name: '人脸检测'
      },
      {
        path: '/face/verify',
        name: '人脸验证'
      }
    ]
  },
  {
    path: 'attribute',
    title: '人脸属性分析',
    headerImg: shuxing,
    desc: '对图像中的人脸进行性别、年龄、情绪等属性的分析。配合实际需求，可支持包括戴口罩、戴眼镜等多达40余项人脸属性的识别',
    funcIntro: '',
    funcImg: r_renlianshuxing,
    funcGif: g_renlianshuxing,
    productAdv: [
      {
        title:'准确可靠',
        desc: '每日百万级别线上数据验证，识别精度达99.9%以上',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '实时识别、支持高并发计算，提供高可用性保证',
        icon: keyong
      },
      {
        title:'个性化定制',
        desc: '可配合客户实际需求，提供定制化、多模块灵活组合的人脸属性分析服务',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '用户画像',
        content: '根据图像中人脸的性别、年龄、情绪属性，刻画用户特征，进一步为个性化运营工作提供依据',
        icon: 'iconyonghuhuaxiang',
        bg: yonghuhuaxiang
      },
      {
        name: '场景分析',
        content: '多维度分析人脸特征，结合关键场景需求，在业务管控、智能安防等领域发挥重要作用',
        icon: 'iconchangjingfenxi',
        bg: changjingfenxi
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，保障数据私密性，提高安全性'
      },
    ],
    more: [
      {
        path: '/face/detection',
        name: '人脸检测'
      },
      {
        path: '/face/verify',
        name: '人脸验证'
      }
    ]
  },
  {
    path: 'towards',
    title: '人脸朝向分析',
    headerImg: chaoxiang,
    desc: '分析人脸在三维空间内的偏转角度，进一步可对人脸图像做正脸/侧脸分类，或配合具体需求筛选正常/异常人脸',
    funcIntro: '分析人脸在三维空间内的偏转角度，进一步可对人脸图像做正脸/侧脸分类，或配合具体需求筛选正常/异常人脸',
    funcImg: g_chaoxiang,
    funcGif: g_chaoxiang,
    productAdv: [
      {
        title:'准确可靠',
        desc: '每日百万级别线上数据验证，识别精度达99.9%以上',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '实时识别、支持高并发计算，提供高可用性保证',
        icon: keyong
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂场景，不同拍摄条件的图片均可成功识别',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '安全检测',
        content: '根据车内驾驶员的脸部朝向，辅助判断是否分心驾驶，检测行程中驾驶安全状况',
        icon: 'iconanquanjiance',
        bg: anquanjiance
      },
      {
        name: '事故判责',
        content: '基于驾驶员面部朝向分析，结合事故发生前后报警信息，查看是否产生视线偏移报警，提供判责参考',
        icon: 'iconshigupanze',
        bg: shigupanduan
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/face/body',
        name: '人脸活体检测'
      },
      {
        path: '/face/attribute',
        name: '人脸属性分析'
      }
    ]
  },
  {
    path: 'body',
    title: '人脸活体检测',
    headerImg: huoti,
    desc: '在指定动作交互或者静默无动作的场景下，均可判断当前用户进行人脸识别的操作是否存在作弊行为，识别业务场景中的用户是否为“真人”',
    funcIntro: '通过多种技术手段，综合判断输入的人脸图像是否为真人。若检测到照片、海报、视频等虚假人脸，则无法通过身份认证，能有效降低身份被冒用带来的多种风险',
    funcImg: r_renlianhuoti,
    funcGif: g_renlianhuoti,
    productAdv: [
      {
        title:'准确可靠',
        desc: '每日百万级别线上数据验证，识别精度达99.9%以上',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '实时识别、支持高并发计算，提供高可用性保证',
        icon: keyong
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂场景，可识别业务场景中的用户是否为“真人”',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '身份认证',
        content: '结合活体验证，确认用户真身，相比传统身份验证更加安全高效',
        icon: 'icongerenzhuli',
        bg: h_shenfen
      },
      {
        name: '安防检测',
        content: '适用于有重点安保需求的场所，可有效确认来访者身份及其真实性，防止违规入侵',
        icon: 'iconanfangjiance',
        bg: h_anfang
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/face/attribute',
        name: '人脸属性分析'
      },
      {
        path: '/face/towards',
        name: '人脸朝向分析'
      }
    ]
  },

  // 文字识别
  {
    path: 'driver',
    title: '驾驶证识别',
    headerImg: jiashizheng,
    desc: '支持中国大陆驾驶证全字段识别，可用于用户注册、信息录入等场景',
    funcIntro: '',
    funcImg: r_jiashi,
    funcGif: g_jiashi,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托出行领域的海量垂直数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂情况，如反光、倾斜、模糊等，具备极强的可用性',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '注册信息录入',
        content: '对于需要留存驾驶证照信息的注册流程，可以提高录入效率，减少输入错误',
        icon: 'iconzhucebangka',
        bg: zucexinxi
      },
      {
        name: '用户凭证登录',
        content: '通过实时识别证件信息，实现无输入登录。以用户身份信息进行验证，预防违规冒认行为，提高安全性',
        icon: 'iconwenzhangzhuanxiezhushou',
        bg: yonghupinzheng
      },
      {
        name: '用户资质鉴别',
        content: '识别驾驶证上的有效期、有效类型等关键字段，鉴别加盟者和合作商的资质，及时发现潜在风险',
        icon: 'iconrenliziyuanguanli',
        bg: yonghujianbie
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/IDcard',
        name: '身份证识别 '
      },
      {
        path: '/character/travel',
        name: '行驶证识别'
      }
    ]
  },
  {
    path: 'travel',
    title: '行驶证识别',
    headerImg: xingshi,
    desc: '支持中国大陆行驶证全字段识别，可用于用户注册、信息录入等场景',
    funcIntro: '',
    funcImg: r_xingshizheng,
    funcGif: g_xingshi,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂情况，如反光、倾斜、模糊等，具备极强的可用性',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '注册信息录入',
        content: '对于需要留存行驶证证照信息的注册流程，通过OCR识别可以有效提高录入效率，减少手工输入错误，精简录入流程，优化用户体验',
        icon: 'iconzhucebangka',
        bg: zucexinxi
      },
      {
        name: '用户凭证登录',
        content: '通过实时识别证件信息，实现无输入登录。以用户身份信息进行验证，预防违规冒认行为，提高安全性',
        icon: 'iconwenzhangzhuanxiezhushou',
        bg: yonghupinzheng
      },
      {
        name: '用户资质鉴别',
        content: '识别行驶证上的有效期，有效类型等关键字段，鉴别加盟者，合作商户，企业等资质，及时发现潜在风险，为商业合作保驾护航',
        icon: 'iconrenliziyuanguanli',
        bg: yonghujianbie
      }
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/IDcard',
        name: '身份证识别 '
      },
      {
        path: '/character/driver',
        name: '驾驶证识别'
      }
    ]
  },
  {
    path: 'IDcard',
    title: '身份证识别',
    headerImg: shenfen,
    desc: '支持对中国大陆二代居民身份证正反面的全字段识别',
    funcIntro: '',
    funcImg: r_shenfenzheng,
    funcGif: g_shenfenzheng,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂情况，如反光、倾斜、模糊等，具备极强的可用性',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '客户身份认证',
        content: '适用于互联网金融、电商等需要验证用户真实身份的行业，通过身份证识别录入客户身份信息，可配合其他形式的身份认证，确保交易安全',
        icon: 'iconyonghuhuaxiang',
        bg: kehushenfen
      },
      {
        name: '人力资源管理',
        content: '通过识别和录入身份证件信息，确认员工身份，方便企业人力资源管理，提高管理效率',
        icon: 'iconrenliziyuanguanli',
        bg: b_renliziyuan
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/bank',
        name: '银行卡识别 '
      },
      {
        path: '/character/driver',
        name: '驾驶证识别'
      }
    ]
  },
  {
    path: 'bank',
    title: '银行卡识别',
    headerImg: yinhangka,
    desc: '支持对主流银行卡的卡号识别，并返回发卡行和卡片类型信息',
    funcIntro: '',
    funcImg: r_yinhangka,
    funcGif: g_yinhangka,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂情况，如反光、倾斜、模糊等，具备极强的可用性',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '身份认证',
        content: '自动识别银行卡号码，确认交易账号，配合多种方式的身份认证，确保交易的安全',
        icon: 'iconbiaotishengcheng',
        bg: shenfenrenzheng
      },
      {
        name: '人力资源管理',
        content: '企业薪酬福利需通过银行卡发放，自动识别和录入银行卡证件信息，可以有效提高人力资源管理工作效率',
        icon: 'iconrenliziyuanguanli',
        bg: renliziyuan
      },
      {
        name: '注册绑卡',
        content: '适用于电商等需要用户绑卡注册的行业，使用银行卡进行识别录入，提升效率和体验',
        icon: 'iconzhucebangka',
        bg: zucebangka
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/IDcard',
        name: '身份证识别 '
      },
      {
        path: '/character/business',
        name: '营业执照识别'
      }
    ]
  },
  {
    path: 'plate',
    title: '车牌识别',
    headerImg: chepai,
    desc: '对多种场景下的汽车车牌进行检测识别，可服务于通过汽车号牌进行车辆管理和车主身份认证的业务场景',
    funcIntro: '',
    funcImg: r_chepai,
    funcGif: g_chepai,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'适应性强',
        desc: '适应各种实际应用中的复杂情况，如反光、倾斜、模糊等，具备极强的可用性',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '车辆智能管理',
        content: '可用于车库、充电站、汽修点等汽车密集场所，支持通过车牌识别和校验来区分用户，提高车位利用效率，帮助实现自动化管理',
        icon: 'iconcheliangzhinengguanli',
        bg: cheliangguanli
      },
      {
        name: '车主身份认证',
        content: '通过识别汽车号牌并结合其他形式的身份认证，预防违规冒认行为，提高安全性',
        icon: 'iconrenjijiaohuxitong',
        bg: chezhushenfen
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/driver',
        name: '驾驶证识别 '
      },
      {
        path: '/character/travel',
        name: '行驶证识别'
      }
    ]
  },
  {
    path: 'QRcode',
    title: '二维码识别',
    funcImg: r_qrcode,
    headerImg: QRcode,
    desc: '能够识别出二维码中相对应的内容',
    funcIntro: '识别商品、货柜、单据的二维码，输出对应内容，快速录入二维码信息，便于后续跟踪和查询 ',
    funcGif: g_qrcode,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'个性化定制',
        desc: '可配合用户的个性化场景及业务需求，灵活组合各项文字识别功能，打造专用解决方案',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '仓储管理登记',
        content: '适用于货物仓储登记、发货登记等场景，可通过OCR技术识别和录入数据，方便制造业、零售业等的日常管理',
        icon: 'iconcangchuguanlidenglu',
        bg: changkuguanli
      },
      {
        name: '单据进度查询',
        content: '通过二维码跟踪订单，借助OCR技术提高录入效率，降低人工成本，适用于物流运输等行业',
        icon: 'icondanjujinduchaxun',
        bg: danjujindu
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/QRcode',
        name: 'VIN码识别 '
      },
      {
        path: '/character/Barcode',
        name: '条形码识别'
      }
    ]
  },
  {
    path: 'Barcode',
    title: '条形码识别',
    headerImg: Barcode,
    funcImg: r_barcode,
    funcGif: g_barcode,
    desc: '能够识别出条形码中相对应的内容',
    funcIntro: '识别货品或单据的条形码，输出对应内容，可快速录入条码信息，便于后续查询',
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'个性化定制',
        desc: '可配合用户的个性化场景及业务需求，灵活组合各项文字识别功能，打造专用解决方案',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '仓储管理登记',
        content: '适用于货物仓储登记、发货登记等场景，可通过OCR技术识别和录入数据，方便制造业、零售业等的日常管理',
        icon: 'iconcangchuguanlidenglu',
        bg: changkuguanli
      },
      {
        name: '数据录入和查询',
        content: '自动识别条形码内容，能快速将商品、单据上的条形码识别并录入，后续可多次凭码查询，赋能市场监管、快递物流等领域，大幅提高工作效率',
        icon: 'iconshujuluruhechaxun',
        bg: b_shujuluru
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/VINcode',
        name: 'VIN码识别 '
      },
      {
        path: '/character/QRcode',
        name: '二维码识别'
      }
    ]
  },
  {
    path: 'VINcode',
    title: 'VIN码识别',
    headerImg: VINcode,
    desc: '对车辆车架上、挡风玻璃上的VIN码进行识别',
    funcIntro: '支持多场景下的VIN码识别，应用于车辆智能监测和车辆智能管理等领域',
    funcImg: g_vincode,
    funcGif: g_vincode,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'个性化定制',
        desc: '可配合用户的个性化场景及业务需求，灵活组合各项文字识别功能，打造专用解决方案',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '车辆信息入库',
        content: '通过OCR技术识别和录入VIN码，方便管理，适用于车厂、4S店对车辆进行信息输入和订单检测，以及交通、公安等部门对社会车辆进行管理',
        icon: 'iconcheliangxinxiruku',
        bg: cheliangxinxi
      },
      {
        name: '车辆智能管理',
        content: '可用于车库、充电站、汽修点等汽车密集场所，通过VIN码识别出车辆，提高车位利用效率，辅助自动化管理',
        icon: 'iconcheliangzhinengguanli',
        bg: cheliangguanli
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/plate',
        name: '车牌识别'
      },
      {
        path: '/character/driver',
        name: '驾驶证识别'
      }
    ]
  },
  {
    path: 'customOCR',
    title: '自定义模版识别',
    headerImg: customOCR,
    desc: '支持用户自定义识别模版、指定关键字段识别',
    funcIntro: '支持有版式票据和无版式文档的内容识别，可实现特定格式的自动识别和结构化提取，打造定制化的文字识别服务',
    funcImg: r_zidingyi,
    funcGif: g_zidingyi,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'个性化定制',
        desc: '可配合用户的个性化场景及业务需求，对有版式票据、无版式文档等均可提供定制化、个性化的专用文字识别服务',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '无纸化文档管理',
        content: '针对客户需求，可提供办公常用文档的定制OCR服务，通过文本内容自动识别和提取，实现文书材料的无纸化管理',
        icon: 'iconbangongwendangdianzihuaguanli',
        bg: wuzhihuaguanliu
      },
      {
        name: '内容留存及审核',
        content: '医疗机构、金融机构、各界企业在日常业务中需进行大量资质审核、材料录入留存工作，通过定制OCR服务，能大幅节省人力抄录的成本，提高办公效率',
        icon: 'iconneirongfenxiheshenhe',
        bg: neirongcunliu
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/bank',
        name: '银行卡识别'
      },
      {
        path: '/character/document',
        name: '通用文档识别'
      }
    ]
  },
  {
    path: 'document',
    title: '通用文档识别',
    headerImg: tongyongwendang,
    desc: '支持对篇章级印刷文档等的文字识别，能自动识别出文本内容，可适应各种文本录入的场景',
    funcIntro: '支持多场景下的通用文档识别，对扫描图片、拍摄图片、网页截图等文档图片均能有力支持',
    funcImg: r_tongyong,
    funcGif: g_tongyong,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'个性化定制',
        desc: '对各种场景下的文档图片均能实现较好的支持，不限文章长短、体裁，可满足各种应用需求',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '办公文档电子化管理',
        content: '对文件、报表、病历等文档材料进行文字识别与提取，加速实现文档电子化，降低人力成本，提高效率',
        icon: 'iconbangongwendangdianzihuaguanli',
        bg: bangongwendang
      },
      {
        name: '内容分析和审核',
        content: '协助UGC、媒体、出版等行业进行文字内容审核，通过OCR技术进行批量化内容提取，提升审核效率',
        icon: 'iconneirongfenxiheshenhe',
        bg: neirongshenhe
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/business',
        name: '营业执照识别'
      },
      {
        path: '/character/customOCR',
        name: '定制OCR'
      }
    ]
  },
  {
    path: 'business',
    title: '营业执照识别',
    headerImg: yinyezhizhao,
    desc: '支持对中国大陆竖版营业执照的识别，能快速提取关键字段信息，包括法人、地址、有效期、单位名称、证件编号、社会信用代码等',
    funcIntro: '识别营业执照关键字段，输出对应内容，可快速录入营业执照信息，实现信息入库，应用于资质审核等场景',
    funcImg: r_yingye,
    funcGif: g_yingye,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟业界领先技术，依托海量数据，致力于迭代优化，当前平均识别精度达到98%',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '识别速度快，结果即时可得。经业务实际应用验证，服务性能稳定，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'个性化定制',
        desc: '适应各种实际应用中的复杂情况，如反光、倾斜、模糊等，具备极强的可用性',
        icon: gexinghuadingzhi
      },
    ],
    recommondSence: [
      {
        name: '信息留存',
        content: '电商、O2O、招聘网站等平台接入合作商户时需录入营业执照信息。可通过OCR技术，快速实现信息入库，降低人工输入成本，提高工作效率',
        icon: 'iconxinxitoufang',
        bg: xinxiliucun
      },
      {
        name: '资质审核',
        content: '支持金融机构及各界企业，通过OCR掌握证照材料上的有效期、有效类型等关键字段，及时审核加盟者、合作商户的资质，可有效发现潜在风险，保障商业合作安全',
        icon: 'iconzizhishenhe',
        bg: zizhishenhe
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/character/bank',
        name: '银行卡识别'
      },
      {
        path: '/character/IDcard',
        name: '身份证识别'
      }
    ]
  },

  // 人体识别
  {
    path: 'keypoint',
    title: '人体关键点检测',
    headerImg: guanjiandian,
    desc: '定位人体骨骼关键点，描述人体的姿态',
    funcIntro: '检测人体的核心关键点，还原人体姿态和动作，对视频或图片、单人或多人场景均可支持',
    funcImg: r_rentiguanjian,
    funcGif: g_rentiguanjiandian,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟行业技术潮流，依托海量垂直数据，持续致力于优化迭代，识别准确率业界领先',
        icon: zhunquekekao
      },
      {
        title:'服务强稳定',
        desc: '应用于业务多个环节，久经实践检验，服务稳定成熟，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'多场景适用',
        desc: '克服遮挡、色差、过曝、过暗等干扰因素，可支持多人检测，满足多种不同场景需求',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '娱乐交互',
        content: '分析人体动作和姿态，在直播、游戏、拍照时可加入体感交互、动态贴图等趣味元素，增强娱乐性',
        icon: 'iconhudongyule',
        bg: yulejiaohu
      },
      {
        name: '教学辅助',
        content: '在舞蹈、体育等项目的教学过程中，检测人体关键点、分析师生动作姿态，能有效辅助教学和训练',
        icon:'iconjiaoxuefuzhu',
        bg: jiaoxuefuzhu
      },
      {
        name: '安防监测',
        content: '基于人体关键点检测技术，识别出异常人员及其行为动作即可发出预警，辅助安防和管控',
        icon: 'iconanfangjiance',
        bg: bukongzhencha
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网中便可实现功能，保障数据私密性，提高安全性'
      },
    ],
    more: [
      {
        path: '/body/Re-ID',
        name: '行人Re-ID'
      },
      {
        path: '/body/behavior',
        name: '行为检测'
      }
    ]
  },
  {
    path: 'Re-ID',
    title: '行人Re-ID',
    headerImg: ReID,
    desc: '即行人重识别技术，实现跨多个设备从图像中识别出指定的行人目标',
    funcIntro: '针对给定的某个行人目标，可在不同摄像设备、不同时间段所拍摄的图像中，准确有效地识别该目标并返回其所在的位置',
    funcImg: r_reid,
    funcGif: g_id,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟行业技术潮流，依托海量垂直数据，持续致力于优化迭代，识别准确率业界领先',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '久经实践检验，服务稳定成熟，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'多场景适用',
        desc: '克服遮挡、色差、过曝、过暗等干扰因素，可支持多人检测，满足多种不同场景需求',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '侦查搜索',
        content: '广泛应用于安防刑侦领域，可在海量监测视频中快速定位目标人。大幅节省人力查找的工作量，提高侦查效率',
        icon: 'iconwenbensousuo',
        bg: zhenchasousuo
      },
      {
        name: '行为分析',
        content: '利用行人重识别技术，可对特定目标的行动轨迹、停留时长等进行综合分析，有助于深入研究用户行为',
        icon: 'iconhangweifenxi',
        bg: xingweifenxi
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网中便可实现功能，保障数据私密性，提高安全性'
      },
    ],
    more: [
      {
        path: '/body/keypoint',
        name: '人体关键点检测'
      },
      {
        path: '/body/behavior',
        name: '行为检测'
      }
    ]
  },
  {
    path: 'behavior',
    title: '行为检测',
    headerImg: xingwei,
    desc: '结合具体场景，检测图像中人的行为动作',
    funcIntro: '可根据用户实际需求，定制化地检测出特定行为，为提醒、预警和管控等后续流程提供依据，适用于服务管控，安防监测等场景',
    funcImg: r_xingwei,
    funcGif: g_xingwei,
    productAdv: [
      {
        title:'准确可靠',
        desc: '已落地到业务多个环节，经业务线上数据验证，识别准确度可达98%',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '有效排除不同拍摄条件下形成的多种噪声，场景适应能力强，响应速度快',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '服务管控',
        content: '通过图像检测从业人员的行为，判断其服务是否符合规范。有助于及时纠正违规行为，提升服务水平和工作效率，优化用户体验',
        icon: 'iconfuwuguankong',
        bg: fuzhujiashi
      },
      {
        name: '安防监测',
        content: '充分利用监控视频，通过行为检测技术及时发现高危、违规行为，发出预警，使安保管控工作更高效便捷，减少安全事故的发生',
        icon: 'iconanfangjiance',
        bg: anfangjiankong_bg
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/body/Re-ID',
        name: '行人Re-ID'
      },
      {
        path: '/body/gesture',
        name: '手势识别'
      }
    ]
  },
  {
    path: 'gesture',
    title: '手势识别',
    headerImg: shoushi,
    desc: '支持静态和动态的常见手势识别，可应用于智能汽车、智能家居产品等设备的交互控制',
    funcIntro: '检测图片或视频上手的位置，识别手势内容，返回所有手部矩形框位置，并输出手势含义标签',
    funcImg: r_shoushi,
    funcGif: g_shoushi,
    productAdv: [
      {
        title:'识别精度高',
        desc: '紧跟行业技术潮流，依托海量垂直数据，持续致力于优化迭代，识别准确率业界领先',
        icon: zhunquekekao
      },
      {
        title:'服务稳定',
        desc: '久经实践检验，服务稳定成熟，可支持高并发计算',
        icon: wendinkekao
      },
      {
        title:'多场景适用',
        desc: '克服遮挡、色差、过曝、过暗等干扰因素，可支持多人检测，满足多种不同场景需求',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '交互控制',
        content: '在智能家居、智能汽车等产品中可以通过手势唤醒、操纵设备，为用户提供更多的交互形式和更好的用户体验',
        icon: 'iconjiaohukongzhi',
        bg: jiaohukongzhi
      },
      {
        name: '娱乐特效',
        content: '对视频、图片、直播中的人体进行手势识别，并添加相应的特效、贴图等，提供更富有趣味性的娱乐形式',
        icon: 'iconhudongyule',
        bg: yuletexiao
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '在客户内网中便可实现功能，保障数据私密性，提高安全性'
      },
    ],
    more: [
      {
        path: '/body/keypoint',
        name: '人体关键点检测'
      },
      {
        path: '/body/behavior',
        name: '行为检测'
      }
    ]
  },

  // 内容审核
  {
    path: 'yellow',
    title: '涉黄图像鉴别',
    headerImg: h_shehuangjiance,
    desc: '人工智能鉴黄技术，智能识别图片和视频中的色情和性感内容',
    funcImg: r_shehuang,
    funcIntro: '',
    funcGif: g_shehuang,
    productAdv: [
      {
        title:'准确可靠',
        desc: '已落地到业务多个环节，经业务线上数据验证，识别准确度可达98%',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '场景适应能力强，可因应不同业务需求提供定制化审核服务，响应速度快、性能稳定',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '图片内容审核',
        content: '通过人工智能鉴黄技术迅速实现海量UGC图片内容的分流，大幅节约人工审核成本',
        icon: 'icontupianshenhe',
        bg: r_tupianneirong
      },
      {
        name: '视频／直播审核',
        content: '对视频、直播等内容可实现实时自动审核，保证标准统一，及时过滤违规内容',
        icon: 'iconshipinzhiboshenhe',
        bg: r_shipin
      },
      {
        name: '社交／电商平台监管',
        content: '在社交、电商类应用中，全面高效进行图像审核，有效降低平台运营成本和业务违规风险',
        icon: 'iconshejiaodianshangpingtaijianguan',
        bg: r_shejiao
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/review/political',
        name: '涉政图像鉴别'
      },
      {
        path: '/review/custom',
        name: '自定义内容检测'
      }
    ]
  },
  {
    path: 'political',
    title: '涉政图像鉴别',
    headerImg: h_shezhen,
    desc: '识别政治人物和敏感政治事件场景，帮助UGC、IM、BBS类产品在敏感时期规避相关风险',
    funcIntro: '',
    funcImg: r_shezhen,
    funcGif: g_shezhen,
    productAdv: [
      {
        title:'准确可靠',
        desc: '已落地业务多个环节，经业务线上数据验证，识别准确度可达98%',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '有效排除不同拍摄条件下形成的多种噪声，场景适应能力强，响应速度快',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '图片审核',
        content: '在海量UGC图片中准确迅速定位不良内容，告别传统人工审核时代',
        icon: 'icontupianshenhe',
        bg: tupianneirong
      },
      {
        name: '视频／直播审核',
        content: '视频直播等场景中采用政治敏感识别技术，实现对图片、视频、直播等内容的实时自动审核，标准统一，简单快速，成本低廉',
        icon: 'iconshipinzhiboshenhe',
        bg: shiping
      },
      {
        name: '社交／电商平台监管',
        content: '在社交、电商类应用中，全面高效进行图像审核，有效降低平台运营成本和业务违规风险',
        icon: 'iconshejiaodianshangpingtaijianguan',
        bg: b_shejiao
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '在客户内网中便可实现功能，保障数据私密性，提高安全性'
      },
    ],
    more: [
      {
        path: '/review/yellow',
        name: '涉黄图像鉴别'
      },
      {
        path: '/review/custom',
        name: '自定义内容检测'
      }
    ]
  },
  {
    path: 'custom',
    title: '自定义内容检测',
    headerImg: h_zidingyi,
    desc: '可根据客户在不同场景下的需求，提供自定义内容检测服务，有效提升服务效率',
    funcIntro: '',
    funcImg: r_neirong,
    funcGif: g_neirong,
    productAdv: [
      {
        title:'准确可靠',
        desc: '已落地到业务多个环节，经业务线上数据验证，识别准确度可达98%',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '有效排除不同拍摄条件下形成的多种噪声，场景适应能力强，响应速度快',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '图片内容审核',
        content: '在海量UGC图片中准确迅速定位不符合要求的自定义内容，告别传统人工审核时代',
        icon: 'icontupianshenhe',
        bg: tupianneirong
      },
      {
        name: '视频／直播审核',
        content: '在直播等场景中，实现对自定义图片、视频、直播等内容的实时自动审核，标准统一，简单快速，成本低廉',
        icon: 'iconshipinzhiboshenhe',
        bg: shiping
      },
      {
        name: '社交／电商平台监管',
        content: '在社交，电商类应用中，实现自定义内容的屏蔽与监管，有效甄别图片内容中的相关违规信息，有效减少人力成本并降低业务违规风险',
        icon: 'iconshejiaodianshangpingtaijianguan',
        bg: b_shejiao
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '/review/yellow',
        name: '涉黄图像鉴别'
      },
      {
        path: '/review/political',
        name: '涉政图像鉴别'
      }
    ]
  },

  // 车辆分析
  {
    path: 'model-reco',
    title: '车型识别',
    headerImg: chexing,
    desc: '依据车辆外观特征，识别出车系、品牌、车型、颜色等信息',
    funcIntro: '在输入图像中检测车辆目标，并以结构化方式存储、展示车辆属性信息，依据具体场景和需求，可支持细分至车辆类别、品牌、车型等不同颗粒度',
    funcImg: r_chexing,
    funcGif: g_chexing,
    productAdv: [
      {
        title:'准确可靠',
        desc: '已落地到业务多个环节，经业务线上数据验证，识别准确度可达98%',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '有效排除不同拍摄条件下形成的多种噪声，场景适应能力强，响应速度快',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '图库管理',
        content: '拍照识图，返回车型相关信息，可快速便捷地实现汽车图库分类标记管理',
        icon: 'icontukuguanli',
        bg: tukuguanli
      },
      {
        name: '智慧交通',
        content: '借助道路、卡口、车库等监控视频，自动识别车型信息，辅助判断路面情况，及时高效进行交通调度',
        icon: 'iconzhihuijiaotong',
        bg: r_zhihuijiaotong
      },
      {
        name: '布控侦查',
        content: '识别并结构化存储车辆属性信息，便于凭图片或属性标签筛查、搜索违法车辆，大幅提高侦查效率',
        icon: 'iconchanpinzhijian',
        bg: bukongzhencha
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '',
        name: '目标跟踪'
      },
      {
        path: '',
        name: '目标检测'
      }
    ]
  },
  {
    path: 'car-detect',
    title: '车辆检测',
    headerImg: cheliangjiance,
    desc: '识别图像中所有车辆的类型和位置，支持对汽车、面包车、卡车、公交车、摩托车、自行车、三轮车、叉车等多类车辆的识别统计，返回含有坐标、车辆类型、识别框及车辆图像的检测结果',
    funcIntro: '检测图像中所有车辆，支持对汽车、面包车、卡车、公交车、摩托车、自行车、三轮车、叉车等多类车辆的识别，获取具体车辆类型和坐标位置',
    funcImg: r_cheliangjiance,
    funcGif: g_cheliang,
    productAdv: [
      {
        title:'识别精准',
        desc: '基于业界领先的算法技术，能实现对目标物的快速准确识别',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '有效排除不同拍摄条件下形成的多种噪声，场景适应能力强，响应速度快',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '智慧停车场',
        content: '面向室外停车场景，依托车辆检测辅助管理员智能统计停车场空余车位，实现智慧化管理',
        icon: 'iconzhihuijiaotong',
        bg: zhihuitingche
      },
      {
        name: '违规停车管理',
        content: '面向城市道路、园区等公共场所，基于车辆检测可实现对违停车辆的智能识别，并统计车辆类型及数量',
        icon: 'iconweiguitingcheguanli',
        bg: weiguitingche
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '部署至客户本地服务器，在客户内网实现功能，更好地保障客户数据安全'
      },
    ],
    more: [
      {
        path: '',
        name: '目标跟踪'
      },
      {
        path: '',
        name: '目标检测'
      }
    ]
  },
  {
    path: 'count',
    title: '车流统计',
    headerImg: cheliu,
    desc: '对拍摄区域内的各类车辆目标进行检测和追踪，计算各类车辆（包括小汽车、卡车、巴士、摩托车、三轮车）的驶入、驶出数量，实现动态车流量统计',
    funcIntro: '在拍摄区域内根据车辆行驶轨迹判断驶入/驶出行为，从而统计该区域当前承载的车辆数量及一段时间内的车流总量，全面客观反映该区域的交通流量状况',
    funcImg: r_cheliu,
    funcGif: r_cheliu,
    productAdv: [
      {
        title:'准确可靠',
        desc: '已落地到业务多个环节，经业务线上数据验证，识别准确度可达98%',
        icon: zhunquekekao
      },
      {
        title:'可用性高',
        desc: '有效排除不同拍摄条件下形成的多种噪声，场景适应能力强，响应速度快',
        icon: keyong
      },
      {
        title:'多种支持',
        desc: '可提供API/SDK支持，亦能提供私有化部署服务，保障用户信息安全',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '智慧交通',
        content: '利用道路、卡口等监控视频进行区域车流量统计，及时掌握区域路况，辅助进行交通指挥与调度工作，提升交通管理智能化水平',
        icon: 'iconzhihuijiaotong',
        bg: r_zhihuijiaotong
      },
      {
        name: '智慧出行',
        content: '根据不同地区的车流量统计信息，结合大数据分析，准确捕捉出行需求热点区域，辅助进行运力调配，优化出行效率和体验',
        icon: 'iconzhihuichuhang',
        bg: r_zhihuichuxing
      },
      {
        name: '布控侦查',
        content: '有效追踪车辆目标，还原监测区域内指定车辆的行驶轨迹，方便搜索定位违法车辆以及相关取证工作，可大幅提高侦查效率',
        icon: 'iconchanpinzhijian',
        bg: bukongzhencha
      },
    ],
    serviceType: [
      {
        name: '云端服务',
        content: '提供云端服务API，使用方法简明快捷，有效降低开发成本'
      },
      {
        name: '离线识别SDK',
        content: '针对移动设备上的识别需求，可提供SDK方案，离线实现功能'
      },
      {
        name: '私有化部署',
        content: '在客户内网中便可实现功能，保障数据私密性，提高安全性'
      },
    ],
    more: [
      {
        path: '/car/model-reco',
        name: '车型识别'
      },
    ]
  },
]

// 自然语言处理二级页面
export const nlpdata = [
 /*  {
    path: 'label',
    title: '标注平台',
    desc: '基于滴滴NLP平台输出的强大算法，为用户提供高准确率的备选答案，有助于用户快速准确的标注海量数据',
    funcIntro: '对给定文本中的文法错误进行纠错，包括长短文本，语音识别结果等多种内容，可应用于人机对话、内容审核等方面',
    productAdv: [
      {
        title:'专业易用',
        desc: '数据审核机制严格，质检分层级操作，风控系统成熟，有效保证服务质量',
        icon: zhunquekekao
      },
      {
        title:'保密可靠',
        desc: '基于完善的方案将数据隔离，防止敏感性数据外流，保密性高',
        icon: keyong
      },
      {
        title:'海量数据积累',
        desc: '标注方案提前制定，标注工具领先，百万级数据标注处理能力，精准快速回收数据',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '智能语音',
        content: '采集多种场景语料，将语音转写为文字进行标注操作。可将语料通过情绪分析、情感识别、文本相关等方式进行加工处理，为语音识别(ASR)、语音合成(TTS)等提供基本数据',
        icon: jiaotong
      },
      {
        name: 'OCR数据',
        content: '车牌、彩票、名片、身份证、银行卡、手写轨迹等多种垂类图片采集及内容识别提取标注，提高多种场景文字识别准确率',
        icon: tuku
      },
      {
        name: '电子商务数据',
        content: '采集分析多种电子商务数据，如商品价格信息、商品销量、商品口碑等。有助于了解商品的销售情况及用户评价信息',
        icon: shejiao
      },
      {
        name: '用户反馈标注',
        content: '采集分析新闻、论坛、微博、贴吧等社交媒体中用户发布的数据，如文章，点赞，评论等，有助于快速准确获取网络用户反馈数据，监控网络用户用户反馈',
        icon: liuliang
      }
    ],
    more: [
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      },
      {
        path: '/NLP/entity',
        name: '命名实体识别'
      }
    ]
  }, */
  {
    path: 'sentiment',
    title: '情感识别',
    header: qingganshibie,
    desc: '对含主观信息的文本进行情感极性判断，主要包括正向、负向、中性',
    funcIntro: '以NLP能力为技术基础，深度理解文本语义，分析和识别文本中表达的情感状态',
    funcImg: r_qinggan,
    productAdv: [
      {
        title:'精度高',
        desc: '基于大数据和深度学习训练，自动学习深层次的语义及语序特征，具备较强的泛化能力，情感倾向性分析精度高',
        icon: jingdugao
      },
      {
        title:'效果优',
        desc: '在多个垂类上（汽车、餐饮、酒店等）情感倾向性分析准确率高',
        icon: xiaoguoyou
      },
      {
        title:'稳定可靠',
        desc: '服务可用性高，根据调用量动态调整，支持上亿量级的调用及数据监测',
        icon: wendinkekao
      },
    ],
    recommondSence: [
      {
        name: '评论分析与决策',
        content: '全方位将用户对产品的评论及观点进行情感倾向分析，并提供分析结果，有助于进行决策',
        icon: 'iconpinglunfenxiyujuece',
        bg: pinlunfenxi
      },
      {
        name: '用户反馈分析',
        content: '通过对需要用户反馈分析的实时文字数据流进行情感倾向性检测，掌握用户对热点信息的情感倾向性变化',
        icon: 'iconyuqingfenxi',
        bg: yuqingjiankong
      },
      {
        name: '口碑分析',
        content: '对网络中各个行业的口碑评价进行多维分析，及时有效的掌握用户心理，有助于对行业服务作出调整',
        icon: 'iconkoubeifenxi',
        bg: koubeifenxi
      },
    ],
    more: [
      {
        path: '/NLP/emotion',
        name: '情绪识别'
      },
      {
        path: '/NLP/similarity',
        name: '语义相似度'
      }
    ]
  },
  {
    path: 'emotion',
    title: '情绪识别',
    header: qingxushibie,
    desc: '识别对话中的用户情绪，提供合理的反馈',
    funcIntro: '对文本中包含的用户情绪进行判断并作出针对性反馈，有助于提升用户体验',
    funcImg: r_qingxu,
    productAdv: [
      {
        title:'专业化',
        desc: '基于专业的对话系统，可准确分析并理解多种场景中包含的主要情绪类型及分布',
        icon: zhuanyehua
      },
      {
        title:'精度高',
        desc: '基于大数据和深度学习，可自动学习词义及词序关系，具备较强的泛化能力，情绪识别精度高',
        icon: jingdugao
      },
      {
        title:'效果优',
        desc: '在多个垂类（闲聊对话、导航交互对话等）中，识别准确率均已达较高水平',
        icon: xiaoguoyou
      },
    ],
    recommondSence: [
      {
        name: '闲聊',
        content: '可识别用户聊天时的情绪，使机器人产品选择出更匹配用户情绪的文本进行回复',
        icon: 'iconxianliao',
        bg: xianliao
      },
      {
        name: '智能客服',
        content: '可识别用户情绪并合理回复，提升客户体验',
        icon: 'iconzhinengkefu',
        bg: zhinengkefu
      },
      {
        name: '场景对话',
        content: '在某些复杂场景对话流程（机票定制，天气查询）中，可根据不同的对话情绪，选择不同的回答策略进行答复',
        icon: 'iconchangjingduihua',
        bg: changjingduihua
      }
    ],
    more: [
      {
        path: '/NLP/sentiment',
        name: '情感识别'
      },
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      }
    ]
  },
  {
    path: 'correction',
    title: '中文纠错',
    desc: '可将中文文本内容中的错别字、冗余字等语义错误进行纠正',
    header: jiucuo,
    funcIntro: '对给定文本中的文法错误进行纠错，包括长短文本，语音识别结果等多种内容，可应用于人机对话、内容审核等方面',
    funcImg: r_zhongwenjiucuo,
    productAdv: [
      {
        title:'高稳定性',
        desc: '基于海量训练样本，挖掘用户行为，在复杂多变的应用场景下也可保证效果稳定',
        icon: zhunquekekao,
      },
      {
        title:'语料丰富',
        desc: '基于长期海量数据积累，融合各类知识库、新词资源等，语料资源丰富',
        icon: keyong
      },
      {
        title:'精度高',
        desc: '结合多种算法优势，数据利用率高，识别准确率高，有助于纠错效果',
        icon: jingdugao
      },
    ],
    recommondSence: [
      {
        name: '文章撰写助手',
        content: '能够自动检查输入文本并提示错别字情况，降低写作错误率，提高写作质量，有助于提升写作效率，提升阅读体验，可应用于内容写作平台等场景',
        icon: 'iconwenzhangzhuanxiezhushou',
        bg: wenzhangjuanxie
      },
      {
        name: '搜索纠错',
        content: '可搜索分析query的形式和特征并自动纠正，提示用户输入错误，提供更符合用户需求的搜索结果',
        icon: 'iconsousuojiucuo',
        bg: sousuojiucuo
      },
      {
        name: '输入法纠错',
        content: '将文本纠错应用到输入法中，可自动修正输入文本中的错别字，传递纠错后的正确文本，有助于提高准确率，提升产品体验',
        icon: 'iconshurufajiucuo',
        bg: shurufajiucuo
      },
    ],
    more: [
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      },
      {
        path: '/NLP/segment',
        name: '中文分词'
      }
    ]
  },
  {
    path: 'segment',
    title: '中文分词',
    header: fenci,
    desc: '可将连续的自然语言文本，切分成具有语义合理性和完整性的词汇序列',
    funcIntro: '基于智能语义理解等技术，将连续的自然语言文本，切分成具有语义合理性和完整性的词汇序列，同时基于海量数据实时优化模型，提升精准度',
    funcImg: r_zhongwenfenci,
    productAdv: [
      {
        title:'多粒度支持',
        desc: '包括基本语义粒度，更小的检索粒度以及扩展检索粒度',
        icon: duolidu
      },
      {
        title:'语料规模大',
        desc: '基于滴滴核心业务积累，语料数据丰富，在通用领域处于领先水平',
        icon: yuliaoguimo
      },
      {
        title:'灵活性高',
        desc: '可在短时间内完成海量文本分析，为后续挖掘文本内容提供技术支持',
        icon: linhuoxingao
      },
    ],
    recommondSence: [
      {
        name: '文本搜索',
        content: '结合标注平台，可快速搭建搜索模块，提升搜索准确性以及审核质量',
        icon: 'iconwenbensousuo',
        bg: wenbenshousuo
      },
      {
        name: '机器翻译',
        content: '使用准确的分词方案可以解决因为歧义导致翻译错误的情况出现，大大提升文本翻译的准确性',
        icon: 'iconzhilingjiexi',
        bg: zhilinjiexi400
      },
      {
        name: '自动问答',
        content: '结合命名实体，可根据问题中的分词和实体识别结果，理解发问意图，快速搭建问答场景应用',
        icon: 'iconzidongwenda',
        bg: zidongwenda
      },
    ],
    more: [
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      },
      {
        path: '/NLP/entity',
        name: '命名实体识别'
      }
    ]
  },
  {
    path: 'entity',
    title: '命名实体识别',
    header: minmingshibie,
    desc: '识别自然语言文本中具有特定意义的实体，目前主要包括人名、地名、机构名、时间日期等',
    funcIntro: '识别自然语言文本中具有特定意义的实体，精准识别人名、地名、机构名、时间日期等相关实体',
    funcImg: r_minmingshiti,
    productAdv: [
      {
        title:'涵盖范围广',
        desc: '历经多个业务场景锤炼，覆盖多个领域，如交通，电商等',
        icon: fanweiguang
      },
      {
        title:'海量数据建模',
        desc: '依托百亿级别的数据反馈，模型具有高精度及良好的适配性',
        icon: hailiangshuju
      },
      {
        title:'标签丰富',
        desc: '根据不同的细分领域，构建细粒度标签，实体类别丰富',
        icon: biaoqianfengfu
      },
    ],
    recommondSence: [
      {
        name: '文本搜索',
        content: '跟据文本内容，如关键字、语意等对文本集合进行检索、分类、过滤等，帮助用户快速查找到目标内容',
        icon: 'iconwenbensousuo',
        bg: wenbenshousuo
      },
      {
        name: '知识库构建',
        content: '根据实体之间、实体与关键词之间的关联，构建相关的知识库',
        icon: 'iconzhishikugoujian',
        bg: zhishikugoujian
      },
      {
        name: '用户反馈分析',
        content: '对网络中出现的文章、发帖等内容进行用户反馈分析，提供分词和词向量功能，辅助后续模型训练',
        icon: 'iconyuqingfenxi',
        bg: yuqingfenxi
      },
    ],
    more: [
      {
        path: '/NLP/similarity',
        name: '语义相似度'
      },
      {
        path: '/NLP/segment',
        name: '中文分词'
      }
    ]
  },
  {
    path: 'dataanony',
    title: '数据匿名',
    header: shujunimin,
    desc: '对公司原始文本中的敏感数据，如人名、电话、身份证等，进行匿名处理',
    funcIntro: '',
    funcImg: r_shujunimin,
    productAdv: [
      {
        title:'技术领先',
        desc: '领先于业界的技术团队，基于深度学习模型建立数据匿名体系',
        icon: jisulinxian
      },
      {
        title:'海量数据建模',
        desc: '基于滴滴大数据库训练模型，样本数据丰富',
        icon: hailiangshuju
      },
      {
        title:'覆盖全面',
        desc: '经公司大量业务落地验证，对多维度敏感词汇匿名',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '个人隐私保护',
        content: '可对公开信息，如网页、文章等，进行用户个人敏感信息匿名，包括人名、电话、身份证等信息，实现对个人隐私的保护',
        icon: 'icongerenyinsibaohu',
        bg: gerenyinsi
      },
      {
        name: '企业隐私保护',
        content: '可对企业涉及商业或客户安全的数据进行加密，包括人名、电话、身份证等信息，有效防止数据泄露，提高企业安全性',
        icon: 'iconqiyeyinsibaohu',
        bg: qiyeyinsi
      },
    ],
    more: [
      {
        path: '/NLP/sentiment',
        name: '情感识别'
      },
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      }
    ]
  },
  {
    path: 'summary',
    title: '文本摘要',
    header: wenbenzaiyao,
    desc: '可提取句子、词或短语等关键信息形成摘要结果',
    funcIntro: '',
    productAdv: [
      {
        title:'技术领先',
        desc: '深度学习技术自动抽取文章重要部分，经公司大量业务落地验证',
        icon: jisulinxian
      },
      {
        title:'海量数据建模',
        desc: '基于滴滴大数据库训练模型，样本数据丰富',
        icon: hailiangshuju
      },
      {
        title:'使用场景丰富',
        desc: '可满足用户在多种场景下的需求，并自定义摘要长度',
        icon: shiyingxing
      },
    ],
    recommondSence: [
      {
        name: '文章展示推送',
        content: '可对文章内容进行分析，提取文章重点内容向用户推送，有助于提升用户点击率，并使用户阅读效率大大提升',
        icon: 'iconwenzhangzhanshituisong',
        bg: wenzhangzhansi
      },
      {
        name: '标题生成',
        content: '可对文章进行语义分析，篇章结构分析，段落分布分析，得出语句重要性程度，总结核心内容，形成文章标题',
        icon: 'iconbiaotishengcheng',
        bg: biaotishengcheng
      }
    ],
    more: [
      {
        path: '/NLP/word_vector',
        name: '词向量'
      },
      {
        path: '/NLP/keyword',
        name: '关键词抽取 '
      }
    ]
  },
  {
    path: 'similarity',
    title: '语义相似度',
    header: yuyi,
    desc: '基于滴滴海量对话数据与标注数据形成的深度神经网络模型，对句子语义相似度进行计算',
    funcIntro: '基于滴滴海量对话数据和多业务场景验证，通过深度神经网络模型，为您提供高精度的语义相似度技术，赋能更多业务场景',
    funcImg: r_yuyixiangshi,
    productAdv: [
      {
        title:'技术领先',
        desc: '专业团队护航，算法领先，经公司大量实际业务落地验证',
        icon: jisulinxian
      },
      {
        title:'海量数据建模',
        desc: '基于滴滴大数据库训练模型，样本数据丰富',
        icon: hailiangshuju
      },
      {
        title:'准确率高',
        desc: '基于海量样本训练学习，形成高精度的语义相似度表示体系',
        icon: jingdugao
      },
    ],
    recommondSence: [
      {
        name: 'Query改写',
        content: '可对query的关键词进行抽取并替换为语义相似度高的词汇，有助于对原始query进行补充，得到丰富、准确的搜索结果',
        icon: 'iconquerygaixie',
        bg: gaixie
      },
      {
        name: '专名挖掘',
        content: '基于词语相关性计算，查找与目标专有名词相关性较高的词语，有助于扩大词典的词条数量，节省人力、优化应用',
        icon: 'iconzhuanmingwajue',
        bg: zhuanminwajue
      }
    ],
    more: [
      {
        path: '/NLP/word_vector',
        name: '词向量'
      },
      {
        path: '/NLP/correction',
        name: '中文纠错 '
      }
    ]
  },
  {
    path: 'word_vector',
    title: '词向量',
    header: cixiangliang,
    desc: '基于海量数据及深度神经网络技术，将文本中的词进行分布式表示，从而实现文本的可计算，有助于语义挖掘、相似度计算',
    funcIntro: '将自然语言中的词进行向量化表示，输入文本语料库，输出每个词的词向量表示',
    funcImg: r_cixiangliang,
    productAdv: [
      {
        title:'技术领先',
        desc: '领先于业界的专业深度学习技术，基于深度学习模型，建立词向量表示体系',
        icon: jisulinxian
      },
      {
        title:'海量数据',
        desc: '基于滴滴大数据库训练模型，样本数据丰富',
        icon: hailiangshuju
      },
      {
        title:'精确度高',
        desc: '经公司大量业务落地验证，词向量精度高，能够准确描述词和词之间的相似度',
        icon: jingdugao
      },
    ],
    recommondSence: [
      {
        name: '个性化推荐',
        content: '通过分析不同文章的关键词及属性，对文章进行更细粒度的描述，作为用户兴趣的特征，从而满足用户的长尾阅读兴趣，为用户推送最适宜的内容',
        icon: 'iconhudongyule',
        bg: gexinghuatuijian
      },
      {
        name: '语义召回',
        content: '对数据进行词向量表示，基于语义相关性为用户输出召回结果，与传统的基于字词倒排索引方法相比，增强了词的模糊匹配能力',
        icon: 'iconyuyizhaohui',
        bg: yuyizhaohui
      }
    ],
    more: [
      {
        path: '/NLP/dataanony',
        name: '数据匿名'
      },
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      }
    ]
  },
  {
    path: 'keyword',
    title: '关键词抽取',
    header: guanjianci,
    desc: '将文本中的关键词汇进行提取，可作为文章的标签用于分类推送',
    funcIntro: '支持多种类型文章，提取出能够代表文本语义的句子、词或短语，是许多文本挖掘任务的基础性和必要性工作',
    funcImg: r_guanjianci,
    productAdv: [
      {
        title:'准确率高',
        desc: '通过公司大量业务落地验证，提升抽取词汇准确率',
        icon: jingdugao
      },
      {
        title:'海量数据建模',
        desc: '依托滴滴大量的对话数据与标注数据，样本丰富',
        icon: hailiangshuju
      },
      {
        title:'维度丰富',
        desc: '深度学习技术实现文本挖掘，多维度抽取文章主题性与关键词',
        icon: biaoqianfengfu
      },
    ],
    recommondSence: [
      {
        name: '文本聚类',
        content: '通过抽取文本中的关键词，将关键词相似的文章归为一个类团，有助于提高聚类的收敛速度，为聚合相同或相似文本提供前置服务',
        icon: 'iconwenbenjulei',
        bg: wenbenjulei
      },
      {
        name: '个性化推荐',
        content: '通过分析不同文章的关键词及属性，对文章进行更细粒度的描述，作为用户兴趣的特征，从而满足用户的长尾阅读兴趣，为用户推送最适宜的内容',
        icon: 'iconhudongyule',
        bg: gexinghuatuijian
      }
    ],
    more: [
      {
        path: '/NLP/word_vector',
        name: '词向量'
      },
      {
        path: '/NLP/summary',
        name: '文本摘要'
      }
    ]
  },
  {
    path: 'pos_tagging',
    title: '词性标注',
    header: cixinbiaozhu,
    desc: '基于滴滴海量数据及算法支持，分析文本中词汇的基本属性，并赋予每一个词汇相应的词性',
    funcIntro: '通过词性标注技术，可以快速的为自然文本中每一个词标注对应的词性，结合中文分词、命名实体识别等技术，可以有助于进行深层次的文本挖掘处理',
    funcImg: r_cixing,
    productAdv: [
      {
        title:'海量数据建模',
        desc: '依托百亿级别的数据为基础，使模型具有高精度及良好的适配性，同时通过海量数据深度学习，精准理解自然语言文本',
        icon: hailiangshuju
      },
      {
        title:'多粒度支持',
        desc: '通过海量数据及业务场景的学习，结合语义粒度识别，标注粒度更加广泛',
        icon: duolidu
      },
      {
        title:'高效精准',
        desc: '通过海量数据及业务场景积累，不断优化算法模型，可快速帮助客户精准实现文本词性分析',
        icon: jingdugao
      },
    ],
    recommondSence: [
      {
        name: '指令解析',
        content: '通过词性标注结合分词等自然语言处理技术，使机器更好的理解相应指令内容，提高机器识别能力，提升用户体验',
        icon: 'iconzhilingjiexi',
        bg: zhilinjiexi
      },
      {
        name: '信息搜索',
        content: '以词性标注筛选分类与聚类特征，提升搜索结果的准确度及速度，提高客户搜索效率',
        icon: 'iconwenbensousuo',
        bg: xinxisousuo
      }
    ],
    more: [
      {
        path: '/NLP/segment',
        name: '中文分词'
      },
      {
        path: '/NLP/similarity',
        name: '语义相似度'
      }
    ]
  },
  {
    path: 'sent_vector',
    title: '句向量',
    header: juxiangliang,
    desc: '基于海量数据及深度神经网络技术，将文本句子进行向量化表示，有助于语义相似度计算，语义挖掘',
    funcIntro: '基于海量数据及深度神经网络技术，将文本中的句子进行分布式表示，从而实现文章的可计算，有助于语义挖掘、相似度计算',
    funcImg: r_juciangliang,
    productAdv: [
      {
        title:'技术领先',
        desc: '领先于业界的专业深度学习技术，基于深度学习模型，训练句向量表示模型',
        icon: jisulinxian
      },
      {
        title:'海量数据',
        desc: '基于滴滴海量文本数据训练模型，样本数据丰富',
        icon: hailiangshuju
      },
      {
        title:'精确度高',
        desc: '经公司大量业务落地验证，广泛应用在滴滴语义搜索场景',
        icon: jingdugao
      },
    ],
    recommondSence: [
      {
        name: '语义搜索',
        content: '对于用户的输入问题进行相似问搜索，得到准确的标准问题的答案',
        icon: 'iconwenbensousuo',
        bg: yuyisousuo
      },
      {
        name: '推荐',
        content: '对于文本场景的推荐，可以准确的进行类似文本推荐',
        icon: 'iconwenzhangzhanshituisong',
        bg: wenbentuijian
      }
    ],
    more: [
      {
        path: '/NLP/summary',
        name: '文本摘要'
      },
      {
        path: '/NLP/keyword',
        name: '关键词抽取'
      }
    ]
  }
]

